import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import storage from "framework/src/StorageProvider";
import moment from "moment";


// Customizable Area End

export interface ICurrency{
  "id": number,
  "currency_name": string,
  "value_respect_to_usd": number,
  "currency_type": string,
  "symbol": string,
}
export interface IBank {
		"id": number,
		"image": string,
		"name": string,
		"date": string,
		"note": string,
		"connection_type": string,
		"converted_balance": number,
		"converted_symbol": string,
		"currency_name": string,
		"original_currency": string,
    "account_number": string
	
}
export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
    selectedCurrency: { value: string, label: string },
    currencyList :ICurrency[],
    currencyListDropDown: { value: string, label: string }[],
    bankData: IBank[],
    bankTooltip: HTMLSpanElement | null,
    currentBank: IBank | null,
    openDeleteModel: boolean;
    
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class BankOverviewController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start

  getCurrencyListAPICallId: string = "";
  putCurrencyAPICallId:string = "";
  deleteBankAPICallId:string = "";
  getBankListAPICallId:string = "";
  getCurrentBankCallId:string = ""
 

  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      
      selectedCurrency: { value: '', label: '' },
      currencyList:[],
      currencyListDropDown:[],
      bankData: [],
      bankTooltip:null,
      currentBank: null,
      openDeleteModel: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.getCurrencyList();
    
    // Customizable Area End
  }
  
  getToken=()=>{
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  }



  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.handleGetCurrencyListResponse(apiRequestCallId,responseJson);
    this.handleDeleteBankResponse(apiRequestCallId);
    this.handleBankListResponse(apiRequestCallId,responseJson);
    this.handleManageCurrencyResponse(apiRequestCallId);
    this.handleGetCurrentBank(apiRequestCallId,responseJson)

    // Customizable Area End
  }

  // Customizable Area Start

  handleManageCurrencyResponse(apiRequestCallId:string){
    if(apiRequestCallId === this.putCurrencyAPICallId){
      this.getCurrencyList()
    }
  }


  handleGetCurrencyListResponse(apiRequestCallId:string,responseJson: {
    list:ICurrency[],
    selection: number
  }){

    if(apiRequestCallId === this.getCurrencyListAPICallId){
      const userSelectedCurrncy = responseJson.list.find((value) => {
        return value?.id == responseJson.selection
    })
      this.setState({
        currencyList: responseJson.list,
        selectedCurrency: userSelectedCurrncy? { value: userSelectedCurrncy.currency_type, label: `${userSelectedCurrncy.symbol}${userSelectedCurrncy.currency_type}` }:{
          value:"",label:""
        },
        currencyListDropDown: responseJson.list.map((value) => (
          { value: value.currency_type, label: `${value.symbol}${value.currency_type}` }
      ))
      })

      this.getBankList()
    }
  }
  goToAccountDetail=() =>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "MyneSettingsProfile");
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message) 
  }

  handleBack=() =>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Dashboard");
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message)
  }


  apiCall = async (data: {
    contentType: string, method: string, endPoint: string, body: string | null 
  }) => {
    const token = await storage.get("authToken")
    const { contentType, method, endPoint, body } = data;
    const bankMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));

      bankMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": contentType,
        token
      })
    );

    bankMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    bankMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    body && bankMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(bankMessage.id, bankMessage);
    return bankMessage.messageId;
  };

  getCurrencyList = async() => {
    this.getCurrencyListAPICallId = await this.apiCall({
      contentType:"application/json" + '',
      method: "GET" + '',
      endPoint: "bx_block_profile/profiles/currency_list" ,
      body: null
    });
  }

  getBankList = async() => {
    this.getBankListAPICallId = await this.apiCall({
      contentType:"application/json" + '',
      method: "GET" + '',
      endPoint: "account_block/bank_forms" ,
      body: null
    });
  }


  setCurrency = async (value: { value: string, label: string }) => {
    const currency = this.state.currencyList.find((newValue) => {
      return newValue.currency_type === value.value
    })
    if (currency) {
      this.putCurrencyAPICallId = await this.apiCall({
        contentType: "application/json" + '',
        method: "PUT" + '',
        endPoint: "bx_block_profile/profiles/add_currency_to_account",
        body: JSON.stringify({
          currency_id: currency.id
        })
      });
    }
  }

  setBankTooltip = (event:React.MouseEvent<HTMLSpanElement, MouseEvent>,bank:IBank) => {
    this.setState({
      bankTooltip: event.currentTarget,
      currentBank: bank
    })
  }

  handleCloseTooltip = () => {
    this.setState({
      bankTooltip: null
    })
  }

  handleEditBank = () =>{
    this.getCurrentBank()
  }

  closeDeleteModal = () =>{
    this.setState({
      openDeleteModel: false
    })
  }

  handleDeleteBank = () => {
    this.setState({
      openDeleteModel: true,
      bankTooltip:null
    })
  }
  handleDeleteBankAsset = async() => {
    this.deleteBankAPICallId = await this.apiCall({
      contentType:"application/json" + '',
      method: "DELETE" + '',
      endPoint: `account_block/bank_forms/${this.state.currentBank?.id}` ,
      body: null
    });
  }

  handleDeleteBankResponse(apiRequestCallId:string){
    if(apiRequestCallId === this.deleteBankAPICallId){

     this.getBankList()
    }
  }

  handleBankListResponse(apiRequestCallId:string,responseJson: {
    bank_accounts: IBank[]
  }){
    if(apiRequestCallId === this.getBankListAPICallId){
      this.setState({
        bankData:responseJson.bank_accounts,
        openDeleteModel: false,
        bankTooltip: null
      })
      if(this.deleteBankAPICallId !="" && responseJson.bank_accounts?.length===0){
        this.props.navigation.navigate("Dashboard");
      }
    }
  }

  handleGetCurrentBank(apiRequestCallId:string,responseJson: {
    data: {
      attributes: {
        date_of_purchase: string
      }
    }
  }){
    if(apiRequestCallId === this.getCurrentBankCallId){
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "ManualForm");
      message.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      if(this.state.currentBank){
        const userSelectedCurrncy = this.state.currencyListDropDown.find((value) => {
          return value.value === this.state.currentBank?.original_currency
        })
        
        let editData = {
          ...responseJson.data,
          currency: {
            label: this.state.currentBank?.original_currency,
            value:userSelectedCurrncy?.value
          },
          date: this.formatDate(responseJson.data.attributes.date_of_purchase)
        };
        raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), editData)
      }
    
      message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(message)
    }
  }

  getCurrentBank =async () => {
    this.getCurrentBankCallId = await this.apiCall({
      contentType: "application/json" + '',
      method: "GET" + '',
      endPoint: "account_block/bank_forms/"+ this.state.currentBank?.id,
      body: null
    });
  }

  dialogPaperStyle = {
    width: 'max-content',
    backgroundColor: '#3c3c51',
    borderRadius: '12px',
    border: '1px solid #57575d',
    padding: '0rem 2rem',
  };

  formatDate = (date:string)=> moment(date).format("DD/MM/YYYY")
  formatToDDMMYYY = (date:string)=> moment(date,"MM/DD/YY").format("DD/MM/YYYY")
  // Customizable Area End
}
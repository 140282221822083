import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  Grid,
  CssBaseline,
  FormControl,
  Popover,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";

import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import Select from "react-select";
import {
  bankImg,
  image_Vuesax,
  imagenav_BackIcon,
  threeDot,
} from "./assets";
import BankOverviewController,{
  Props,
} from "./BankOverviewController.web";
import { formatNumberWithCommasAndDecimals } from "../../../components/src/commonFn";

const theme = createTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        color: "#94989c",
      },
    },
    MuiDialog: {
      paper: {
        boxShadow: "0px 0px 10px 0px rgba(44, 226, 213, 0.16)",
        backgroundColor: "#395D6B",
        borderRadius: "8px",
        width: "max-content",
      },
    },
    MuiPopover: {
      root: {
        zIndex: 999,
        borderRadius: "14px",
        "&.simple-tooltip .MuiPaper-root": {
          padding: 14,
          boxShadow: "none",
          fontSize: 12,
          letterSpacing: "0.4px",
          backgroundColor: "#395d6b !important",
          borderRadius: "14px",
        },
        "& .MuiPaper-rounded": {
          borderRadius: "0px",
          boxShadow: "none",
        },
      },
    },
  },
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#2CE2D5",
    },
  },
});

// Customizable Area End

export default class BankOverview extends BankOverviewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <Box style={{ height: "100vh", width: "100%" }}>
        <Box className="mainWrappers_Box" style={{ minHeight: "100%",width:'100%' }}>
          <Box className="sideBorder">
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "40px 0px",
                }}
              >
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={imagenav_BackIcon}
                    style={webStyle.backbtnStyle}
                    data-test-id="handle-back"
                    onClick={this.handleBack}
                  />
                  <div
                    style={webStyle.titleTxt}
                  >
                    Bank Accounts
                  </div>
                </Box>
                <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <FormControl>
                      <Select
                        options={this.state.currencyListDropDown}
                        value={this.state.selectedCurrency}
                        onChange={(value) => {
                          value?.value && this.setCurrency(value);
                        }}
                        classNamePrefix="an-simple-selectUSD"
                        className="selectUSD"
                        data-test-id="selectMenu"
                      />
                    </FormControl>
                    <Box
                      onClick={()=> this.goToAccountDetail()} 
                      style={{ padding: '0px 20px' }} data-test-id="accountDetail"                    >
                      <img
                        src={image_Vuesax}
                        style={{
                          width: "41px",
                          height: "41px",
                          cursor: "pointer",
                        }}
                      />
                    </Box>
                  </Box>
              </Box>

              <Grid container spacing={2}>
              {this.state.bankData.map((bankInfo) => (
                <Grid
                item
                key={bankInfo.id}
                md={4}
                sm={6}
                xs={12}
              >
                <Box style={webStyle.container}>
                        <Box style={webStyle.boxContainer}>
                          <div style={ bankInfo.connection_type === "manual"?  webStyle.imgContainer: {} }>
                          <img
                            src={
                              bankInfo.connection_type === "manual"?  bankImg: bankInfo.image
                            }
                            style={
                              bankInfo.connection_type === "manual"?  {
                                height:"36px",
                                width:"33px"
                              }: {
                                height:"30px",
                                width:"56px"
                              }
                            }
                          
                          />
                          </div>
                          {
                            bankInfo.connection_type === "manual" && <span
                              style={{
                                position: "absolute",
                                top: 24,
                                right: 24,
                                cursor: "pointer"
                              }}
                              data-test-id="threeDot"
                              onClick={(event) => {
                                this.setBankTooltip(event, bankInfo);
                              }}
                            >
                              <img src={threeDot} height={14} width={4} />
                            </span>
                          }
                          <div
                            style={{
                              marginLeft: "12px",
                            }}
                          >
                            <Typography style={webStyle.headingText}>
                              {bankInfo.name}
                            </Typography>
                           
                          </div>
                        </Box>
                        <Box
                          style={webStyle.boxContainer1}
                        />
                        <Box
                          style={{
                            padding: "24px",
                          }}
                        >
                          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                              <Typography style={webStyle.titleText}>
                                Acc.No
                              </Typography>
                              <Typography style={webStyle.headingTxt}>
                                {bankInfo.account_number}
                              </Typography>
                            </div>
                          <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: "12px" }}>
                              <Typography style={webStyle.titleText}>
                                Price
                              </Typography>
                              <Typography style={webStyle.headingTxt}>
                                {bankInfo.converted_symbol} {formatNumberWithCommasAndDecimals(bankInfo.converted_balance.toString())}
                              </Typography>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px',marginTop: "12px" }}>
                              <Typography style={webStyle.titleText}>
                                Date
                              </Typography>
                              <Typography style={webStyle.headingTxt}>
                                {
                                  bankInfo.connection_type === "manual" ? this.formatDate(bankInfo.date) : this.formatToDDMMYYY(bankInfo.date)
                                }
                              </Typography>
                            </div>
                        </Box>
                      </Box>
              </Grid>
              ) )}

              </Grid>
              
              <Popover
                open={Boolean(this.state.bankTooltip)}
                className="simple-tooltip"
                elevation={0}
                anchorEl={this.state.bankTooltip}
                onClose={this.handleCloseTooltip}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                PaperProps={{
                  style: {
                    backgroundColor: "transparent",
                    marginTop: "10px",
                  },
                }}
              >
                <Box>
                  <Typography
                    style={webStyle.deleteTxt}
                    onClick={() => this.handleEditBank()}
                    data-test-id="edit-bank"
                  >
                    Edit
                  </Typography>
                  <Typography
                    style={webStyle.deleteTxt}
                    onClick={() => this.handleDeleteBank()}
                    data-test-id="delete-bank"

                  >
                    Delete
                  </Typography>
                </Box>
              </Popover>
            </ThemeProvider>
          </Box>
        </Box>
        <Dialog
          open={this.state.openDeleteModel}
          keepMounted
          data-test-id="dialogModel"
          onClose={this.closeDeleteModal}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          PaperProps={{ style: this.dialogPaperStyle }}
        >
          <DialogTitle
            id="alert-dialog-slide-title"
            style={webStyle.dialogTitle}
          >
            <span style={webStyle.dialogTitleHeader}>
              Are you sure you want to delete?
            </span>
          </DialogTitle>
          <DialogContent style={webStyle.dialogContent}>
            <DialogContentText
              id="alert-dialog-slide-description"
              style={webStyle.dialogContainer}
            >
              <div className="modal-button-container">
                <div
                  style={webStyle.cancelBtn}
                  className="modal-button modal-save"
                  data-test-id="cancelDelete"

                  onClick={() => this.closeDeleteModal()}
                >
                  Cancel
                </div>
                <div
                  className="modal-button modal-discard"
                  style={{
                    borderRadius: "7px",
                    height: "40px",
                  }}
                  onClick={()=>this.handleDeleteBankAsset()}
                  data-test-id="yesDelete"

                >
                  Delete
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
type IStyle = { [key: string]: React.CSSProperties };

const webStyle: IStyle = {
  titleText: {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#fff",
  },
  headingText: {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#fff",
    fontWeight:600
  },
  boxContainer: {
    display: "flex",
    alignItems: "center",
    paddingTop: "24px",
    paddingLeft: "24px",
    paddingRight: "24px",
    paddingBottom: "32px",
  },
  container: {
    background: "#3c3c50",
    borderRadius: "12px",
    position: "relative",
    height: "100%",
    border:"1px solid #57575d"
  },
  dialogTitle: { padding: "0px 65px", marginTop: "3rem" },
  dialogTitleHeader: {
    color: "#fff",
    textAlign: "center",
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: 600,
  },
  dialogContent: {
    padding: "0px!important",
    marginBottom: "0px",
    paddingTop: "15px",
  },
  dialogContainer: {
    color: "rgb(255, 255, 255) !important",
    fontSize: "20px !important",
    fontFamily: "Poppins !important",
    fontWeight: 500,
    marginTop: "18px",
    marginBottom: "2rem",
  },
  deleteTxt:{
    fontSize: "14px",
    color: "#fff",
    cursor: "pointer",
  },
  titleTxt:{
    color: "white",
    padding: "0px 20px",
    fontSize: "24px",
    fontFamily: "Poppins",
    fontWeight: 600,
  },
  backbtnStyle:{
    width: "24px",
    height: "13px",
    cursor: "pointer",
  },
  boxContainer1:{
    height: "1px",
    borderBottom: "1px solid #928f98",
  },
  cancelBtn:{
    borderRadius: "7px",
    height: "40px",
    color: "#c9f9f6",
    border: "1px solid #c9f9f6",
    marginRight: "3rem",
  },
  headingTxt:{
    color: "#2de2d5",
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: 600,
  },
  imgContainer:{
    height: "56px",
    width:"56px",
    borderRadius:"8px",
    textAlign:"center",
    display:"flex",
    justifyContent:"center",
    alignContent:"center",
    alignItems:"center",
    background:"#fff"
  }
};
// Customizable Area End
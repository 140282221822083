import { BlockComponent } from "../../framework/src/BlockComponent";

import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { Message } from "../../framework/src/Message";
import { runEngine } from "../../framework/src/RunEngine";
import { IBlock } from "../../framework/src/IBlock";

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  openModel: boolean;
  activeSubMenu: string;
  activeMenu: string;
  showQuestionArray: boolean;
  hasSelectedPlanner: boolean;
  serviceSelected: boolean;
  isPinPresent: boolean;
  isDrawerOpen: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SavedAvailabilityController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock((this as unknown) as IBlock, [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ]);
    this.state = {
      openModel: false,
      activeSubMenu: window.location.pathname,
      activeMenu: window.location.pathname,
      showQuestionArray: true,
      hasSelectedPlanner: false,
      serviceSelected: false,
      isPinPresent: false,
      isDrawerOpen: false,
    };
  }

  getStatusId: string = "";
  getServiceStatusId: string = ""

  getQuestionnaireStatusId: string = "";
  getSetUpPin: string = "";

  async receive(from: string, message: Message) {
    // Customizable Area Start

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.getQuestionnaireStatusId) {
        if(responseJson?.errors && (responseJson?.errors[0]?.token === "Invalid token" || responseJson?.errors[0]?.token === "Token has Expired")){
          this.logout()
        }
        if (responseJson.message === "The questions already answered") {
          this.setState({
            showQuestionArray: false,
          });
          this.getServiceStatus();
          this.getStatus()
        }
      }
      if (apiRequestCallId === this.getStatusId) {
        if(responseJson.wealth_planner)
        this.setState({
          hasSelectedPlanner: true,
        });
          
      }
      if (apiRequestCallId === this.getServiceStatusId) {
        this.setState({
          serviceSelected: responseJson.services_selected,
        });
      }
      if (apiRequestCallId === this.getSetUpPin) {
        if (responseJson) {
          if (responseJson.message === "Pin present") {
            this.setState({
              isPinPresent: true
            })
          }
          else {
            this.setState({
              isPinPresent: false
            })
          }
        }
      }
    }

    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getQuestionArrayStatus();
    this.getSetUpPinStatus()
    document.addEventListener("estatePlanningUpdated", () => {
      this.getQuestionArrayStatus();
    });
  }

  getSetUpPinStatus() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSetUpPin = requestMessage.messageId;
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_settings/settings/user_pin_status"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  logout = () => {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('userEmail');
    window.localStorage.removeItem('authToken');
    window.localStorage.removeItem('rememberMe');
    window.localStorage.removeItem('email');
    window.localStorage.removeItem('loggedin');
    window.localStorage.removeItem('auhtToken');
    this.goToEmailLogin()
}
goToEmailLogin() {
  const msg: Message = new Message(
    getName(MessageEnum.NavigationEmailLogInMessage)
  );
  msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(msg);
}
  getServiceStatus() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getServiceStatusId = requestMessage.messageId;
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_proposal_generation/user_service_selections/services_status"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getStatus() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStatusId = requestMessage.messageId;
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_formapprovalworkflow/send_requests/get_status"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getEstatePlanningLink() {
    if (this.state.showQuestionArray) {
      return "/Quetionnaire";
    }
    if (!this.state.hasSelectedPlanner) {
      return "/FinancialPlanner";
    }
    if (this.state.serviceSelected) {
      return "/EstatePlanning";
    }
    return "/EngagementRequest/id";
  }

  getQuestionArrayStatus() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getQuestionnaireStatusId = requestMessage.messageId;
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_questionbank/estate_planning_questionnaire/questionnaire_status"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}

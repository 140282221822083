import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall } from "../../../components/src/Common";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from 'framework/src/Utilities';

export const configJSON = require("./config");


export interface Props {
  navigation: any;
  id: string;
  location: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  hader_dropdown: any;
  selectCurrency: { value: string, label: string },
  selectCurrency2: { value: string, label: string },
  currencyList: any,
  logoImg1AddCash: string
  dataValue: Object
  dataValueNew: Object;
  isLoading: boolean;
  selectedPage: number;
  selectedMonth: string;
  selectedYear: string;
  transactionsData: any;
}

interface SS {
  id: any;
}

export default class TransactionAndSpendingDetailsPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  postUserPDFDataCallId: any;
  postUserSelectDataCallId: any;
  getAllRecentTransactionsApiCallId: any;
  lastElementRef: any;
  observer: any = null;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      hader_dropdown: [],
      selectCurrency: { value: '', label: '' },
      selectCurrency2: { value: '', label: '' },
      currencyList: [],
      logoImg1AddCash: "",
      dataValue: {
        display: "flex", flexDirection: 'row-reverse', justifyContent: "end"
      },
      dataValueNew: {
        display: "flex", flexDirection: 'row', justifyContent: "end"
      },
      isLoading: false,
      selectedPage: 1,
      selectedMonth: '',
      selectedYear: '',
      transactionsData: [],
    };
    this.lastElementRef = React.createRef();

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start

  getAllCurrencyList = () => {
    const authToken = localStorage.getItem("token")
    const header = { "token": authToken };
    let urlendpoint = configJSON.Loginsubmittoken;
    const bodyData = apiCall({
      header: header,
      httpBody: {},
      url: urlendpoint,
      httpMethod: configJSON.exampleAPiMethodtoken,
    });

    this.postUserPDFDataCallId = bodyData.messageId;
    runEngine.sendMessage(bodyData.id, bodyData);
  }
  async componentDidMount() {
    const language = await getStorageData('language');
    const logoImg1AddCash = language === 'Arabic' ? 'rtl' : 'ltr';
    this.setState({ logoImg1AddCash: logoImg1AddCash });
    this.functionTokenCheck()
    this.getAllCurrencyList();
    this.createObserver();
  }

  createObserver = () => {
    this.observer = new IntersectionObserver(
      this.observerCallBack,
        { threshold: 1.0 }
    );

    if (this.lastElementRef.current) {
        this.observer.observe(this.lastElementRef.current);
    }
};

observerCallBack = (entries:any) => {
  const entry = entries[0];
  const {transactionsData, selectedPage} = this.state
  const totalData = transactionsData.pages;
  const hasMore = totalData >= selectedPage
  if (entry.isIntersecting && hasMore) {
      this.allTransactions()

  }
}

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.postUserPDFDataCallId !== null &&
      this.postUserPDFDataCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse.list) {
        const userSelectedCurrncy = apiResponse.list?.find((value: any) => {
          return value?.id == apiResponse?.selection
        })
        this.setState({
          selectedPage:1,
          transactionsData:[],
          selectCurrency2: { value: userSelectedCurrncy.currency_type, label: `${userSelectedCurrncy.symbol}` },
          currencyList: apiResponse,
          selectCurrency: { value: userSelectedCurrncy.currency_type, label: this.state.logoImg1AddCash === "rtl" ? `${userSelectedCurrncy.currency_type}${userSelectedCurrncy.symbol}` : `${userSelectedCurrncy.symbol}${userSelectedCurrncy.currency_type}` },
          hader_dropdown: apiResponse?.list?.map((value: any) => (
            { value: value.currency_type, label: `${value.symbol}${value.currency_type}` }
          ))
        },()=>this.getSearchParams());
      }
    }

    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.postUserSelectDataCallId !== null &&
      this.postUserSelectDataCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse.meta.currency) {
        this.getAllCurrencyList()
      }
    }
    this.apiReciveFunctions(message)
    // Customizable Area End
  }

  apiReciveFunctions = (message: any) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getAllRecentTransactionsApiCallId !== null &&
      this.getAllRecentTransactionsApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let allTransactionApiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState((prev)=>{
        allTransactionApiResponse.transaction.data = [...prev.transactionsData?.transaction?.data || [],...allTransactionApiResponse.transaction.data]
        return {
          transactionsData: allTransactionApiResponse,
          isLoading: false,
          selectedPage: prev.selectedPage+1
        }
      })
    }

  }



  setCurrencyApi = (currencyId: string) => {
    const authToken = localStorage.getItem("token")
    const header = { "token": authToken };
    const formData = new FormData();
    formData.append("currency_id", currencyId);
    let userDetailurl = configJSON.Loginsubmittokenselect;
    const bodyData = apiCall({
      header: header,
      httpBody: formData,
      url: userDetailurl,
      httpMethod: configJSON.exampleAPiMethodtokenselect,
    });
    this.postUserSelectDataCallId = bodyData.messageId;
    runEngine.sendMessage(bodyData.id, bodyData);
  }


  allTransactions = async () => {
    if (this.state.isLoading) {
      return
    }
    this.setState({
      isLoading: true
    })
    this.functionTokenCheck()
    const authToken = localStorage.getItem("token")
    const header = { "token": authToken };
    const bodyData = apiCall({
      header: header,
      httpBody: {},
      url: `${configJSON.transactionApiEndPoint}?month=${this.state.selectedMonth}&year=${this.state.selectedYear}&page=${this.state.selectedPage}`,
      httpMethod: configJSON.reacttransactionsget,
    });
    this.getAllRecentTransactionsApiCallId = bodyData.messageId;
    runEngine.sendMessage(bodyData.id, bodyData);
  }
  getSearchParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const month = urlParams.get('month');
    const year = urlParams.get('year');
    if (month && year) {
      this.setState({
        selectedMonth: month,
        selectedYear: year
      })
      this.allTransactions()
    }
  }

  setCurrency = (value: { value: string, label: string }) => {
    const currencyId = this.state.currencyList?.list?.find((newValue: any) => {
      return newValue?.currency_type == value?.value
    })
    this.setCurrencyApi(currencyId?.id)
  }


  navigateToLogin = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLogin");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(msg)
  }
  functionTokenCheck = async () => {
    let authToken = localStorage.getItem("token")
    if (!authToken) {
      window.localStorage.clear();
      this.navigateToLogin()
    }
  }

  // Customizable Area End
}

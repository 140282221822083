// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.exampleAPiMethodPostType = "POST";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "FormApprovalWorkflow";
exports.labelBodyText = "FormApprovalWorkflow Body";
exports.CreateIdApi='bx_block_formapprovalworkflow/formapprovalworkflows/create_customer_trip'
exports.btnExampleTitle = "CLICK ME";
exports.cancelAPI = "bx_block_formapprovalworkflow/formapprovalworkflows/cancel_trip";
exports.FinancialPlannerGet = "/bx_block_formapprovalworkflow/send_requests/wealth_planner_index";
exports.FinancialPlannerApiMethodType = "GET";
exports.FinancialPlannerPost = "/bx_block_formapprovalworkflow/send_requests/create_engagement_request";
exports.FinancialPlannerApiMethodTypePost = "POST";
exports.send_requests = "/bx_block_formapprovalworkflow/send_requests/get_status";
exports.service_status = "/bx_block_proposal_generation/user_service_selections/services_status";
exports.new_added_service = "/bx_block_proposal_generation/user_service_selections/newly_added_services";
exports.index_payment_method = "/bx_block_stripe_integration/payment_methods";
exports.sub_total = "/bx_block_proposal_generation/user_service_selections/subtotal";
exports.set_service = "/bx_block_proposal_generation/user_service_selections";

exports.send_requests_Post_Data = "POST";
exports.users_slots="/bx_block_appointment_management/appointments/user_slot";
exports.send_requests_Get = "GET";
exports.send_requestsPost = "/bx_block_formapprovalworkflow/send_requests/create_engagement_request?wealth_planner_id";
exports.send_requests_Post = "POST";
exports.send_allow_access = '/account_block/accounts/allow_access_permission';
exports.getCalenderDateEndPoint='bx_block_appointment_management/appointment_slots/available_slots';
exports.rescheduleBookingEndPoint = 'bx_block_appointment_management/appointments/reschedule_booking';
exports.cancelSlot = 'bx_block_appointment_management/appointments/cancel_slot'
exports.send_allow_access_post = "POST";
exports.getCalenderDateEndPoint='bx_block_appointment_management/appointment_slots/available_slots'
exports.getBookSlotEndPoint='bx_block_appointment_management/appointments/user_slot'
exports.postSetPlanEndPoint='bx_block_appointment_management/service_planners/set_plan'
exports.getPlanEndPoint='bx_block_appointment_management/service_planners/get_plan'
exports.postApiMethod= 'POST'
exports.getApiMethod= "GET"
exports.getConversationApi= "twilio_chat/create_conversation?type=user&receiver_id=";
// Customizable Area End

import React from "react";

// Customizable Area Start
import '../assets/cashAsset.css';
import '../assets/confirmationModal.css';
import { Select, MenuItem, FormControl, Menu } from '@material-ui/core'
import { Link } from 'react-router-dom';
// Customizable Area End
import { cashDefault, headereBackIcon, settingicon, vertical3Dots } from "./assets";
import AssetOverviewController, {
  Props,
} from "./AssetOverviewController";
import { addCommas } from "../../../components/src/commonFn";

export default class CashAssetsOverview extends AssetOverviewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getCashSelectedCurrency(cash_currency_list: any[]) {
    return cash_currency_list.length > 0 && cash_currency_list.find((currency: any) => currency.id === this.state.currencyList.selection) || null;
  }

  getCashCurrency() {
    return this.state.currencyList && this.state.currencyList.list && this.state.currencyList.list.length > 0 ? this.state.currencyList.list : [];
  }

  getCashData() {
    return this.state.cashAssetData || [];
  }

  renderCashDeleteConfirmationModal() {
    if (this.state.isDeleteConfimationModalOpen && this.state.currentCardId) {
      return (
        <div className="cash-modal-background">
          <div className="cash-modal-popup-container">
            <div className="cash-modal-title">Are you sure you want to delete ?</div>
            <div className="cash-modal-button-container">
              <div className="cash-modal-button modal-save" data-test-id='cash-popup-cancel-click'
                onClick={() => this.setState({ isDeleteConfimationModalOpen: false })}>
                Cancel
              </div>

              <div className="cash-modal-button modal-discard" data-test-id='cash-popup-delete-click'
                onClick={() => {
                  this.setState({ isDeleteConfimationModalOpen: false });
                  this.deleteCashAssetData(this.state.currentCardId)
                }}>
                Delete
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  fetchCashAsssetKeys(data: any) {
    const attributes = data && data.attributes;
    const obj: any = {
      name: '',
      price: '',
    }
    if (attributes) {
      obj.name = attributes.name;
      obj.price = `${attributes.converted_currency} ${addCommas(attributes.converted_amount)}`;
      return obj;
    }
    return obj;
  }

  // Customizable Area End

  render() {
    const cash_currency_list = this.getCashCurrency();
    const selectedCashCurrency = this.getCashSelectedCurrency(cash_currency_list);
    const cashAssetData = this.getCashData();
    return (
      // Customizable Area Start
      <div className="cash-asset-overview-main-container">
        <div className="cash-asset-overview-container">
          <div className="cash-header-wrapper">
            <div className="cash-page-title">
              <h4>
                <Link to={'/Dashboard'} className="cash-navigation-back-icon">
                  <img src={headereBackIcon}></img>
                </Link>
                Cash</h4>
            </div>
            <div className="cash-page-info">
              <div className="cash-drop-menu">
                <div>
                  <FormControl className="cash-an-custom-select">
                    <Select
                      displayEmpty
                      value={selectedCashCurrency ? selectedCashCurrency.id : ''}
                      data-test-id="select-change"
                      onChange={async (e: any) => {
                        if (e.target.value) {
                          await this.onAddCurrencyToAccount(e.target.value)
                          setTimeout(async () => {
                            await this.loadCashAssetData();
                          }, 500);
                        }
                      }}
                      className="cash-an-select-dropdown"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {cash_currency_list.length > 0 &&
                        cash_currency_list.map((currency: any) => (
                          <MenuItem key={currency.id} value={currency.id} className="cash-an-custom-menu-item">
                            {`${currency.symbol} ${currency.currency_type}`}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
                <div>
                  <Link to="/Account-Detail">
                    <img src={settingicon} alt="setting" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="cash-asset-cards">
            {
              cashAssetData.length > 0 && cashAssetData.map((data: any, index: number) => {
                const { name, price } = this.fetchCashAsssetKeys(data);
                return (
                  <div className="cash-card" key={data.id}>
                    <div className="cash-card-top">
                      <div className="cash-card-left-upload">
                        <img src={cashDefault} alt='cash image' />
                        <div className="white-btn-title">
                          {name}
                        </div>
                      </div>

                      <div className="cash-upper-card-right">
                        <img
                          src={vertical3Dots}
                          onClick={(event: React.MouseEvent<HTMLElement>) => this.handleMenuModal(event, data.id) }
                          data-test-id={`cash-vertical-icon-click-${index}`}
                          alt='vertival-dots' />

                        {this.state.isVerticalPopoverOpen && data.id === this.state.currentCardId && (
                          <Menu
                          data-test-id="cash-popup-menu" keepMounted
                          open={Boolean(this.state.anchorEl)}

                          PaperProps={{
                            style: {width:'55px',background:"#395D6B", padding:"0 5px", borderRadius:'8px'}
                          }}                          anchorEl={this.state.anchorEl}
                          onClose={this.handleDocumentMenuModalClose}
                          anchorOrigin={{
                            horizontal: 'right',
                            vertical: 'top',
                          }}
                          transformOrigin={{
                            horizontal: 'right',
                            vertical: 'top',
                          }}

                          id="simple-menu"
                          
                          >
                            
                            <Link to={{
                              pathname: '/AddCashWeb',
                              state: { cashEditData: data }
                            }}
                              className="cash-edit-option"
                              onClick={() => this.setState({ isVerticalPopoverOpen: false })}
                              data-test-id={`cash-edit-${index}`} >
                              Edit
                            </Link>
                            <div className="cash-delete-option"
                              onClick={() => {
                                this.setState({ isVerticalPopoverOpen: false, isDeleteConfimationModalOpen: true, currentCardId: data.id });
                              }}
                              data-test-id={`cash-delete-${index}`}>
                              Delete
                            </div>
                          </Menu>
                        )}
                      </div>
                    </div>
                    <div className="cash-card-bottom">
                      <div className="cash-sub-tile-bold-section">
                        Price
                      </div>
                      <div className="cash-middlesub-tile-normal-section">{price}</div>
                    </div>
                  </div>
                )
              }
              )
            }
          </div>
        </div>
        {
          this.renderCashDeleteConfirmationModal()
        }
      </div >
      // Customizable Area End
    );
  }
}

export const  addCommas = (getText: string) => {
  let text = ""
  if(getText){
    text = getText
  }
  let negative = ''
  if(typeof text === 'string'){
  if(text?.includes('-')){
    negative = '-';
    text = text.replace('-', '');
  }
  let withoutDecimal = text
  let decimal = ''
  // if the text contains commas, no need to add commas again
  if(text?.includes(',')){
    if(text?.length > 6){
      let num =  text.replace(/,/g, '') 
      let value = formatNumber(Number(num))
      return negative+value
    } else{
      return negative+text
    }
  }
  if (withoutDecimal?.lastIndexOf('.') >= 0) {
    decimal = text?.slice(withoutDecimal?.lastIndexOf('.'), withoutDecimal?.length)
    withoutDecimal = text.slice(0, withoutDecimal?.lastIndexOf('.'))
  }
  let whole = withoutDecimal
  if (text?.length > 6) {
    return negative+formatNumber(Number(text))
  } else if (whole?.length < 4) {
    return negative + whole + decimal;
  } else {
    let final = whole
    let count = 0;
    let commasAdded = ""
    for (let i = final?.length; i > 0; i--) {
      if (count % 3 === 0 && count != 0) {
        commasAdded = final[i - 1] + ',' + commasAdded
      } else {
        commasAdded = final[i - 1] + commasAdded;
      }
      count++;
    }
    return negative + commasAdded + decimal
  }
}
}

export const addCommas2 = (getText: string | number) => {
  let text = typeof getText === 'number' ? Math.round(getText).toString() : getText || '';
  let negative = '';

  if (text.includes('-')) {
    negative = '-';
    text = text.replace('-', '');
  }

  let withoutDecimal = text;
  let decimal = '';

  // if the text contains commas, no need to add commas again
  if (text.includes(',')) {
    if (text.length > 6) {
      let num = text.replace(/,/g, '');
      let value = formatNumber(Number(num));
      return negative + value;
    } else {
      return negative + text;
    }
  }

  if (withoutDecimal.lastIndexOf('.') >= 0) {
    decimal = text.slice(withoutDecimal.lastIndexOf('.'), withoutDecimal.length);
    withoutDecimal = text.slice(0, withoutDecimal.lastIndexOf('.'));
  }

  let whole = withoutDecimal;
  if (text.length > 6) {
    return negative + formatNumber(Number(text));
  } else if (whole.length < 4) {
    return negative + whole + decimal;
  } else {
    let final = whole;
    let count = 0;
    let commasAdded = '';

    for (let i = final.length; i > 0; i--) {
      if (count % 3 === 0 && count != 0) {
        commasAdded = final[i - 1] + ',' + commasAdded;
      } else {
        commasAdded = final[i - 1] + commasAdded;
      }
      count++;
    }

    return negative + commasAdded + decimal;
  }
};


const formatNumber = (number: number) => {
  if (number < 1) {
    return Math.round(number)
  }
  const suffixes = ["", "k", "m", "b", "t"];
  const suffixIndex = Math.floor(Math.log10(Math.abs(number)) / 3);
  const formattedNumber = (number / Math.pow(10, suffixIndex * 3)).toFixed(2) + suffixes[suffixIndex];
  return formattedNumber;
}


export function validateImage(file:File) {
  const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
  const maxSize = 1048576; // 1MB in bytes
  
  if (!allowedTypes.includes(file.type)) {
    return {
      isValid: false,
      errorMessage: "Only JPEG, PNG, or GIF formats are allowed"
    };
  }
  
  if (file.size > maxSize) {
    return {
      isValid: false,
      errorMessage: "File must be 1MB or smaller"
    };
  }
  
  return {
    isValid: true,
    errorMessage: ""
  };
}
import React from "react";
// Customizable Area Start
import "./style.css";
import {
    settingicon,
    subarrowicon,
    stockPortfolio,
    stockTicker
} from "./assets";
import {
    Box,
    Typography,
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { backIcon } from "../../forgot-password/src/assets";
import Grid from '@material-ui/core/Grid';

// Customizable Area End
import DashboardController, { Props } from "./DashboardController";
export default class Dashboard extends DashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start



    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <div className="pagedashboard-wrapper">
                <div className="header-wrapper">
                    <div className="page-title">
                        <h4>
                            <NavLink to="/CategoriessubcategoriesWeb"><img src={backIcon} alt="back-icon" /></NavLink>
                            &nbsp;Add Stock
                        </h4>
                    </div>
                    <div id="pageInfoStocKTicker" className="page-info">
                        <ul data-test-id="StockCurrencyDropDown" className="drop-menu">
                            <li className="dropdownitems">
                                <div
                                    data-test-id="currencyDropdoen"
                                    id="currencyStockDropdown"
                                    onClick={() => this.handleDropdown()}
                                    className={this.state.dropdownVisiblity}
                                >
                                    <p className="arrowIconForStock">
                                        {this.state.selectedCurrency} <img src={subarrowicon} alt="subarrow" />
                                    </p>
                                    <ul id="labelForStock">
                                        {this.state.currencyList && this.state.currencyList.list.map((value: any, index: number) => {
                                            return <li data-test-id={"currency-" + index} onClick={() => this.selectCurrency(value)}>{value.symbol + value.currency_type}</li>
                                        })}
                                    </ul>
                                </div>
                            </li>
                            <li className="settingIcon">
                                <NavLink to="/Account-Detail">
                                    <img date-test-id="settingIcon" src={settingicon} alt="setting" />
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={2} md={3}>
                            {this.state.stockBtn == 'portfolio' ?
                                <>
                                    <Box
                                        style={{ ...webStyle.stockCardActive, flexDirection: 'column', }}
                                    >
                                        <Box>
                                            <img src={stockPortfolio} style={{ width: '50px', margin: 'auto' }} />
                                        </Box>
                                        <Typography style={webStyle.stockPortfolioLabel}>
                                            Stock Wallets & Exchanges
                                        </Typography>
                                    </Box>
                                </> :
                                <Box
                                    onClick={() => this.setState({ stockBtn: 'portfolio' })}
                                    data-test-id="portfolioCard"
                                    style={{ ...webStyle.stockCard, flexDirection: 'column', }}
                                >
                                    <Box>
                                        <img src={stockPortfolio} style={{ width: '50px', margin: 'auto' }} />
                                    </Box>
                                    <Typography style={webStyle.stockPortfolioLabel}>
                                        Stock Wallets & Exchanges
                                    </Typography>
                                </Box>
                            }
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            {this.state.stockBtn == 'ticker' ?
                                <Box
                                    style={{ ...webStyle.stockCardActive, flexDirection: 'column' }}
                                >
                                    <Box>
                                        <img src={stockTicker} style={{ width: '50px', margin: 'auto' }} />
                                    </Box>
                                    <Typography style={webStyle.stockTickerLabel}>
                                        Stock Positions
                                    </Typography>
                                </Box> :
                                <Box
                                    onClick={() => this.setState({ stockBtn: 'ticker' })}
                                    data-test-id="tickerCard"
                                    style={{ ...webStyle.stockCard, flexDirection: 'column' }}
                                >
                                    <Box>
                                        <img src={stockTicker} style={{ width: '50px', margin: 'auto' }} />
                                    </Box>
                                    <Typography style={webStyle.stockTickerLabel}>
                                        Stock Positions
                                    </Typography>
                                </Box>}
                        </Grid>
                        {/* <Grid item xs={12} sm={3}></Grid>
                        <Grid item xs={12} sm={3}></Grid> */}

                    </Grid>
                </Box>
                <Box>
                    <Box style={{ ...webStyle.btnBox, position: 'absolute', bottom: '20px', right: '30px' }} data-test-id="nextBtn" onClick={() => this.redirectToNext()}>
                        <Typography style={webStyle.btnLabel}>
                            Next
                        </Typography>
                    </Box>
                </Box>
            </div>
        );
        // Customizable Area End
    }
}

// Customizable Area Start

type IStyle = { [key: string]: React.CSSProperties }

const webStyle: IStyle = {
    stockPortfolioLabel: { color: '#FFF', fontFamily: 'Poppins', fontSize: '15px', fontWeight: 500, marginTop: '10px', textAlign: "center" },
    stockTickerLabel: { color: '#3C3C50', fontFamily: 'Poppins', fontSize: '15px', fontWeight: 500, marginTop: '24px', textAlign: "center" },
    btnLabel: { color: '#3C3C50', fontFamily: 'Poppins', fontSize: '15px', fontWeight: 700,padding: '5px',
   borderRadius: '50% !important'},
    btnBox: { cursor: 'pointer', backgroundColor: '#C9F9F6', width: '135px', borderRadius: '3px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px' },
    stockCard: { cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '220px',width:'220px',  borderRadius: '12px', background: '#2A2A39', boxShadow: '0px 0px 10px 2px rgba(6, 141, 132, 0.45)' },
    stockCardActive: { cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '220px', width:'220px', borderRadius: '12px', background: '#2CE2D5', boxShadow: '0px 0px 10px 2px rgba(6, 141, 132, 0.45)' }
}
// Customizable Area End

import React from "react"
// Customizable Area Start
import {
    Box
} from "@material-ui/core";
import { Link, NavLink } from 'react-router-dom';
import { imageVuesax } from "./assets"
import FormControl from '@material-ui/core/FormControl';
import Select from 'react-select';
import { down, up } from "../../dashboard/src/assets";
import { backIcon } from "../../forgot-password/src/assets";
import "./transaction.css"
// Customizable Area End
import TransactionAndSpendingDetailsPageController, { Props } from './TransactionAndSpendingDetailsPageController.web';
import { image_BackIcon } from "../../visualanalytics/src/assets";
import CircularProgress from "@material-ui/core/CircularProgress";
import { formatNumberWithCommasAndDecimals } from "../../../components/src/commonFn";

export default class TransactionAndSpendingDetailsPage extends TransactionAndSpendingDetailsPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start
    styleCss = () => {
        return this.state.logoImg1AddCash === "rtl" ? this.state.dataValue : this.state.dataValueNew
    }
    getArrowImage(amountOperand: string) {
        return amountOperand === '+' ? up : down;
    }
    image_BackIcon = () => {
        return this.state.logoImg1AddCash === "rtl" ? image_BackIcon : backIcon
    }

    // Customizable Area End
    render() {
        // Customizable Area Start
        return (
            <div className="main-container">
                <Box>
                    <Box className="trendsoverflowY" >
                        <Box className="trendsfont">
                            <NavLink to="/ForecastingandbudgetingTrends" className="back-icon-link">
                                <img src={this.image_BackIcon()} alt="back-icon" className="back-icon" />
                            </NavLink>
                            <p className="trendsAfont">All transactions</p>
                        </Box>
                        <Box className="addCashrightHeaderDropSetting">
                            <Box className="trendsicon">
                                <FormControl>
                                    <Select options={this.state.hader_dropdown}
                                        value={this.state.selectCurrency}
                                        onChange={(val: any) => { this.setCurrency(val) }}
                                        classNamePrefix="an-simple-selectUSD" className="selectUSD" data-test-id="selectMenu" />
                                </FormControl>
                                <Box style={{ paddingLeft: '20px' }} data-test-id="addCashDataSetting">
                                    <Link to="/Account-Detail">
                                        <img src={imageVuesax} className="trendsSettingimg" />
                                    </Link>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <h3 className="month-info">{this.state.transactionsData.month}</h3>
                <Box>
                    {
                        this.state.transactionsData?.transaction?.data?.map((item: any, key: any) => {
                            const amountClass = item?.attributes?.amount_operand === "+" ? "amount-positive" : "amount-negative";
                            return (
                                <Box key={key} className="box-container">
                                    <Box className="box-start">
                                        <img src={item?.attributes?.sub_category?.image} width={48} height={48} alt={item?.attributes?.sub_category.name} />
                                        <p className="description">{item?.attributes?.description}</p>
                                        <p className="date">{item?.attributes?.transaction_date}</p>
                                    </Box>
                                    <Box className="box-end">
                                        <p className={amountClass}>

                                            {`${item?.attributes?.amount_operand} ${formatNumberWithCommasAndDecimals(item?.attributes?.converted_amount)} ${item?.attributes?.user_currency}`}
                                            <img
                                                className="arrow-icon"
                                                src={this.getArrowImage(item?.attributes?.amount_operand)}
                                                alt={item?.attributes?.amount_operand === '+' ? 'Up Arrow' : 'Down Arrow'}
                                                width={16}
                                                height={16}
                                            />
                                        </p>
                                        <p className="sub-category-name">{`${item?.attributes?.sub_category.name}`}</p>
                                    </Box>
                                </Box>
                            )
                        })

                    }
                    {this.state.isLoading && (
                        <Box style={{
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center',
                            margin:'20px 0'
                        }}>
                            <CircularProgress />
                        </Box>
                    )}
                </Box>
                <div
                    ref={this.lastElementRef}
                >
                </div>
            </div>
        )
    }

}
// Customizable Area Start

// Customizable Area End

import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start

import { imgArrow, imgAutoConnectBlack, imgAutoConnectWhite, imgCryptoWalletBlack, imgCryptoWalletWhite, imgSetting, } from "./assets";
import { NavLink } from "react-router-dom";
import MuiButton from '@material-ui/core/Button';
import { Box, CssBaseline, FormControl, Grid, ThemeProvider, createTheme, styled } from "@material-ui/core";
import Select from 'react-select';

const theme = createTheme({
  palette: {
    primary: {
      main: "#2CE2D5",
      contrastText: "#fff",
    },
  },
});

// Customizable Area End

import CradditionalformsformschangerequestsController, {
  Props,
  configJSON,
} from "./CradditionalformsformschangerequestsController";

export default class Cradditionalformsformschangerequests extends CradditionalformsformschangerequestsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MainWrapper dir={this.state.direction}>
        <Box className="wrapper">
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box className="mainHeader">
              <Box className="headingTitle">
                <img
                  className={`backImage ${this.state.direction === 'rtl' ? 'rtlRotate' : ''}`}
                  src={imgArrow}
                  onClick={() => this.navigateBack("CategoriessubcategoriesWeb")}
                />
                <h2 className="headerText">Add Bank Accounts</h2>
              </Box>
              <Box>
                <Box className="currencySelectorWrapper">
                  <FormControl>
                    <Select
                      options={this.state.currencyList.map(currency => ({
                        label: currency.currency_type,
                        value: currency.currency_type
                      }))}
                      value={this.state.selectedCurrency}
                      onChange={this.handleChangeCurrency}
                      classNamePrefix="an-simple-selectUSDNew"
                      className="selectUSDNew"
                      data-test-id="selectMenu"
                    />
                  </FormControl>
                  <NavLink to="/Account-Detail">
                    <img className="settingsIcon" src={imgSetting} />
                  </NavLink>
                </Box>
              </Box>
            </Box>
            <Box className="contentWrapper">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                  <Box className={`formBox ${this.state.selectedCard === this.CardType.Manual ? 'selected' : ''}`} onClick={() => this.handleCardChange(this.CardType.Manual)} data-test-id="manual-form">
                    <Box className="formsContainer">
                      <img className="cardImage" src={this.state.selectedCard === this.CardType.Manual ? imgCryptoWalletBlack : imgCryptoWalletWhite} alt="Enter Manually" />
                      <Box className="label">Enter Manually</Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                  <Box className={`formBox ${this.state.selectedCard === this.CardType.Auto ? 'selected' : ''}`} onClick={() => this.handleCardChange(this.CardType.Auto)} data-test-id="auto-connect">
                    <Box className="formsContainer">
                      <img className="cardImage" src={this.state.selectedCard === this.CardType.Auto ? imgAutoConnectBlack : imgAutoConnectWhite} alt="Auto Connect" />
                      <Box className="label">Auto Connect</Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box className="nextButtonWrapper">
              <MuiButton
                data-test-id="submitButton"
                variant="contained"
                className="nextButton3"
                onClick={this.handleSelectCardNext}
              >
                Next
              </MuiButton>
            </Box>
          </ThemeProvider>
        </Box>
      </MainWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start


const MainWrapper = styled(Box)({
  width: "100%",
  height: "100vh",
  background: "#2A2A39",
  fontFamily: 'Poppins',
  "& .wrapper": {
    height: "100%",
    overflowY: "scroll",
    padding: '2rem',
  },
  "& .mainHeader": {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  "& .headingTitle": {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  "& .backImage": {
    width: '24px',
    height: '13px',
    cursor: "pointer",
    marginTop: "3px",
  },
  "& .backImage.rtlRotate": {
    transform: "rotate(180deg)",
  },
  "& .headerText": {
    fontSize: '18px',
    color: 'white',
    fontFamily: 'Poppins',
    fontWeight: 600,
  },
  "& .currencySelectorWrapper": {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  "& .settingsIcon": {
    width: '41px',
    height: '40px',
    cursor: "pointer",
  },
  "& .contentWrapper": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginTop:'16px'
  },
  "& .formBox": {
    height: "200px",
    background: "#2A2A39",
    boxShadow: "0px 0px 10px 2px rgba(6, 141, 132, 0.44982)",
    borderRadius: "12px",
    cursor: "pointer",
    transition: "all .3s ease",
    "& .label": {
      color: "#FFFFFF",
      fontSize: "15px",
    },
    "& img": {
      color: "black",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .formBox.selected": {
    background: "#2CE2D5",
    "& .label": {
      color: "#3C3C50",
    },
  },
  "& .formsContainer": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  "& .cardImage": {
    width: '50px',
    height: '50px',
    marginBottom: "15px",
  },
  "& .nextButtonWrapper": {
    position: 'fixed',
    bottom: '20px',
    right: '30px',
    zIndex: 1000,
  },
  "& .nextButton3": {
    textAlign: 'center',
    lineHeight: '24px',
    fontWeight: 700,
    fontFamily: 'Poppins',
    textTransform: 'none',
    color: '#3C3C50',
    fontSize: '17px',
    width: "auto",
    backgroundColor:"#c9f9f6 !important",
    padding: '10px 35px',
    margin: '15px',
    borderRadius: '0.70rem', 

  }
});
// Customizable Area End

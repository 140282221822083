import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Grid,
  Dialog,
  DialogContent,
  CssBaseline,
  Input,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Snackbar,
  styled
} from "@material-ui/core";
import { ErrorMessage, Form, Formik } from "formik";
import { CustomTextFieldControl } from "../../../components/src/TextFieldFormik";
import Select from "react-select";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';

import UserProfile from "../../../components/src/UserProfile.web";



let theme = createTheme({
  palette: {
    primary: {
      main: "#2CE2D5",
      contrastText: "#fff",
    },
  },
});

theme = createTheme(theme, {
  overrides: {
    MuiSvgIcon: {
      root: {
        color: '#94989c',
      },
    },
    MuiOutlinedInput: {
      root: {
        "& .MuiOutlinedInput-input": {
          color: "white",
          borderBottom: '0px',
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "#fff",
        },
        "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
          borderColor: "#fff",
        },
        "& $notchedOutline": {
          borderColor: "#94989c",
        },
        "& .MuiAutocomplete-endAdornment": {
          position: 'unset'
        }
      },
    },
    MuiDialog: {
      paper: {
        boxShadow: '0px 0px 10px 0px rgba(44, 226, 213, 0.16)',
        backgroundColor: '#395D6B',
        borderRadius: '8px',
        width: 'max-content',
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#3C3C51",
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        backgroundColor: "rgb(57, 93, 107)",
        color: "rgb(181, 176, 176)"

      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: "rgb(57, 93, 107)",
        color: "rgb(181, 176, 176)",
        "& button": {
          backgroundColor: "rgb(57, 93, 107)",
          color: "rgb(181, 176, 176)",
        }
      },
      dayheader: {
        backgroundColor: "rgb(57, 93, 107)",
        color: "rgb(181, 176, 176)"
      },
      dayLabel: {
        color: "rgb(181, 176, 176)"
      }
    },
    MuiPickersDay: {
      day: {
        color: "rgb(181, 176, 176)",
      },
      daySelected: {
        backgroundColor: "#2CE2D5",
      },
      dayDisabled: {
        color: "#ccc",
      },
      current: {
        color: "rgb(181, 176, 176)"
      },
      dayLabel: {
        color: "rgb(181, 176, 176)"
      }
    },
    MuiPickersModal: {
      dialogAction: {
        color: "#33abb6",
        backgroundColor: "#3C3C51",
      },
    },
    MuiTypography: {
      root: {
        "&.heading": {
          fontWeight: 600,
          fontSize: "28px",
          color: "#fff",
          fontFamily:"Poppins",
        },
        "&.title": {
          fontSize: "16px !important",
          color: "#fff",
          fontWeight: "300 !important",
          fontFamily:"Poppins",
          marginTop:"16px"

        },
        "&.title-disabled": {
          fontSize: "16px !important",
          color: "#928f98",
          fontFamily:"Poppins",
          marginTop:"16px"
        },
        "&.subtitle": {
          fontSize: "18px",
          fontWeight: 600,
          color: "#fff",
          fontFamily:"Poppins",
          wordBreak: 'break-word',
          whiteSpace: 'normal'
        },
        "&.subtitle-disabled": {
          fontSize: "20px",
          fontWeight: 600,
          color: "#928f98",
          fontFamily:"Poppins",
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          display: 'block',
          width: '100%',
        },
        "&.reset-heading": {
          fontSize: "14px",
          fontWeight: 600,
          color: "#fff",
          fontFamily:"Poppins",
        },
        
      }
    },
  },
});


// Customizable Area End

import WealthPlannerSettingsController, {
  Props,
  configJSON,
} from "./WealthPlannerSettingsController";
import { getFirstCharacterFromEachWord } from "../../../components/src/commonFn";
import { calendarImg } from "../../dashboard/src/assets";
import { closeIcon, deleteIcon, editIcon, resetIcon, varrow } from "./assets";
import { AlertImage } from "../../proposalgeneration2/src/assets";


export default class WealthPlannerSettings extends WealthPlannerSettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  renderPhotoDialog = () => {
    return (

      <Dialog
        open={this.state.openPhotoDialog}
        maxWidth="md"
        keepMounted
        aria-labelledby="photo-dialog"
        aria-describedby="photo-dialog"
        PaperProps={{ style: webStyle.dialogStyle }}
      >
        {
          this.state.plannerInfo &&
          <DialogContent style={{
            padding: "40px 80px",
            position: "relative"
          }}>
            <Box style={{
              marginTop: "18px"
            }}>
              <div style={webStyle.closeIcon} data-test-id="closeDialog" onClick={() => {
                this.handleClosePhotoDialog()
              }}>
                <img src={closeIcon} alt="" />
              </div>
            </Box>
            {
              this.state.plannerInfo.attributes.image ?
                <Box style={{
                  textAlign: "center",
                  marginTop: "18px"
                }}>
                  <img style={webStyle.photoContainer} src={this.state.plannerInfo.attributes.image} />
                </Box>
                :
                <Box style={webStyle.noImgContainerDialog}>
                  <div style={webStyle.noImg}>
                    {getFirstCharacterFromEachWord(this.state.plannerInfo.attributes.name)}
                  </div>
                </Box>
            }
            <Typography style={{...webStyle.fieldError,textAlign:"center",marginTop:"12px"}}>
              {this.state.errorMessage}
            </Typography>
            {
              this.state.isUpdatingPhoto ?
                <Button style={webStyle.saveBtn} >
                  Uploading
                </Button> :
                <Button style={webStyle.saveBtn} data-test-id="pictureUpload" onClick={this.openPictureUpload}>
                  {!this.state.plannerInfo.attributes.image ? "Upload Profile Picture" : "Change Profile Picture"}
                </Button>
            }
            {
              this.state.isUpdatingPhoto && 
              <div style={webStyle.progressBarStyle}>
              <div  style={webStyle.progressBarFillStyle} />
            </div>
            }
            <Typography style={webStyle.infoTxt}>
              Maximum size: 1MB. Supported formats:JPEG, GIF, or PNG
            </Typography>
            <Input
                  data-test-id="FileInput"
                  inputRef={this.fileInputRef}
                  style={{
                    visibility:"hidden"
                  }}
                  type="file"
                  inputProps={{ accept : "image/png, image/jpeg, image/gif" ,multiple:false }}
                  onChange={this.handleFileChange}
                />
          </DialogContent>
        }
      </Dialog>
    )
  }

  renderConfirmDialog = () => {
    return (
      <Dialog

        open={this.state.confirmDialog}
        keepMounted
        data-test-id="dialogModel"
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{ style: webStyle.dialogStyle }}
      >
        <DialogContent style={{ padding: '40px 80px' }}>
          <Box id="alert-dialog-slide-description" style={{
            textAlign: "center"
          }}>
            <Typography style={webStyle.successMessage}>
              You have unsaved changes
            </Typography >
            <div style={webStyle.btnContainer}>
              <Button style={webStyle.cancelBtnRed} data-test-id="cancelUpdate" onClick={() => this.handleCloseConfirmationPopup()}>
                Cancel
              </Button>
              <Button style={webStyle.cancelBtn} data-test-id="saveUpdate" onClick={() => this.handleSubmit()} >
                Save
              </Button>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    )
  }

  renderDeleteDialog = () => {
    return (
      <Dialog
        open={this.state.openDeleteDialog}
        keepMounted
        data-test-id="dialogModel"
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{ style: webStyle.dialogStyle }}
      >
        <DialogContent style={{ padding: '40px 80px' }}>
          <Box id="alert-dialog-slide-description" style={{
            textAlign: "center"
          }}>
            <Typography style={webStyle.successMessage}>
             Are you sure you want to delete your
            </Typography >
            <Typography style={webStyle.successMessage}>
            Account permanently?
            </Typography >
            <div style={webStyle.btnContainer}>
              <Button style={webStyle.cancelBtnRed} data-test-id="saveDelete" onClick={() => this.hadleDeleteAccount()}>
                Delete
              </Button>
              <Button style={webStyle.cancelBtn} data-test-id="cancelDelete" onClick={() => this.cancelDeleteAccount()} >
                No
              </Button>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    )
  }

  renderSnackBar = () =>{
    return(
      <Snackbar open={this.state.openSnackbar} autoHideDuration={6000} anchorOrigin={{
        vertical: 'top', horizontal: 'center'
      }} onClose={this.handleCloseSnackbar}>
        <Box style={webStyle.snakbar}>
          <Grid container justifyContent="center" alignContent="center" alignItems="center">
            <Grid item>
            <img src={AlertImage} alt="" style={{
              paddingTop: "4px",
              paddingRight: "12px"
            }}/>
            </Grid>
            <Grid item>
            {this.state.errorMsg}
            </Grid>

          </Grid>
        </Box>
      </Snackbar>
    )
  }

  renderResetScreen = () => {
    return (
      <Box>
        {
          this.state.isResettingPassoword &&
          <Box>
            <Typography className="reset-heading" >Reset Password</Typography>
            <Formik
              enableReinitialize
              initialValues={{
                currentPassword: "",
                newPassword: "",
                confirmNewPassword: ""
              }}
              onSubmit={(values) => this.handleSubmitResetPassword({
                old_password: values.currentPassword,
                new_password: values.newPassword
              })}
              data-test-id="resetForm"
              validationSchema={this.validationResetPasswordSchema}
            >
              {({ values, handleSubmit, handleChange }) => (
                <Form noValidate onSubmit={handleSubmit} data-test-id='resetForm' translate={"en"}>
                  <Grid container spacing={4} style={{
                    marginTop: "24px"
                  }}>
                    <Grid item md={4} sm={12}>
                      <OutlinedInput
                        type={this.state.isOldResetPasswordVisible ? 'text' : 'password'}
                        data-test-id="currentPassword"
                        placeholder={"Current Password"}
                        fullWidth={true}
                        style={webStyle.inputRoot}
                        name="currentPassword"
                        value={values.currentPassword}
                        onChange={handleChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.handleToggleCurrentPassword}
                              edge="end"
                              data-test-id="toggleBtn"
                            >
                              {this.state.isOldResetPasswordVisible ? (
                                <VisibilityOutlinedIcon style={webStyle.visibilityStyle} />
                              ) : (
                                <VisibilityOffOutlinedIcon style={webStyle.visibilityStyle} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      <Box sx={webStyle.fieldError}>
                        <ErrorMessage name="currentPassword" />
                      </Box>
                    </Grid>
                    <Grid item md={6} sm={12} />
                    <Grid item md={4} sm={12}>
                      <OutlinedInput
                        type={this.state.isNewesetPasswordVisible ? 'text' : 'password'}
                        data-test-id="newPassword"
                        placeholder={"New Password"}
                        fullWidth={true}
                        style={webStyle.inputRoot}
                        name="newPassword"
                        value={values.newPassword}
                        onChange={handleChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.handleToggleNewPassword}
                              edge="end"
                              data-test-id="toggleBtn"
                            >
                              {this.state.isNewesetPasswordVisible ? (
                                <VisibilityOutlinedIcon style={webStyle.visibilityStyle} />
                              ) : (
                                <VisibilityOffOutlinedIcon style={webStyle.visibilityStyle} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      <Box sx={webStyle.fieldError}>
                        <ErrorMessage name="newPassword" />
                      </Box>

                    </Grid>
                    <Grid item md={6} sm={12} />
                    <Grid item md={4} sm={12}>
                      <OutlinedInput
                        type={this.state.isConfirmNewResetPasswordVisible ? 'text' : 'password'}
                        data-test-id="confirmNewPassword"
                        placeholder={"Confirm New Password"}
                        fullWidth={true}
                        style={webStyle.inputRoot}
                        name="confirmNewPassword"
                        value={values.confirmNewPassword}
                        onChange={handleChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.handleToggleConfirmPassword}
                              edge="end"
                              data-test-id="toggleBtn"
                            >
                              {this.state.isConfirmNewResetPasswordVisible ? (
                                <VisibilityOutlinedIcon style={webStyle.visibilityStyle} />
                              ) : (
                                <VisibilityOffOutlinedIcon style={webStyle.visibilityStyle} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      <Box sx={webStyle.fieldError}>
                        <ErrorMessage name="confirmNewPassword" />
                      </Box>
                      <Typography data-test-id="forgot-password" style={webStyle.forgotBtnLink} onClick={() => this.handleForgotPassword()}>Forgot Password? </Typography>


                    </Grid>
                    <Grid item md={8} sm={12} />
                   
                    <Grid item md={3}>
                      <Button style={webStyle.saveBtn} type="submit" data-test-id="submitResetForm" onClick={this.handleSubmit}>
                        Confirm Password
                      </Button>
                    </Grid>
                  </Grid>


                </Form>
              )}
            </Formik>
          </Box>
        }

      </Box>
    )
  }



  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MainWrapper className="pagedashboard-wrapper2">
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {this.renderSnackBar()}

          <Box >
            {
              this.state.plannerInfo && 
              <UserProfile username={this.state.plannerInfo.attributes.name} email={this.state.plannerInfo.attributes.email} profilePicture={this.state.plannerInfo.attributes.image} />
            }
            <Box style={{
              display:"flex",
              alignContent:"center",
              justifyContent:"space-between",
              alignItems:"center"
            }}>
              <Typography className="heading" >Account Details</Typography>
              {
                this.state.isResettingPassoword && 
                <Box onClick={()=> this.cancelResetPassword()} data-test-id="closeReset">
                <img src={closeIcon} />
              </Box>
              }
              
            </Box>
            {
              this.state.plannerInfo && !this.state.isEdit && !this.state.isResettingPassoword &&
              <Grid container style={{
                marginTop: "24px"
              }} spacing={4}>
                <Grid item md={2} sm={12}>
                  {this.state.plannerInfo.attributes.image ?
                    <Box style={{
                      textAlign: "right",
                    }}>
                      <img style={webStyle.photoContainer} src={this.state.plannerInfo.attributes.image} />
                    </Box>
                    :
                    <Box style={webStyle.noImgMainContainer}>
                      <div style={webStyle.noImg}>
                        {getFirstCharacterFromEachWord(this.state.plannerInfo.attributes.name)}
                      </div>
                    </Box>
                  }
                </Grid>
                <Grid item md={8} sm={12}>
                  <Grid container>
                    <Grid item md={8} >
                      <Typography className="title">Full Name: </Typography>
                      <Typography className="subtitle"> {this.state.plannerInfo.attributes.name} </Typography>
                    </Grid>
                    <Grid item md={4}>
                      <Typography style={webStyle.btnLink} data-test-id="editDetails" onClick={() => this.handleEditDetails()}>Edit Details </Typography>
                    </Grid>
                    <Grid item md={6} style={{
                            marginTop:"18px"
                          }}>
                      <Typography className="title-disabled">  Email: </Typography>
                      <Typography className="subtitle-disabled">  {this.state.plannerInfo.attributes.email} </Typography>
                    </Grid>
                    <Grid item md={6} />
                    <Grid item md={4}>
                      <Typography className="title">{configJSON.languageListForecasting.English.Mobile} </Typography>
                      <Typography className="subtitle">  {this.state.plannerInfo.attributes.mobile} </Typography>
                    </Grid>
                    <Grid item md={4}>
                      <Typography className="title"> Date of Birth:  </Typography>
                      <Typography className="subtitle"> {this.state.plannerInfo.attributes.dob} </Typography>
                    </Grid>
                    <Grid item md={4}>
                      <Typography className="title"> Location:  </Typography>
                      <Typography className="subtitle"> {this.state.plannerInfo.attributes.location}</Typography>
                    </Grid>
                    <Grid item md={7}>
                      <Typography className="title"> Firm:  </Typography>
                      <Typography className="subtitle"> {this.state.plannerInfo.attributes.firm}</Typography>

                    </Grid>
                    <Grid item md={1}></Grid>

                    <Grid item md={4}>
                      <Typography className="title"> Jurisdiction Advice:  </Typography>
                      <Typography className="subtitle">{this.state.plannerInfo.attributes.jurisdiction_advice}</Typography>
                    </Grid>
                    <Grid item md={7}>
                      <Typography className="title"> Certification:  </Typography>
                      <Typography className="subtitle"> {this.state.plannerInfo.attributes.certificate}</Typography>

                    </Grid>
                    <Grid item md={1}></Grid>
                    <Grid item md={4}>
                      <Typography className="title"> Qualification:  </Typography>
                      <Typography className="subtitle"> {this.state.plannerInfo.attributes.qualification}</Typography>
                    </Grid>
                    <Grid item md={7}>
                      <Typography className="title"> Employement History:  </Typography>
                      <Typography className="subtitle"> {this.state.plannerInfo.attributes.employment_history}</Typography>
                    </Grid>
                    <Grid item md={1}></Grid>
                    <Grid item md={4}>
                      <Typography className="title"> Disclosure:  </Typography>
                      <Typography className="subtitle"> {this.state.plannerInfo.attributes.disclosure}</Typography>
                    </Grid>
                    <Grid item md={7}>
                      <Typography className="title"> Experience:  </Typography>
                      <Typography className="subtitle"> {this.state.plannerInfo.attributes.experience}  {this.state.plannerInfo.attributes.experience > 1 ? "Yrs" : "Yr"}</Typography>
                    </Grid>
                    <Grid item md={1}></Grid>

                    <Grid item md={4}>
                      <Typography className="title"> Firm Website:  </Typography>
                      <Typography className="subtitle"> {this.state.plannerInfo.attributes.firm_website}</Typography>
                    </Grid>
                    <Grid item md={12}>
                      <Typography className="title"> Focus:  </Typography>
                      <Box style={{
                        display:"flex",
                        flexWrap: "wrap",
                        gap: "5px",
                      }}>
                      {this.state.plannerInfo.attributes.focus.map(({ name }) => {
                        return <Typography style={webStyle.chipContainer} component={"div"}>
                          {name}
                        </Typography>
                      })}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            }
            {
             !this.state.isResettingPassoword &&  <Formik
             enableReinitialize
             initialValues={this.state.initialValues}
             onSubmit={(values) => { this.handleOpenConfirmDialog(values) }}
             data-test-id="profileForm"
             validationSchema={this.validationSchema}
           >
             {({ values, handleSubmit, setFieldValue }) => (
               <Form noValidate onSubmit={handleSubmit} data-test-id='submitForm' translate={"en"}>
                 {
                   this.state.plannerInfo && this.state.isEdit &&
                   <Grid container spacing={4} style={{
                     marginTop: "24px"
                   }}>
                     <Grid item md={2} sm={12}>

                       {this.state.plannerInfo.attributes.image ?

                         <Box style={{
                           textAlign: "right",
                           position: "relative"
                         }}>
                           <div style={webStyle.editIcon} data-test-id="editPhoto" onClick={() => {
                             this.handleEditPhoto()
                           }} >
                             <img src={editIcon} alt="editicon" />
                           </div>
                           <img style={webStyle.photoContainer} src={this.state.plannerInfo.attributes.image} />
                         </Box>
                         :
                         <Box style={webStyle.noImgContainer}>
                           <div style={webStyle.editIcon} onClick={() => {
                             this.handleEditPhoto()
                           }} data-test-id="editPhoto" >
                             <img src={editIcon} alt="editicon" />
                           </div>
                           <div style={webStyle.noImg}>
                             {getFirstCharacterFromEachWord(this.state.plannerInfo.attributes.name)}
                           </div>
                         </Box>
                       }

                     </Grid>
                     <Grid item md={8} sm={12}>
                       <Grid container spacing={4}>
                         <Grid item md={4} className="py-0">
                           <Typography className="title">Full Name: </Typography>
                           <CustomTextFieldControl placeHolder="Enter name" label="" name="name" />
                         </Grid>
                         <Grid item md={2}  className="py-0"></Grid>
                         <Grid item md={6}  className="py-0">
                           <Typography style={webStyle.btnLink} onClick={() => handleSubmit()}>Confirm Changes </Typography>
                         </Grid>

                         <Grid item md={6}   className="py-0" style={{
                           marginTop:"18px"
                         }}>
                           <Typography className="title-disabled">  Email: </Typography>
                           <Typography className="subtitle-disabled">  {this.state.plannerInfo.attributes.email} </Typography>
                         </Grid>
                         <Grid item md={6}  className="py-0"/>

                         <Grid item md={7} >
                          <Grid container spacing={4}>
                          <Grid item md={6}  className="py-0">
                          <Typography className="title">Mobile: </Typography>
                           <CustomTextFieldControl  placeHolder="Enter mobile" label="" name="mobile" />
                          </Grid>
                          <Grid item md={6}  className="py-0">
                           <Typography className="title"> Date of Birth:  </Typography>
                           <Box data-test-id="datePicker">
                             <MuiPickersUtilsProvider utils={DateFnsUtils}>
                               <KeyboardDatePicker
                                 error={false}
                                 placeholder="DD/MM/YYYY"
                                 variant="inline"
                                 fullWidth
                                 inputVariant="outlined"
                                 format="dd/MM/yyyy"
                                 name="dob"
                                 value={values.dob}
                                 onChange={(values) => setFieldValue("dob", values, true)}
                                 autoOk
                                 InputProps={{
                                   style: {
                                     padding: '10px 0px',
                                     borderRadius: '8px',
                                     color: 'white'
                                   }
                                 }}
                                 KeyboardButtonProps={{
                                   style: {
                                     color: 'white'
                                   }
                                 }}
                                 data-test-id="dob"
                                 helperText=""
                                 className="uploadBox newBoxContant12Pension"
                                 keyboardIcon={<img src={calendarImg} style={{
                                   borderRadius: "0",
                                   height: "18px",
                                   width: "18px",
                                   marginTop: "-4px",
                                   marginRight: "4px"
                                 }} />}
                               />
                             </MuiPickersUtilsProvider>
                           </Box>
                           <Box sx={webStyle.fieldError}>
                             <ErrorMessage name="dob" />
                           </Box>
                         </Grid>
                          </Grid>
                          
                         </Grid>
                       
                         <Grid item xs={12} md={5} style={{maxWidth: "380px"}} className="py-0">
                           <Typography className="title"> Location:  </Typography>
                           <Select
                             styles={{
                               control: (defaultStyles) => ({
                                 ...defaultStyles,
                                 fontSize: '16px',
                                 fontFamily: 'Poppins',
                                 textAlign: "left",
                                 fontWeight: 500,
                                 backgroundColor: "#2a2a38",
                                 borderRadius: '8px',
                                 padding: "8px",
                                 color: 'rgba(255, 255, 255, 0.60)',
                               }),
                             }}
                             classNamePrefix="an-simple-select"
                             options={this.state.locationDropdown}
                             onChange={(event) => event && setFieldValue("location", event)}
                             className="selectMenu selectedMenuDropdown"
                             data-test-id="selectLocation"
                             value={values.location}
                             placeholder="Select Currency"
                           />
                           <Box sx={webStyle.fieldError}>
                             <ErrorMessage name="location.value" />
                           </Box>
                         </Grid>
                         <Grid item md={5}  className="py-0">
                           <Typography className="title"> Firm:  </Typography>
                           <CustomTextFieldControl placeHolder="Enter firm" label="" name="firm" />

                         </Grid>
                         <Grid item md={2}  className="py-0"></Grid>

                         <Grid item md={5}  className="py-0">
                           <Typography className="title"> Jurisdiction Advice:  </Typography>
                           <CustomTextFieldControl placeHolder="Enter jurisdiction advice" label="" name="jurisdiction_advice" />

                         </Grid>
                         <Grid item md={5}  className="py-0">
                           <Typography className="title"> Certification:  </Typography>
                           <CustomTextFieldControl placeHolder="Enter certification" label="" name="certificate" />
                         </Grid>
                         <Grid item md={2}  className="py-0"></Grid>
                         <Grid item md={5}  className="py-0">
                           <Typography className="title"> Qualification:  </Typography>
                           <CustomTextFieldControl placeHolder="Enter qualification" label="" name="qualification" />
                         </Grid>
                         <Grid item md={5}  className="py-0">
                           <Typography className="title"> Employement History:  </Typography>
                           <CustomTextFieldControl placeHolder="Enter employement history" label="" name="employment_history" />
                         </Grid>
                         <Grid item md={2}  className="py-0"></Grid>
                         <Grid item md={5}  className="py-0">
                           <Typography className="title"> Disclosure:  </Typography>
                           <CustomTextFieldControl placeHolder="Enter disclosure" label="" name="disclosure" />
                         </Grid>
                         <Grid item md={5}  className="py-0">
                           <Typography className="title"> Experience:  </Typography>
                           <CustomTextFieldControl type="number" placeHolder="Enter experience" label="" name="experience" />
                         </Grid>
                         <Grid item md={2}  className="py-0"></Grid>
                         <Grid item md={5}  className="py-0">
                           <Typography className="title"> Firm website:  </Typography>
                           <CustomTextFieldControl placeHolder="Enter firm website" label="" name="firm_website" />
                         </Grid>
                         <Grid item md={12}  className="py-0">
                           <Typography className="title"> Focus:  </Typography>
                           <Select
                             closeMenuOnSelect={false}
                             isClearable={false}
                             isMulti
                             value={values.focus}
                             onChange={(event) => {
                               setFieldValue("focus", event)
                             }}
                             options={this.state.focusDropdown}
                             styles={{
                               control: (defaultStyles) => ({
                                 ...defaultStyles,
                                 backgroundColor: "#2a2a38",
                                 borderRadius: '8px',
                                 padding: "8px",
                                 color: 'rgba(255, 255, 255, 0.60)',
                                 fontSize: '16px',
                                 fontFamily: 'Poppins',
                                 textAlign: "left",
                                 fontWeight: 500,
                               }),

                               multiValue: (styles) => {
                                 return {
                                   ...styles,
                                   backgroundColor: "#395d6b",
                                   padding:"4px",
                                   borderRadius:"12px",
                                   marginRight:"6px"
                                 };
                               },
                               multiValueLabel: (styles) => ({
                                 ...styles,
                                 color: "#fff",
                               }),
                               multiValueRemove: (styles) => ({
                                 ...styles,
                                 color: 'white',
                                 ':hover': {
                                   backgroundColor: "#395d6b",
                                   color: 'white',
                                 },
                               }),
                             }}
                             classNamePrefix="an-simple-select"
                             className="selectMenu selectedMenuDropdown"
                             data-test-id="selectFocus"
                             placeholder="Select Focus"
                           />
                             <Box sx={webStyle.fieldError}>
                             <ErrorMessage name="focus" />
                           </Box>
                         </Grid>
                       </Grid>
                     </Grid>
                   </Grid>
                 }
               </Form>
             )}
           </Formik>
            }
            {
              !this.state.isResettingPassoword &&      <Grid container style={{
                marginTop:"4rem"
              }}>
                <Grid item md={1}></Grid>
                <Grid item md={8}>
                  <Box >
                    <Box style={webStyle.linkContainer} data-test-id="resetPassword" onClick={()=>{
                      this.handleResetPassword()
                    }}>
                      <Box>
                      <img src={resetIcon} alt="reset" />
  
                      </Box>
                      <Box style={webStyle.linkText}>
                        Reset Password
                      </Box>
                      <Box  style={webStyle.arrowIcon}> 
                        <img src={varrow} alt="varrow" />
                      </Box>
                    </Box>
                    <Box>
                    <Box style={webStyle.linkContainer} data-test-id="deleteAccount" onClick={()=>{
                      this.hadleDeleteAccountDialog()
                    }}>
                    <Box>
                      <img src={deleteIcon} alt="reset" />
  
                      </Box>
                      <Box style={webStyle.linkText}>
                        Delete Account
                      </Box>
                      </Box>
                     
                    </Box>
                  </Box>
                </Grid>
  
              </Grid>
            }     
          </Box>

          {this.renderPhotoDialog()}
          {this.renderConfirmDialog()}
          {this.renderResetScreen()}
          {this.renderDeleteDialog()}


        </ThemeProvider>

      </MainWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
type IStyle = { [key: string]: React.CSSProperties };

const webStyle: IStyle = {
  photoContainer: {
    height: "160px",
    width: "160px",
    borderRadius: "50%",
  },
  editIcon: {
    position: "absolute",
    left: 0,
    right: -4,
    bottom: 16,
    zIndex: 1,
    cursor:"pointer"
  } ,
  dialogStyle: { backgroundColor: '#3b3c50', borderRadius: '8px' ,boxShadow: "none",    border:"1px solid #57575d"},

  btnLink: {
    fontSize: "16px",
    fontFamily: "Poppins",
    color: "#814ff9",
    fontWeight: 600,
    paddingTop:"15px",
    cursor:"pointer"
  },
  noImg: {
    height: "160px",
    width: "160px",
    borderRadius: "50%",
    backgroundColor: "2CE2D5",
    color: "#fff",
    fontWeight: 600,
    fontSize:"32px",
    display: "flex",
    alignItems: "center",
    justifyContent: 'center',
    textTransform: "uppercase"
  },
  chipContainer: {
    backgroundColor: "#395d6b",
    color: "#fff",
    padding: "8px",
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: 600,
    marginRight: "12px",
    borderRadius: "12px"

  },
  fieldError: {
    color: "#EE5353",
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',

  },
  noImgContainer:{
    textAlign: "right",
    position: "relative",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: 'flex-end',
  },
  btnContainer: {display:"flex",justifyContent:"space-between", textAlign: "center", marginTop: "18px",fontFamily: "Poppins", flexWrap: "wrap", gap: "20px" },
  saveBtn: {marginTop:"18px", color: "#3c3c50", background: "#c9f9f6", width: "100%", fontFamily: "Poppins", fontSize: "20px", fontWeight: 600, textTransform: "none", border: "1px solid #c9f9f6", borderRadius: "8px", padding: "10px" },
  cancelBtnRed: { color: "#ee5353", width:"180px", fontFamily: "Poppins", fontSize: "20px", fontWeight: 600, textTransform: "none", border: "1px solid #ee5353", borderRadius: "8px",padding:"10px" } ,
  cancelBtn: { color: "#c9f9f6", width:"180px", fontFamily: "Poppins", fontSize: "20px", fontWeight: 600, textTransform: "none", border: "1px solid #c9f9f6", borderRadius: "8px",padding:"10px" } ,
  successMessage: {fontSize: "20px",color: "#fff",fontFamily: "Poppins"},
  closeIcon:{
    top: 20,
    right: 20,
    position:"absolute",
    height:"22px",
    width:"22px",
    cursor:"pointer"

  },
  infoTxt:{
    color: "#fff",
    fontSize: "16px",
    fontFamily: "Poppins",
    marginTop:"12px"
  },
  noImgContainerDialog:{
    textAlign: "center",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: 'center',
    marginTop:"18px"

  },
  progressBarStyle : {
    width: '100%',
    height: '5px',
    backgroundColor: '#d8d8d8',
    borderRadius: '25px',
    overflow: 'hidden',
    marginTop:"18px"
  },
  progressBarFillStyle :{
    height: '100%',
    width: `75%`,
    backgroundColor: '#2de2d5',
    textAlign: 'center',
    lineHeight: '25px',
    color: 'white',
    transition: 'width 0.5s ease-in-out',
    borderRadius: '25px 0 0 25px',
  },
  noImgMainContainer:{
    textAlign: "right",
    position: "relative",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: 'flex-end',
  },
  linkContainer:{
    backgroundColor: "#3c3c51",
    padding: "22px",
    borderRadius:"12px",
    display: "flex",
    alignItems: "center",
    marginTop:"18px",
    border:"1px solid #57575d",
    cursor: "pointer"
  },
  arrowIcon:{
    marginLeft:"auto"
  },
  linkText:{
    color: "#fff",
    fontSize: "16px",
    fontFamily: "Poppins",
    marginLeft:"22px",
    fontWeight:700,
  },
  visibilityStyle:{
    cursor: "pointer", color: 'white',
    opacity: 0.2
  },
  inputRoot: {
    borderRadius: "8px",
    height: "56px",
    outline: "none",
    fontFamily: "Poppins",
  },
  forgotBtnLink: {
    fontSize: "16px",
    fontFamily: "Poppins",
    color: "#814ff9",
    fontWeight: 600,
    paddingTop:"15px",
    cursor:"pointer",
    textAlign:"right"
  },
  snakbar: {display:"flex", background: "#f7c1ba", color: "#ee5353",fontSize: "14px",fontFamily: "Poppins",paddingRight: "4rem",paddingLeft: "4rem",padding:'23px',minWidth: "440px",textAlign: "center",    borderRadius: "8px",height:"45px"}
};
const MainWrapper = styled(Box)({
  paddingBottom:"40px",
  "& .py-0": {
    paddingTop: "0px",
    paddingBottom:"0px"
  },
})
// Customizable Area End 
import React from "react";

// Customizable Area Start
import {
  Box,FormControl,ButtonGroup, Button,Typography,Grid
} from "@material-ui/core";
import Select from 'react-select';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { PieChart, Pie, Cell, ResponsiveContainer, Label, BarChart,CartesianGrid, XAxis, Tooltip, Bar} from 'recharts';
import { image_Vuesax, imagenav_BackIcon } from "./assets";
import { formatNumberWithAbbreviations, formatNumberWithCommasAndDecimals } from "../../../components/src/commonFn";
import ProgressBar from "../../../components/src/ProgressBar";
import { renderLabel ,renderActiveShape, renderInnerLable, customTooltip,
   customYAxisBarChart,customLegend} from "../../../components/src/ChartUtils";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
import CustomTooltip from "../../../components/src/CustomTooltip/CustomTooltip";
// Customizable Area End

import NewDashboardController, {
  Props,
  configJSON,
} from "./NewDashboardController";

export default class NewDashboard extends NewDashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  renderCurrencyPieChart = () => {
    return (
      <Box style={webStyle.currencyPieChartContainer}>
        <Box style={webStyle.pieChartCointainer}>
          <Typography style={webStyle.chartHeading}>
            {this.state.assetsType === "Country"?  this.getStringNewDashboard("countryExposure") :this.getStringNewDashboard("currencyExposure") }
          </Typography>
          <ButtonGroup disableElevation variant="contained" style={{ borderRadius: "12px", height: '35px' }}>
            <Button
              data-test-id="currency-change"
              onClick={() => { this.changeType('Currency') }}
              style={{
                textTransform: 'none',
                borderRadius: this.state.direction === "ltr" ? "12px 0px 0px 12px" : "0px 12px 12px 0px",
                backgroundColor: this.state.assetsType === 'Currency' ? "#2DE2D5" : '#D8D8D8',
                ...webStyle.pieChartHeaderButton,
              }}
            >
              {this.getStringNewDashboard("currency")}
            </Button>
            <Button
              data-test-id='country-change'
              onClick={() => { this.changeType('Country') }}
              style={{
                textTransform: 'none',
                borderRadius: this.state.direction === "ltr" ? "0px 12px 12px 0px" : "12px 0px 0px 12px",
                backgroundColor: this.state.assetsType === 'Country' ? "#2DE2D5" : '#D8D8D8',
                ...webStyle.pieChartHeaderButton,
              }}
            >
              {this.getStringNewDashboard("country")}
            </Button>
          </ButtonGroup>
        </Box> 
        <ResponsiveContainer height={375} width="100%" >
          <PieChart>
            <Tooltip content={<CustomTooltip />}/>
            <Pie
              data={this.state.currencyPieChartData}
              innerRadius={115}
              fill="#fff"
              dataKey="value"
              outerRadius={140}
              isAnimationActive={false}
              labelLine={false}
              stroke="none" 
              data-test-id="piechartCurrency"
              activeShape={renderActiveShape}
              onMouseEnter={this.onCurrencyPieEnter}
              onMouseLeave={this.onCurrencyPieOut}
              activeIndex={this.state.activeCurrencyIndex}
            >
              {this.state.currencyPieChartData.map((_entry, index) => (
                <Cell key={`cell-${index}`} fill={this.PIE_CURRENCY_COLORS[index % this.PIE_CURRENCY_COLORS.length]} />
              ))}
              <Label value={`${this.state.currencyPieChartInfo.currency_symbol}${formatNumberWithAbbreviations(this.state.currencyPieChartInfo.total_networth+ '')}`} fill="#fff" style={webStyle.labelStyle} position="center"></Label>
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </Box>
    )
  }

  renderPerformance = () => {
    return (
      <Box style={webStyle.currencyPieChartContainer}>
        <Box style={webStyle.headingCotnainer}>
          <Box>
          <Typography style={webStyle.performanceGraphHeading}>
              {this.getStringNewDashboard("performance")}
          </Typography>
            <Box style={webStyle.performanceMatrixContainer}>
              <Typography style={webStyle.totalGainText}>
                Total gain
              </Typography><Typography style={webStyle.totalGainPlusText}>
              {this.state.currencyPieChartInfo.currency_symbol}
              {formatNumberWithCommasAndDecimals(this.state.financialSummary2?.total_gain?.toString() ?? '0')}<span style={{ marginLeft: '5px', marginRight: '5px' }}>|</span> 
              {(this.state.financialSummary2?.total_gain_percentage ?? 0) >= 0 
            ? `+${this.state.financialSummary2?.total_gain_percentage}` : this.state.financialSummary2?.total_gain_percentage}%
              </Typography>
            </Box>
          </Box> 
          <Box style={webStyle.filterContainer}>
            <Typography data-test-id="6M" style={this.state.performanceFilter ==="6M" ? webStyle.activeStyle  : webStyle.inActiveStyle} onClick={() => { this.handleSetPerformanceFilter("6M")}}>
                 {this.getStringNewDashboard("6M")}
            </Typography>
            <Typography  data-test-id="1Y" style={this.state.performanceFilter === "1Y" ? webStyle.activeStyle  : webStyle.inActiveStyle} onClick={() => { this.handleSetPerformanceFilter("1Y")}}>
              {this.getStringNewDashboard("1Y")}
            </Typography>
            <Typography data-test-id="YTD" style={this.state.performanceFilter === "YTD" ? webStyle.activeStyle  : webStyle.inActiveStyle} onClick={() => { this.handleSetPerformanceFilter("YTD")}}>
            {this.getStringNewDashboard("YTD")}
            </Typography>
          </Box>
        </Box>
        <ResponsiveContainer width="100%" height={600}>
          <BarChart
          stackOffset="sign"
            data={this.state.performanceData} barSize={80} margin={{ top: 20, right: 30, left: 50, bottom: 5 }} >
            <CartesianGrid stroke="#eee" strokeOpacity={0.5} strokeDasharray="none" vertical={false} />
            <XAxis dataKey="name" tick={{ fill: "rgba(255, 255, 255, 0.5)", fontFamily: "Poppins, sans-serif" }}  interval={0} />
            {customYAxisBarChart(this.state.currencyPieChartInfo.currency_symbol,this.state.direction)}
            <Tooltip content={customTooltip} cursor={{ fill: "transparent" }} />
            {customLegend()}
            {
              this.state.currentLevel.level === 1 ?(this.performanceBarData.map((barData,index)=>{
                return  <Bar key={barData.name} dataKey={barData.name} stackId="a" fill={barData.fill }></Bar>
              })):(this.state.BarchartLevel2assetNames.map((barData,index)=>{
                return  <Bar key={barData.name} dataKey={barData.name} stackId="" fill={this.PIE_CURRENCY_COLORS[index % this.PIE_CURRENCY_COLORS.length] }></Bar>
              }))
              
            }
          </BarChart>
        </ResponsiveContainer>
      </Box>
    )
  }

  renderAllocation = () => {
    return (
      <Box style={webStyle.currencyPieChartContainer}>
        <Typography style={webStyle.chartHeading}>
           {this.getStringNewDashboard("allocation")}
        </Typography>
        <ResponsiveContainer width="100%" height={375}>
          <PieChart>
            <Tooltip content={<CustomTooltip />}/>
            <Pie
              activeIndex={this.state.activeIndex}
              data={this.state.allocationPieChart}
              innerRadius={115}
              outerRadius={140}
              fill="#fff"
              dataKey="value"
              labelLine={false}
              stroke="none"
              // label={(props) => renderLabel(props, this.state.allocationPieChartPositions,this.state.direction)}
              data-test-id="piechartAllocation"
              activeShape={renderActiveShape}
              onMouseEnter={this.onPieEnter}
              onMouseLeave={this.onPieLeave}
              isAnimationActive={false}
              onClick={(data:{
                name:string
              })=>{
                this.onPieChartClick(data.name)
              }}
            >    
              {this.state.allocationPieChart.map((entry, index) => {
                const  fill= this.state.currentLevel.level === 1 ? this.getColor(entry.name) : this.PIE_CURRENCY_COLORS[index % this.PIE_CURRENCY_COLORS.length] 
                return(
                <Cell key={`cell-${index}`} fill={fill} />
              )})}
              <Label
                value={`${this.state.currencyPieChartInfo.currency_symbol}${formatNumberWithAbbreviations(this.state.currencyPieChartInfo.total_networth+ '')}\n ${this.getFundTypeText()}  `}
                fill="#fff"
                style={webStyle.labelText}
                position="center"
                data-test-id="allocationLable"
                content={renderInnerLable}
              />
            </Pie>

          </PieChart>
        </ResponsiveContainer>
        {
          this.state.currentLevel.level === 1 && 
          <React.Fragment>
            <Typography style={webStyle.diversificationheading}>{this.getStringNewDashboard("score")}</Typography>
            <ProgressBar percentage={this.state.allocationPercentage} direction={this.state.direction} />
          </React.Fragment>
        }
       
      </Box>
    )
  }
  
  renderHeader = () => {
    return(
      <Box style={webStyle.headerContainer} >
      <Box style={{ display: 'flex', alignItems: 'center' }}>
      <Box style={{ display: 'flex', alignItems: 'center' }}>
        {
          this.state.currentLevel.level !== 1 && 
          <img data-test-id="handle-back" src={imagenav_BackIcon} style={webStyle.backBtn} onClick={this.handleBack} />
        }
        <p style={webStyle.title}>{this.state.currentLevel.name} </p>
      </Box>
      </Box>
      <Box className="addCashrightHeaderDropSetting">
        <Box style={{ display: 'flex', alignItems: 'center'}}>
          <FormControl>
            <Select options={this.state.currencyListDropDown}
              value={this.state.selectedCurrency}
              onChange={(value) => { value?.value && this.setCurrency(value) }}
              classNamePrefix="an-simple-selectUSD" className="selectUSD" data-test-id="selectMenu" />
          </FormControl>
          <Box onClick={() => this.goToAccountDetail()}  data-test-id="accountDetail">
            <img src={image_Vuesax} style={webStyle.accountLogo} />
          </Box>
        </Box>
      </Box>
    </Box>
    )
  }

  renderNetworthMatrix = () => {
    if(!this.state.financialSummary){
      return <React.Fragment></React.Fragment>
    }
    return (
      <Box style={{marginTop:"2rem"}} data-test-id="networthTab">
        <Typography style={webStyle.chartHeading}>
          Total Investment
        </Typography>
        <Typography style={webStyle.investmentAmount}>
          {this.state.currencyPieChartInfo.currency_symbol}  {formatNumberWithCommasAndDecimals(this.state.financialSummary.total_investment?.toString())}
        </Typography>
        <Box style={webStyle.investmentMatrixContainer}>
          <Grid container spacing={5}>
            <Grid item md={4} />
            <Grid item  >
              <Box style={webStyle.titleContainer} >
                <Typography style={webStyle.investmentTitle}>
                  Total gain
                </Typography>
                <Typography style={this.state.financialSummary.total_gain_percentage< 0 ?webStyle.minusInvestment : webStyle.plusInvestment}>
                {this.state.currencyPieChartInfo.currency_symbol}{formatNumberWithCommasAndDecimals(this.state.financialSummary?.total_gain?.toString())} ({this.state.financialSummary.total_gain_percentage}%)
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <Grid item md={4} />
            <Grid item  >
              <Box style={webStyle.titleContainer} >
                <Typography style={webStyle.investmentTitle}>
                  Monthly gain
                </Typography >
                <Typography style={this.state.financialSummary.monthly_gain_percentage< 0 ?webStyle.minusInvestment : webStyle.plusInvestment}>
                {this.state.currencyPieChartInfo.currency_symbol}{formatNumberWithCommasAndDecimals(this.state.financialSummary.monthly_gain?.toString())} ({this.state.financialSummary.monthly_gain_percentage}%)
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <Grid item md={4} />
            <Grid item  >
              <Box style={webStyle.titleContainer} >
                <Typography style={webStyle.investmentTitle}>
                  Yearly gain
                </Typography >
                <Typography style={this.state.financialSummary.yearly_gain_percentage< 0 ?webStyle.minusInvestment : webStyle.plusInvestment}>
                {this.state.currencyPieChartInfo.currency_symbol}{formatNumberWithCommasAndDecimals(this.state.financialSummary.yearly_gain?.toString())} ({this.state.financialSummary.yearly_gain_percentage}%)
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box style={webStyle.overviewContainer}>
          <Typography style={webStyle.overviewText}>
            Asset count: {this.state.financialSummary.total_asset_count} | M: {(this.state.financialSummary?.monthly_gain_percentage ?? 0) >= 0 
            ? `+${this.state.financialSummary?.monthly_gain_percentage}` : this.state.financialSummary?.monthly_gain_percentage}%, Y:{(this.state.financialSummary?.yearly_gain_percentage ?? 0) >= 0 
              ? `+${this.state.financialSummary?.yearly_gain_percentage}` : this.state.financialSummary?.yearly_gain_percentage}%
          </Typography>
        </Box>
      </Box>
    )
  }

  renderIndividualNetworthMatrix = () => {
    return (
      <Box style={{marginTop:"2rem"}} data-test-id="individual-matrix">
        
        <Box style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center" }}>
          <Box>
            <Typography style={webStyle.chartHeading}>
              Total Investment
            </Typography>
            <Typography style={webStyle.investmentAmount}>
              {this.state.currencyPieChartInfo.currency_symbol}  {formatNumberWithCommasAndDecimals(this.state.currencyPieChartInfo.total_networth?.toString())}
            </Typography>
          </Box>
          <Typography style={webStyle.chartHeading}>
            Total Assets <span style={{ color: "rgb(45, 226, 213)" }}>{this.state.financialSummary2?.total_asset_count || 0}</span>
          </Typography>
        </Box>
       
          <Grid container spacing={2} style={{marginTop:"1rem",marginBottom:"3rem"}}>
            <Grid item md={6} >
            <Box style={webStyle.individualInvestmentMatrixContainer}>
                <Typography style={webStyle.investmentTitle}>
                  Total gain
                </Typography>
                <Typography style={{...(this.state.financialSummary?.total_gain ?? 0) >= 0 
      ? webStyle.plusInvestment : webStyle.minusInvestmen,marginTop:"12px"}}>
                {this.state.currencyPieChartInfo.currency_symbol}
                {formatNumberWithCommasAndDecimals(this.state.financialSummary2?.total_gain2?.toString() ?? '0')}
                ({this.state.financialSummary2?.total_gain_percentage2 ?? 0}%)
                </Typography>
              </Box>
              </Grid>
              <Grid item md={6} >
              <Box style={webStyle.individualInvestmentMatrixContainer}>
                <Typography style={webStyle.investmentTitle}>
                  Monthly gain
                </Typography>
                <Typography style={{...(this.state.financialSummary2?.monthly_gain ?? 0) >= 0 
      ? webStyle.plusInvestment : webStyle.minusInvestmen,marginTop:"12px"}}>
                {this.state.currencyPieChartInfo.currency_symbol}
                {formatNumberWithCommasAndDecimals(this.state.financialSummary2?.monthly_gain?.toString() ?? '0')}
                ({this.state.financialSummary2?.monthly_gain_percentage ?? 0}%)
                </Typography>
              </Box>
              </Grid>
          </Grid>
      
      </Box>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box style={webStyle.mainContainer} dir={this.state.direction}>
          <Box className='mainWrappers_Box' style={{ minHeight: "100%", width: "100%" }}>
            <Box className="sideBorder">
              {this.renderHeader()}
              {this.state.currentLevel.level === 1 ? this.renderNetworthMatrix(): this.renderIndividualNetworthMatrix()}
              <Box style={{  marginBottom:"2rem"  }}>
              {this.renderPerformance()}
              </Box>
              <Box style={{  marginBottom:"2rem"  }}>
              {this.renderAllocation()}
              </Box>
              {this.renderCurrencyPieChart()}
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
type IStyle = { [key: string]: React.CSSProperties }

const webStyle: IStyle= {
  labelStyle: {
    fontWeight:700,
    fontFamily:"Poppins"
  },
  labelText: {
    fontWeight:700,
    fontFamily:"Poppins",
    marginTop:"18px"
  },
  currencyPieChartContainer:{
    padding :"16px",
    background: "#3c3c50",
    borderRadius:"12px",
    marginBottom:"2rem"
  },
  pieChartHeaderButton: {
    color: "#3C3C50",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  pieChartCointainer:{
    display:"flex",
    justifyContent:"space-between",
    flexWrap: "wrap",
    gap: "10px"
  },
  title:{ color: 'white', fontSize: '26px', fontFamily: 'Poppins', fontWeight: 600 },
  headerContainer:{ display: 'flex', justifyContent: 'space-between', alignItems: "center" },
  chartHeading :{fontSize:"24px",color:"#fff",fontFamily:"Poppins",fontWeight:700},
  performanceGraphHeading :{fontSize:"22px",color:"#fff",fontFamily:"Poppins",fontWeight:700},
  accountLogo:{ width: '41px',marginLeft:"12px", height: '41px', cursor: "pointer" },
  diversificationheading:{fontSize:"20px",color:"#fff",fontFamily:"Poppins",fontWeight:500},
  mainContainer:{width:"100%",height:"100vh"},
  filterContainer:{display:"flex",gap:"40px",padding:"12px"},
  headingCotnainer:{display:"flex",justifyContent:"space-between",alignItems:"center",marginBottom:"22px",marginRight:"16px",marginLeft:"16px",flexWrap: "wrap"},
  activeStyle:{ color: '#2ce2d5', fontSize: '22px', fontFamily: 'Poppins', fontWeight: 600,cursor:"pointer" },
  inActiveStyle:{ color: '#fff', fontSize: '22px', fontFamily: 'Poppins', fontWeight: 600,cursor:"pointer"  },
  investmentAmount :{fontSize:"26px",color:"#2de2d5",fontFamily:"Poppins",fontWeight:700},
  investmentMatrixContainer:{
    padding :"42px",
    background: "#3c3c50",
    borderRadius:"12px",
    marginBottom:"16px",
    marginTop:"1.5rem"
  },
  investmentTitle:{fontSize:"22px",color:"#fff",opacity:0.7,fontWeight: 500 ,minWidth:"200px",fontFamily:"Poppins"},
  plusInvestment:{fontSize:"22px",color:"#24d4b0",fontWeight: 500 ,fontFamily:"Poppins"},
  minusInvestment:{fontSize:"22px",color:"#ee5353",fontWeight: 500 ,fontFamily:"Poppins"},
  titleContainer:{display:"flex",justifyContent:"space-between",alignItems:"center", flexWrap: "wrap"},
  overviewText:{fontSize:"22px",color:"#fff",fontWeight: 700 ,fontFamily:"Poppins",textAlign:"center"},
  overviewContainer:{
    padding :"30px",
    background: "#3c3c50",
    borderRadius:"12px",
    marginBottom:"4rem",
  },
  performanceMatrixContainer:{display:"flex",alignItems:"center",gap:"8px",marginTop:"6px"},
  totalGainText: {fontSize:"18px",color:"#fff",fontWeight: 500 ,fontFamily:"Poppins"},
  totalGainPlusText: {fontSize:"18px",color:"#2de2d5",fontWeight: 500 ,fontFamily:"Poppins"},
  backBtn:{ width: '24px', height: '13px', cursor: "pointer" ,marginRight:"14px"},
  individualInvestmentMatrixContainer:{    background: "#3c3c50",  borderRadius:"12px",textAlign:"center", padding :"100px 42px",},
  
};
// Customizable Area End
import React from "react";

// Customizable Area Start
import '../assets/shareAsset.css';
import '../assets/confirmationModal.css';
import { Select, MenuItem, FormControl, Grid, Menu } from '@material-ui/core'
import { Link } from 'react-router-dom';
import { addCommas } from "../../../components/src/commonFn";
// Customizable Area End
import { headereBackIcon, settingicon, shareDefault, vertical3Dots } from "./assets";
import AssetOverviewController, {
  Props,
} from "./AssetOverviewController";

export default class ShareAssetsOverview extends AssetOverviewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getShareSelectedCurrency(share_currency_list: any[]) {
    return share_currency_list.length > 0 && share_currency_list.find((currency: any) => currency.id === this.state.currencyList.selection) || null;
  }

  getShareCurrency() {
    return this.state.currencyList && this.state.currencyList.list && this.state.currencyList.list.length > 0 ? this.state.currencyList.list : [];
  }

  getShareData() {
    return this.state.shareAssetData || [];
  }

  renderShareDeleteConfirmationModal() {
    if (this.state.isDeleteConfimationModalOpen && this.state.currentCardId) {
      return (
        <div className="share-modal-background">
          <div className="share-modal-popup-container">
            <div className="share-modal-title">Are you sure you want to delete ?</div>
            <div className="share-modal-button-container">
              <div className="share-modal-button modal-save" data-test-id='share-popup-cancel-click'
                onClick={() => this.setState({ isDeleteConfimationModalOpen: false })}>
                Cancel
              </div>

              <div className="share-modal-button modal-discard" data-test-id='share-popup-delete-click'
                onClick={() => {
                  this.setState({ isDeleteConfimationModalOpen: false });
                  this.deleteShareAssetData(this.state.currentCardId)
                }}>
                Delete
              </div>
            </div>

          </div>
        </div>
      )
    }
  }

  fetchShareAsssetKeys(data: any) {
    const attributes = data && data.attributes;
    const obj: any = {
      name: '',
      price: '',
      newPrice: '',
      shares: '',
      profit: '',
    }
    if (attributes) {
      obj.name = attributes.name;
      obj.price = `${attributes.converted_currency_symbol}${addCommas(attributes.converted_price)}`;
      obj.newPrice = `${attributes.converted_currency_symbol}${addCommas(attributes.converted_new_price)}`;
      obj.shares = `${addCommas(attributes.number_of_shares.toString())}`;
      obj.profit = `${attributes.converted_currency_symbol}${addCommas(attributes.profit)}`;
      return obj;
    }
    return obj;
  }

  // Customizable Area End

  render() {
    const share_currency_list = this.getShareCurrency();
    const selectedShareCurrency = this.getShareSelectedCurrency(share_currency_list);
    const shareAssetData = this.getShareData();
    return (
      // Customizable Area Start
      <div className="share-asset-overview-main-container">
        <div className="share-asset-overview-container">
          <div className="share-header-wrapper">
            <div className="share-page-title">
              <h4>
                <Link to={'/Dashboard'} className="share-navigation-back-icon">
                  <img src={headereBackIcon}></img>
                </Link>
                Share</h4>
            </div>
            <div className="share-page-info">
              <div className="share-drop-menu">
                <div>
                  <FormControl className="share-an-custom-select">
                    <Select
                      displayEmpty
                      value={selectedShareCurrency ? selectedShareCurrency.id : ''}
                      data-test-id="select-change"
                      onChange={async (e: any) => {
                        if (e.target.value) {
                          await this.onAddCurrencyToAccount(e.target.value)
                          setTimeout(async () => {
                            await this.loadShareAssetData();
                          }, 500);
                        }
                      }}
                      className="share-an-select-dropdown"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {share_currency_list.length > 0 &&
                        share_currency_list.map((currency: any) => (
                          <MenuItem key={currency.id} value={currency.id} className="share-an-custom-menu-item">
                            {`${currency.symbol} ${currency.currency_type}`}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
                <div>
                  <Link to="/Account-Detail">
                    <img src={settingicon} alt="setting" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="share-asset-cards">
            <Grid container spacing={2} className="share-card-grid-container">
            {
              shareAssetData.length > 0 && shareAssetData.map((data: any, index: number) => {
                const { name, price, profit, shares, newPrice } = this.fetchShareAsssetKeys(data);
                return (
                  <Grid className="share-card-grid-item" item xs={12} sm={6} md={4} key={data.id}>
                    <div className="share-card">
                      <div className="share-card-top">
                        <div className="share-card-left-upload">
                          <img src={shareDefault} alt='share image' />
                          <div
                            className="white-btn-title">
                            {name}
                          </div>
                        </div>

                        <div className="share-upper-card-right">
                          <img
                            alt='vertival-dots' 
                            data-test-id={`share-vertical-icon-click-${index}`}
                            src={vertical3Dots}
                            onClick={(event: React.MouseEvent<HTMLElement>) => this.handleMenuModal(event, data.id)}
                            />
                          {this.state.isVerticalPopoverOpen && data.id === this.state.currentCardId && (
                            <Menu 
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            open={Boolean(this.state.anchorEl)}
                            data-test-id="cash-popup-menu"
                            keepMounted
                            id="simple-menu"
                          anchorEl={this.state.anchorEl}
                          PaperProps={{
                            style: {width:'55px',background:"#395D6B", padding:"0 5px", borderRadius:'8px'}
                          }}
                          onClose={this.handleDocumentMenuModalClose}
                          
                          transformOrigin={{
                            horizontal: 'right',
                            vertical: 'top',
                          }}

                          
                            >
                              <Link to={{
                                pathname: '/AddSharesWeb',
                                state: { shareEditData: data }
                              }}
                                className="share-edit-option"
                                onClick={() => this.setState({ isVerticalPopoverOpen: false })}
                                data-test-id={`share-edit-${index}`} >
                                Edit
                              </Link>
                              <div className="share-delete-option"
                                onClick={() => {
                                  this.setState({ isVerticalPopoverOpen: false, isDeleteConfimationModalOpen: true, currentCardId: data.id });
                                }}
                                data-test-id={`share-delete-${index}`}>
                                Delete
                              </div>
                            </Menu>
                          )}
                        </div>
                      </div>

                      <div className="share-card-bottom">
                        <div className="share-card-bottom-content-container">
                          <div className="share-sub-tile-bold-section">
                            Price
                          </div>
                          <div className="share-middlesub-tile-normal-section">{price}</div>
                        </div>

                        <div className="share-card-bottom-content-container">
                          <div className="share-sub-tile-bold-section">
                            New Price
                          </div>
                          <div className="share-middlesub-tile-normal-section">{newPrice}</div>
                        </div>

                        <div className="share-card-bottom-content-container">
                          <div className="share-sub-tile-bold-section">
                            Shares
                          </div>
                          <div className="share-middlesub-tile-normal-section">{shares}</div>
                        </div>

                        <div className="share-card-bottom-content-container">
                          <div className="share-sub-tile-bold-section">
                            Profit
                          </div>
                          <div className={`share-middlesub-tile-normal-section ${data.attributes.operand == "-" ? "negative-profit":""}`}>{data.attributes.operand == "-"?data.attributes.operand:""}{profit}</div>

                        </div>
                      </div>

                    </div>
                  </Grid>
                )
              }
              )
            }
            </Grid>
          </div>
        </div>
        {
          this.renderShareDeleteConfirmationModal()
        }
      </div >
      // Customizable Area End
    );
  }
}

import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Input,
  styled,
  Avatar,
  InputAdornment,
  IconButton,
  Chip,
  Modal
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {ChatDataType} from "./ChatController";
import SendIcon from "@material-ui/icons/Send";
import SettingsVoiceIcon from "@material-ui/icons/SettingsVoice";
import ChatEmoji from '@material-ui/icons/SentimentSatisfiedOutlined';
import AttachmentIcon from "@material-ui/icons/Attachment";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import CircularProgress from '@material-ui/core/CircularProgress';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import { playIcon, pauseIcon, download } from "./assets";
import ImageDownloader from "../../../components/src/ImageDownloader/ImageDownloader";
// Customizable Area End

import ChatController, { configJSON, IChat, Props } from "./ChatController";

export default class Chat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderImageCell = () => {
    return(
      <ModalContainer
       open={this.state.isImageModal}
       onClose={this.handleCloseValue}
    >
       <ModalBox>
       <ImageDownloader selectImgUrl={this.state.selectImgUrl} image={download} />
        <ModalImageBox>
           <ModalImage src={this.state.selectImgUrl}/>
        </ModalImageBox>
        </ModalBox>
    </ModalContainer>
    )
  };

  audioCell = (isCurrentAudio: boolean, isPlaying: boolean) => {
    if (isCurrentAudio) {
      if (isPlaying) {
        return (
          <img src={pauseIcon} height="25px" width="30px"/>
        )
      } else {
        return (
          <img src={playIcon} height="25px" width="30px"/>
        )
      }
    } else {
      return (
        <img src={playIcon} height="25px" width="30px"/>
      )
    }
  }

  renderAudioPlayer = (chat: ChatDataType, keyIdx: number, isRight: boolean) => {
    const {isPlaying, audioURL, currentTime, duration, currentAudioIndex } = this.state;
    const isCurrentAudio = currentAudioIndex === keyIdx;
    const progress = isCurrentAudio ? (currentTime / duration) * 100 : 0;
    return (
      <Box className={isRight ? "audioContainer" : "audioLeftContainer"}>
        <div style={{ display: "flex", height: "40px", backgroundColor: "#3775d8", alignItems: "center", width: "42%", borderRadius: "10px" }}>
          <div>
            <audio data-testId={`onTimeUpdate,${keyIdx}`} onTimeUpdate={this.handleTimeUpdateValue} ref={this.audioRef} src={audioURL || ""} onEnded={this.onEndedAudioValue}></audio>
            <button data-testId={`onPlayAudioId,${keyIdx}`} onClick={() => this.onPlayAudioValue(chat.imgUrl, keyIdx, isCurrentAudio)} style={{ background: "transparent", border: "0" }}>
              {this.audioCell(isCurrentAudio,isPlaying)}
  
            </button>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', width: '80%' }}>
            <div
              style={{
                width: '100%',
                height: '7px',
                backgroundColor: '#e0e0e0',
                borderRadius: '5px',
                overflow: 'hidden',
                position: 'relative',
                marginLeft: "8px"
              }}
            >
              <div
                style={{
                  width: `${progress}%`,
                  height: '100%',
                  backgroundColor: '#3b5998',
                  borderRadius: '5px',
                  transition: 'width 0.1s linear',
                }}
              ></div>
            </div>
          </div>
          <span className="timeZone" style={{marginRight: "7px", marginLeft:"auto"}}>
            {chat.time}
          </span>
        </div> 
      </Box>
    )
  };


  rightSideRenderFirst = (chat: ChatDataType, keyIdx: number) => {
    const isOnlyMessageCell = chat.message ? chat?.message?.trim() !== '' : false;
    const isOnlyImage = chat.imgUrl !== '';
    const isAudioMessage = chat.type === 'audio';
    if (isAudioMessage) {
      return (
        <>{this.renderAudioPlayer(chat, keyIdx, true)}</>
      )
    }
    if (isOnlyImage) {
      if (isOnlyMessageCell) {
        return (
          <Box className="outgoingChats">
            <Box data-testId={`openModalCell,${keyIdx}`} className="parentdiv" onClick={()=>this.handleModalOpenBaseOnType(chat.type, chat.imgUrl)} style={{ backgroundColor: "#94949c", borderRadius: "10px", flexDirection: "column" }}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", padding: "10px 7px" }}>
              {
                    chat.type === "file" && chat.imgUrl ?  <a style={{
                      color:'white'
                    }} href={chat.imgUrl} target="_blank" rel="noopener noreferrer">
                    <Box display="flex" flexDirection="column" alignItems="center" style={{padding:'4px'}}>
                      <p>📁 Download File</p>
                    </Box>
                  </a> : <>
                    <span style={{ display: "flex", alignItems: "center", width: "200px" }}><InsertPhotoIcon style={{ fontSize: "30px" }} /><small style={{ paddingLeft: "7px", fontSize: "16px", color: "black" }}>Photo</small></span>
                <img
                  src={chat.imgUrl}
                  height="70px"
                  width="70px"
                  style={{ borderRadius: "10px" }}
                  alt="Received"
                />
                    
                    </> 
                    }
               
              </div>
                <Box className="isMessageTime">
                  <Typography className="message">{chat.message}</Typography>
                  <Box className="timeZone">
                    <Typography className="timeHeading">{chat.time}</Typography>
                  </Box>
                </Box>
            </Box>
          </Box>
        )
      }
      return (
        <Box className="outgoingChats">
                <Box data-testId="openModalCellCheck" onClick={()=>this.handleModalOpenBaseOnType(chat.type, chat.imgUrl)} className="outgoingChatsMsg">
                  {
                    chat.type === "file" && chat.imgUrl ?  <a style={{
                      color:'white'
                    }} href={chat.imgUrl} target="_blank" rel="noopener noreferrer">
                    <Box display="flex" flexDirection="column" alignItems="center" style={{padding:'4px'}}>
                      <p>📁 Download File</p>
                    </Box>
                  </a> :  <img
                    src={chat.imgUrl}
                    width="200px"
                    alt="Received"
                    height="200px"
                  />
                  }
                  
                </Box>
        </Box>
      );
    }
    return (
      <>
        <Box className="outgoingChats">
          <div className="parentdiv" style={{ color: "white" }}>
            <div className="subparent">
              <div className="message">
                {chat.message}
              </div>
              <span className="timeZone">
                {chat.time}
              </span>
            </div>
          </div>
        </Box>
      </>
    );
  };

  leftSideRenderFirstMessage = (chat: ChatDataType, keyIdx: number) => {
    const isOnlyMessageValue = chat.message ? chat?.message?.trim() !== '' : false;
    const isOnlyImage = chat.imgUrl !== '';
    const isAudioMessage = chat.type === 'audio';
    if (isAudioMessage) {
      return (
        <>{this.renderAudioPlayer(chat, keyIdx, false)}</>
      )
    }
    if (isOnlyImage) {
      if (isOnlyMessageValue) {
        return (
          <Box className="outgoingChats">
            <Box data-testId={`openModalCellId,${keyIdx}`} onClick={()=>this.handleModalOpenBaseOnType(chat.type, chat.imgUrl)} className="parentLeftdiv" style={{ flexDirection: "column", background: "white" }}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", padding: "10px 7px", background: "grey", borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                
                {
                    chat.type === "file" && chat.imgUrl ?  <a style={{
                      color:'black'
                    }} href={chat.imgUrl} target="_blank" rel="noopener noreferrer">
                    <Box display="flex" flexDirection="column" alignItems="center" style={{padding:'4px'}}>
                      <p>📁 Download File</p>
                    </Box>
                  </a> : <>
                  <span style={{ display: "flex", alignItems: "center", width: "200px" }}><InsertPhotoIcon style={{ fontSize: "30px" }} /><small style={{ paddingLeft: "7px", fontSize: "16px" }}>Photo</small></span>
                <img
                  src={chat.imgUrl}
                  alt="Received"
                  style={{ borderRadius: "10px" }}
                  height="70px"
                  width="70px"
                />
                    </> 
                    }
              </div>
             
                <div className="parentLeftdiv" style={{ padding: "7px 10px" }}>
                  <div style={{ display: "flex", width: "100%" }}>
                    <div className="message">
                      {chat.message}
                    </div>
                    <span className="timeZone">
                      {chat.time}
                    </span>
                  </div>
                </div>
              
            </Box>
          </Box>
        );
      }
      return (
        <Box className="outgoingChats">
          <Box onClick={()=>this.handleModalOpenBaseOnType(chat.type, chat.imgUrl)}  className="parentLeftdiv" style={{ padding: "7px 10px" }}>
          {
                    chat.type === "file" && chat.imgUrl ?  <a style={{
                      color:'black'
                    }} href={chat.imgUrl} target="_blank" rel="noopener noreferrer">
                    <Box display="flex" flexDirection="column" alignItems="center" style={{padding:'4px'}}>
                      <p>📁 Download File</p>
                    </Box>
                  </a> :  <img
              src={chat.imgUrl}
              alt="Received"
              height="200px"
              width="200px"
              style={{ borderRadius: "10px" }}
            />
                  }
            
          </Box>
        </Box>
      );
    }
    return (
      <>
        <Box className="outgoingChats">
          <div className="parentLeftdiv">
            <div className="subparent">
              <div className="message">
                {chat.message}
              </div>
              <span className="timeZone">
                {chat.time}
              </span>
            </div>
          </div>
        </Box>
      </>
    );
  };
  renderImageModal = () => {
    const { selectedFile } = this.state;

    if (!selectedFile) return null;

    const getDocumentIcon = (fileType: string) => {
      if (fileType === 'application/pdf') {
        return '📄';
      } else if (fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        return '📝'; 
      } else if (fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        return '📊'; 
      }
      return '📁'; 
    };

    return (
      <Chip
        className="imageData"
        avatar={
          selectedFile.fileType.startsWith('image/') ? (
            <Avatar alt="Preview" src={selectedFile.previewUrl} />
          ) : (
            <Avatar alt="Document">{getDocumentIcon(selectedFile.fileType)}</Avatar>
          )
        }
        label={selectedFile.fileName}
      />
    );
  };
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <ChatBotStyle>
          <Box className="headPosition">
            <Box className="headerStyle">
              <Box className="header">
                <ArrowBackIcon className="arrowBackIcon" data-testId="backTestId" onClick={this.goToEngagementRequestScreen} />
                <Avatar
                  className="avaratImage"
                  alt="Remy Sharp"
                  src={this.state.userImage}
                />
                <Typography className="userName"> {this.state.userName}</Typography>
              </Box>
            </Box>
          </Box>
          <Box className="todayBox">
            {this.state.isRefreshing && <CircularProgress size={20} style={{color:"aqua"}} />}
            <Typography className="todayHeading">{this.state.dateLabel}</Typography>
          </Box>
          <div
            id="chatContainer"
            ref={this.chatContainerRef}
            className="chatSectionStyle"
          >
            {this.state.chatData.length > 0 && this.state.chatData.map((chat: ChatDataType, keyIdx: number) => {
              let userCheck = this.getLoginId(chat.userId as string)
              return (
                <Box
                  key={keyIdx}
                  className="receiveChat"
                  data-testId="userCheckTestId"
                >
                   {!this.state.isRefreshing && this.state.chatData[keyIdx - 1] && (keyIdx === 0 || (chat.groupDateLabel !== this.state.chatData[keyIdx - 1].groupDateLabel)) && (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                      <div style={{ width: 100, height: 1, backgroundColor: '#c0c0c4' }}/>
                      <span style={{ fontSize: 12, color: '#c0c0c4', paddingLeft: 10, paddingRight: 10 }}>
                        {chat.groupDateLabel}
                      </span>
                      <div style={{ width: 100, height: 1, backgroundColor: '#c0c0c4' }}/>
                    </div>
                  )}
                  {!userCheck
                    ? this.leftSideRenderFirstMessage(chat, keyIdx)
                    : this.rightSideRenderFirst(chat, keyIdx)}
                </Box>
              )
            })}
          </div>
          {this.state.imgModal ? this.renderImageModal() : null}
          <Box>
            <Box className="emojiPicker">
              {this.state.isEmoji && (
                <div ref={this.pickerRef}>
                <Picker
                  previewPosition="none"
                  data={data}
                  data-testId="emojiPickerId"
                  onEmojiSelect={this.handleEmojiSelect}
                />
                </div>
              )}
            </Box>
            <Box className="formStyle">
              <Box className="textButton">
                <Input
                  disabled={this.state.isLoader}
                  placeholder="Enter your text"
                  fullWidth
                  disableUnderline
                  autoComplete="off"
                  data-testId="inputMessageId"
                  name="userMessage"
                  className="inputDateField"
                  value={this.state.senderChatText}
                  onChange={this.handleSenderChat}
                  onKeyPress={this.handleKeyPressCell}
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton onClick={this.handleEmojiPicker}>
                        <ChatEmoji className="addIcon" />
                      </IconButton>
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton>
                        <Box className="inputBox">
                          <label htmlFor="fileInput" className="uploads">
                            <AttachmentIcon className="addIcon" />
                          </label>
                          <input
                          id="fileInput"
                          type="file"
                          accept="image/jpeg, image/png, image/gif, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          onChange={this.handleFileChange}
                          className="inputImage"
/>
                          <div
                            onMouseDown={this.debounceValue(this.startRecordingValue, 300)}
                            onMouseUp={this.debounceValue(this.stopRecordingValue, 300)}
                            onTouchStart={this.debounceValue(this.startRecordingValue, 300)}
                            onTouchEnd={this.debounceValue(this.stopRecordingValue, 300)}
                          >
                            <SettingsVoiceIcon className="addIcon" />
                          </div>
                        </Box>
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Box>
              <Box className="sendIconStyle">
                <IconButton disabled={this.state.isLoader} data-testId="sendMessageTestId" onClick={this.debounceValue(this.sendMessage, 300)} type="submit">
                  <SendIcon className="addIcon" />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </ChatBotStyle>
        {this.renderImageCell()}
        {this.state.isLoader && <LoaderContainer style={{color:"#fff"}}>
        <CircularProgress  size={20} color="inherit"  />
        </LoaderContainer>}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const LoaderContainer = styled(Box)({
  display:"flex",justifyContent:'center',alignItems:'center',width: "calc(100% - 350px)", height:"100vh", position: "absolute",right:0,
  "@media(max-width: 940px)": {
    width: "calc(100% - 222px)",
  },
  "@media(max-width: 840px)": {
    width: "calc(100% - 222px)",
  },
  "@media(max-width: 700px)": {
    width: "calc(100% - 230px)",
  },
  "@media(max-width: 630px)": {
    width: "calc(100% - 200px)",
  },
  "@media(max-width: 550px)": {
    width: "calc(100% - 100px)",
  },
  "@media(max-width: 450px)": {
    width: "calc(100% - 70px)",
  },
  "@media(max-width: 400px)": {
    width: "calc(100% - 20px)",
  },

})
const ChatBotStyle = styled(Box)({
  "& .parentdiv": {
    maxWidth: "600px",
    background: "#3775d8",
    height: "max-content",
    borderRadius: "10px",
    display: "flex",
    float: "right",
    color: "white"
  },

  "& .subparent": {
    display: "flex",
    alignItems:'flex-end',
    padding: "10px 10px",
    width: "100%"
  },
  "& .message": {
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    overflowWrap: "break-word",
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "100%"
  },
  "& .timeZone": {
    fontSize: "10px",
    width: "50px",
    display: "inline-block",
    paddingLeft: "10px",
    color: "#c0c0c4",
    paddingRight: "10px",
    textAlign:'right'

  },
  "& .parentLeftdiv ": {
    display: "flex",
    float: "left",
    marginRight:"auto",
    maxWidth: "500px",
    background: "#fff",
    height: "max-content",
    borderRadius: "10px",
  },

  "& .noneMessage": {
    display: "none"
  },
  "& .audioContainer": {
    display: "flex",
    marginRight: "16px",
    justifyContent: "flex-end",
    marginBottom: "16px",
  },
  "& .audioLeftContainer": {
    marginLeft: "16px",
    display: "flex",
    marginBottom: "16px",
  },
  "& .isMessageTime": {
    width: "100%",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    justifyContent: "space-between",
    maxWidth: "500px",
    display: "flex",
    flexDirection: "row",
    padding: "7px",
    background: "#3775d8",
  },
  width: "100%",
  backgroundColor: "#2a2a38",
  marginLeft: "auto",
  position: "relative",
  height: "100vh",
  "& .arrowBackIcon": {
    color: "white",
    fontSize: "30px",
    marginRight: "12px",
  },
  "& .formStyle": {
    padding: "2% 2% 0% 2%",
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    bottom: "0px",
    gap: "3%",
    boxSizing: "border-box",
  },
  "& .textButton": {
    width: "100%",
  },
  "& .inputBox": {
    display: "flex",
    gap: "10px",
  },
  "& .inputDateField": {
    background: "#373a3d",
    color: "#7C7C7C",
    height: "46px",
    padding: "0px 10px",
    borderRadius: "20px",
    width:"100%",
    "& .MuiInputBase-input": {
      border: "none",
      flexGrow: 1
    },
  },
  "& .addIcon": {
    color: "white",
  },
  "& .inputImage": {
    display: "none"
  },
  "& .sendIconStyle": {
    height: "50px",
    width: "50px",
    borderRadius: "50%",
    backgroundColor: "#2e7eec",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .headPosition": {
    backgroundColor: "#2a2a38",
    padding: "10px",
    top: 0,
    left: 0,
    zIndex: 2,
    width: "100%",
    boxSizing: "border-box",
  },
  "& .userName": {
    color: "white",
    fontFamily: "poppins"
  },
  "& .avaratImage": {
    marginRight: "12px",
  },
  "& .todayBox": {
    left: "50%",
    transform: "translateX(-50%)",
    boxSizing: "border-box",
    backgroundColor: "grey",
    padding: "10px 20px",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "80px",
    opacity: "0.8"
  },
  "& .todayHeading": {
    color:"white",
    fontWeight:600,
    textAlign:"center",
    paddingLeft:"5px",
    fontFamily:"poppins"
  },
  "& .headerStyle": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  "& .header": {
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
  },
  "& .outgoingChats": {
    margin: "16px",
    overflow: "hidden",
  },
  "& .outgoingChatsMsgs ": {
    backgroundColor: "#3a12ff",
    height: "min-content",
    backgroundImage: "#1577df",
    color: "#fff",
    borderRadius: "10px 10px 0px 10px",
    fontSize: "14px",
    padding: "1rem",
    boxShadow: "0px 2px 5px 2px rgba(0, 0, 0, 0.25)",
    display: "flex",
    justifyContent: "space-between",
  },
  "& .rightSide": {
    borderRadius: "10px 0px 10px 10px",
  },
  "& .outgoingChatsMsg": {
    float: "right",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column"
  },
  "& .receiveChat": {
    width: "100%",
  },
  "& .outgoingChatsImg": {
    display: "inline-block",
    width: "50px",
    float: "right",
    marginRight: "1rem",
  },
  "& .time": {
    marginLeft: "15px",
    width: "100px",
  },
  "& .imageData": {
    position: "fixed",
    bottom: "80px",
    marginLeft: "50px",
    height: "50px"
  },
  "& .timeHeading": {
    float: "right",
    fontSize: "10px",
  },
  "& .receivedMyCharts": {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "white",
    borderRadius: "10px 10px 10px 0px",
    width: "40%",
    padding: "1rem",
    flexWrap: "wrap"
  },
  "& .leftMessageStyle": {
    width: "330px",
    display: "flex",
    flexFlow: "wrap"
  },
  "& .receivedSecond": {
    borderRadius: "0px 10px 10px 10px",
  },
  "& .emojiPicker": {
    position: "fixed",
    bottom: "70px",
    marginLeft: "5%"
  },
  "& .chatSectionStyle": {
    backgroundColor: "#2a2a38",
    height: "calc(100vh - 140px)",
    width: "100%",
    overflowY: "scroll",
  },

  "@media screen and (max-width: 940px)": {
    width: "100%",
    "& .parentdiv": {
      maxWidth: "375px"
    },
    "& .parentLeftdiv ": {
      maxWidth: "400px"
    }
  },

  "@media screen and (max-width: 840px)": {
    width: "100%",
    "& .parentdiv": {
      maxWidth: "320px"
    },
    "& .parentLeftdiv ": {
      maxWidth: "350px"
    }
  },

  "@media screen and (max-width: 700px)": {
    width: "100%",
    "& .parentdiv": {
      maxWidth: "300px"
    },
    "& .parentLeftdiv ": {
      maxWidth: "300px"
    }
  },

  "@media screen and (max-width: 630px)": {
    width: "100%",

    "& .parentdiv": {
      maxWidth: "270px"
    },
    "& .parentLeftdiv ": {
      maxWidth: "270px",
    }
  },
  "@media(max-width: 550px)": {
    width: "100%",
  },
  "@media(max-width: 450px)": {
    width: "100%",
  },
  "@media(max-width: 400px)": {
    width: "100%",
  }
});

const ModalContainer = styled(Modal)({
  justifyContent:"center" , 
  alignItems:'center',
  display:"flex"
});

const ModalBox = styled("div")({
  position:'relative',
  width: "450px", 
  display: "flex", 
  alignItems: "center", 
  justifyContent: "center",
  height: "450px", 
  background: "white", 
  borderRadius: "20px", 
  "@media(max-width: 620px)" : { 
    height: "350px", 
    width: "350px",
  },
  "@media(max-width: 550px)" : {
    width: "300px", 
    height: "300px", 
  },
});

const ModalImageBox = styled("div")({
  height: "400px",
  width: "400px", 
  "@media(max-width: 620px)" : {
    width: "300px", 
    height: "300px", 
  },
  "@media(max-width: 550px)" : {
    height: "250px", 
    width: "250px", 
  },
});

const ModalImage = styled("img")({
  width: "100%", height: "100%"
});
// Customizable Area End

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import * as Yup from 'yup';
import { ValueType } from "react-select";

const CurrencyList = {
  list:
    [
      {
        "id": 3,
        "currency_name": "United States dollar",
        "created_at": "2023-05-19T05:51:14.716Z",
        "updated_at": "2023-06-07T15:27:50.567Z",
        "value_respect_to_usd": 1.0,
        "currency_type": "USD",
        "symbol": "$",
        "usd_to_currency": 1.0
      },
      {
        "id": 4,
        "currency_name": "Arab Emirates Dirham",
        "created_at": "2023-05-19T05:51:24.584Z",
        "updated_at": "2023-06-07T15:28:53.340Z",
        "value_respect_to_usd": 0.2723,
        "currency_type": "AED",
        "symbol": "د.إ",
        "usd_to_currency": 3.6729
      },
      {
        "id": 5,
        "currency_name": "British pound sterling",
        "created_at": "2023-06-07T15:30:00.820Z",
        "updated_at": "2023-06-07T15:30:00.820Z",
        "value_respect_to_usd": 1.239,
        "currency_type": "GBP",
        "symbol": "£",
        "usd_to_currency": 0.807
      },
      {
        "id": 6,
        "currency_name": "European Euro",
        "created_at": "2023-06-07T15:31:27.070Z",
        "updated_at": "2023-06-07T15:31:27.070Z",
        "value_respect_to_usd": 1.0675,
        "currency_type": "EUR",
        "symbol": "€",
        "usd_to_currency": 0.9368
      },
      {
        "id": 7,
        "currency_name": "Japanese yen",
        "created_at": "2023-06-07T15:33:38.595Z",
        "updated_at": "2023-06-07T15:33:38.595Z",
        "value_respect_to_usd": 0.0071,
        "currency_type": "JPY",
        "symbol": "¥",
        "usd_to_currency": 139.59
      },
      {
        "id": 8,
        "currency_name": "Australian Dollar",
        "created_at": "2023-06-07T15:34:33.531Z",
        "updated_at": "2023-06-07T15:34:33.531Z",
        "value_respect_to_usd": 0.6481,
        "currency_type": "AUD",
        "symbol": "A$",
        "usd_to_currency": 1.543
      },
      {
        "id": 9,
        "currency_name": "Chinese Yuan",
        "created_at": "2023-06-07T15:35:22.767Z",
        "updated_at": "2023-06-07T15:35:22.767Z",
        "value_respect_to_usd": 0.1413,
        "currency_type": "CNH",
        "symbol": "¥",
        "usd_to_currency": 7.1131
      }
    ]
}

interface ICurrencyObject {
  id: number,
  currency_name: string,
  created_at: string,
  updated_at: string,
  value_respect_to_usd: number,
  currency_type: string,
  symbol: string,
  usd_to_currency: number
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  currencyList: ICurrencyObject[],
  selectedCurrency: {
    label: string,
    value: string
  },
  direction: string,
  selectedCard: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CradditionalformsformschangerequestsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  postUserSelectDataCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      currencyList: CurrencyList.list,
      selectedCurrency: { label: `${CurrencyList.list[0].symbol} ${CurrencyList.list[0].currency_type}`, value: CurrencyList.list[0].currency_type },
      direction: "ltr",
      selectedCard: this.CardType.Auto
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount() {
    // this.startDemo();
  }

  handleChangeCurrency = (value: ValueType<{
    label: string;
    value: string;
  }, false>) => {
    if (value) {
      const selectedCurrency = this.state.currencyList.find(currency => {
        return currency.currency_type === value.value
      })
      this.setState({
        selectedCurrency: {
          label: `${selectedCurrency?.symbol} ${value.label}`,
          value: value.value
        }
      });
    }
  }

  handleCardChange = (card: number) => {
    this.setState({ selectedCard: card })
  }

  handleSelectCardNext = () => {
    if (this.state.selectedCard === this.CardType.Auto) {
      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), "AddBankAccounts");
      msg.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      this.send(msg)
    } else {
      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), "ManualForm");
      msg.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      this.send(msg)
    }
  }

  navigateBack = (route: string) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), route);
      msg.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      this.send(msg)
  }



  CardType = {
    Manual: 1,
    Auto: 2
  }

  // Customizable Area End
}

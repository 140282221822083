import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall } from "../../../components/src/Common";
import { Message } from "../../../framework/src/Message";

export const configJSON = require("./config");


export interface Props {
    navigation: any;
    id: string;
    location: any;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    logoImg1AddCash: string,
    show: string,
    isUp: boolean,
    showName: boolean,
    userName: string,
    isClicked: string,
    isPopupOpen: boolean,
    isPopupOpenNew: boolean,
    FinancialPlanner: any,
    FindData: any,
    numberFind: string,
    openCashModel: boolean,
    messengData: string,
    rating: number,
    rating_and_reviewsData: any,
    rating_and_reviews_Model:  {
        "id": string,
        "type": string,
        attributes: {
            date: string,
            description: string,
            rating: number,
            user_name: string,
            user_photo: string,
          }
      }[],
      avgRating: number,
      selectedId:string,
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class FinancialPlannerController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    postUserPDFDataCallId: string = "";
    postUserfinancialPlanner: string = ""
    postUserfinancialPlannerPost: string = ""
    getAllReviewsCallId:string = ""
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            rating_and_reviewsData: [],
            isPopupOpenNew: false,
            rating: 0,
            FindData: [],
            isPopupOpen: false,
            logoImg1AddCash: "",
            show: "",
            isUp: true,
            showName: false,
            userName: '',
            isClicked: '',
            FinancialPlanner: [],
            numberFind: "",
            openCashModel: false,
            messengData: "",
            rating_and_reviews_Model: [],
            avgRating:0,
            selectedId:""
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    // Customizable Area Start

    async componentDidMount() {
        this.functionTokenCheck()
        this.getFinacialList()
    }

    getFinacialList = () => {
        const cashData = localStorage.getItem("token")
        const header = { "token": cashData };
        let userDetailurl = configJSON.FinancialPlannerGet;
        const postPdfData = apiCall({
            header: header,
            httpBody: {},
            url: userDetailurl,
            httpMethod: configJSON.FinancialPlannerApiMethodType,
        });
        this.postUserfinancialPlanner = postPdfData.messageId;
        runEngine.sendMessage(postPdfData.id, postPdfData);
    }

    getFinacialListPost = () => {
        const cashData = localStorage.getItem("token")
        const header = { "token": cashData };
        let userDetailurl = `${configJSON.FinancialPlannerPost}?wealth_planner_id=${this.state.numberFind}`;
        const postPdfData = apiCall({
            header: header,
            httpBody: {},
            url: userDetailurl,
            httpMethod: configJSON.FinancialPlannerApiMethodTypePost,
        });
        this.postUserfinancialPlannerPost = postPdfData.messageId;
        runEngine.sendMessage(postPdfData.id, postPdfData);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.postUserfinancialPlanner !== null &&
            this.postUserfinancialPlanner ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let financialPlanner = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.setState({ FinancialPlanner: financialPlanner.wealth_planners })
        }

        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.postUserfinancialPlannerPost !== null &&
            this.postUserfinancialPlannerPost ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let financialPlannerPost = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (financialPlannerPost.message.includes("sucessfully")) {
                this.setState({ openCashModel: true });
            } else if (financialPlannerPost.message.includes("already")) {
                this.setState({ messengData: financialPlannerPost.message, openCashModel: true });
            }
        }
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
      
            const responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            
            this.handleAllReviewList(responseJson,apiRequestCallId)
        }

        // Customizable Area End
    }



    showMore = (number: string, e: React.MouseEvent<any>) => {
        this.setState({ show: number })
        e.stopPropagation()
    }

    handleClick = (event: React.MouseEvent<any>) => {
        event.stopPropagation();
        this.setState((prevState) => ({
            isUp: !prevState.isUp,
        }));
    }

    maskEmail(email: string) {
        const [username, domain] = email.split('@');
        const maskedUsername = '*'.repeat(username.length);
        return `${maskedUsername}@${domain}`;
    }
    handleIconClick = (event: React.MouseEvent<SVGElement>,selectedId:string) => {
        event.stopPropagation();
        if (this.state.selectedId === selectedId) {
            this.setState({ showName: false, selectedId: "" })
          } else {
            this.setState({ showName: true, selectedId })
          }
    };

    handleClickNew = (index: any) => {
        this.setState({ isClicked: index });
    };
    nextButton = (number: string) => {
        const newData = this.state.FinancialPlanner.data.find((planner: any) => planner.id === number)
        this.setState({ numberFind: number, show: number, FindData: [newData], isUp: newData.attributes.total_reviews ? false : true })
    }
    backButton = () => {
        this.setState({ numberFind: "" })
    }
    handleOpenCashModel = () => {
        this.getFinacialListPost()
    };
    handleCloseCashModel = () => {
        this.setState({ openCashModel: false });
        document.dispatchEvent(new CustomEvent("estatePlanningUpdated"));
        this.props.navigation.navigate('EngagementRequest', { id: this.state.numberFind })
    };

    dialogCashPaperStyleNew = {
        width: '100%',
        backgroundColor: '#395D6B',
        borderRadius: '8px',
        boxShadow: '0px 0px 10px 0px rgba(44, 226, 213, 0.16)',
    };

    dialogCashModelNew = (event: React.MouseEvent<any>, numberId: string,avgRating: number) => {
        this.setState({
            avgRating
        })
        event.stopPropagation();
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
          this.getAllReviewsCallId = requestMessage.messageId;
          const header = {
            token: localStorage.getItem('token')
          };
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_formapprovalworkflow/rating_and_reviews/all_reviews?wealth_planner_id=${numberId}`
          );
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
          );
      
          runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    handleDialogClose = () =>{
        this.setState({isPopupOpenNew:false, });
    }


    handleAllReviewList(responseJson: {
        reviews: {
            data : {
                "id": string,
                "type": string,
                attributes: {
                    date: string,
                    description: string,
                    rating: number,
                    user_name: string,
                    user_photo: string,
                  }
              }[]
        }
    },apiCallId:string) {
        if(apiCallId ===  this.getAllReviewsCallId  ){
            this.setState({isPopupOpenNew:true, rating_and_reviews_Model: responseJson.reviews.data });
        }
    }
    NavigateToLogout=()=>{
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('userEmail');
        window.localStorage.removeItem('authToken');
        window.localStorage.removeItem('rememberMe');
        window.localStorage.removeItem('email');
        window.location.href = '/EmailAccountLogin';
    }
      functionTokenCheck=async ()=>{
        const authToken = localStorage.getItem("token")
      if (!authToken ) {
        this.NavigateToLogout() 
    }
    }
     


    // Customizable Area End
}

import React from "react";
// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import 'firebase/auth';
import { apiCall } from "../../../components/src/Common";
import { authToken, createMeeting } from "./API";
import { useEndCall } from "../../../components/src/EndCall.web";
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  meetingId:string;
  token:string;
  userName:string;
  meetingStartTime: Date | null;
  seconduser:string;
  wealthPlannerImage:string|null;
  isCustomer:boolean|null;
  currentPlannerId:string|null;
  BookingTime:any;
  bookingDuration:string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class VideoCallController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  private timer: NodeJS.Timeout | null = null;
  intervalIdVideo: ReturnType<typeof setInterval> | number = 0;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
 
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
  ];

    this.state = {
     meetingId:"",
     token:"",
     userName:"",
     meetingStartTime: null,
     seconduser:"",
     wealthPlannerImage:null,
     isCustomer:null,
     currentPlannerId:null,
     BookingTime:'',
     bookingDuration:'',

    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  async receive(from: string, message: Message) {
   
    this.handleNavigationMessage(message)

  }

  handleNavigationMessage(message: Message) {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const data1 = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      if (data1) {
          this.setState({
           meetingId: data1.meetingId,
           
           token: data1.authToken, 
            userName: data1.userName,
            seconduser: data1.wealthPlanneName,
            wealthPlannerImage:data1.wealthPlannerImage?data1.wealthPlannerImage:null,
           isCustomer:data1.isCustomer?data1.isCustomer:null,
           currentPlannerId:data1.currentPlannerId?data1.currentPlannerId:null,
           BookingTime:data1.BookingTime?data1.BookingTime:'',
           bookingDuration:data1.bookingDuration?data1.bookingDuration:''
          }, () =>  {})
      }
    }
}

  async componentDidMount() {
    // Customizable Area Start
    this.getPlannerId();


    // Customizable Area End
  }

  async componentWillUnmount() {
    clearInterval(this.intervalIdVideo);
  }

  getPlannerId = () => {
    const meetingID = this.props.navigation.history?.location?.state?.meetingId;
    this.setState({ meetingId: meetingID });
    const tokenn = this.props.navigation.history?.location?.state?.authToken;
    const useName = this.props.navigation.history?.location?.state?.userName;
    const seconduser = this.props.navigation.history?.location?.state?.wealthPlanneName;
    this.setState({ token: tokenn });
    this.setState({ userName: useName,seconduser: seconduser});
  }
  componentDidUpdate(prevProps:any, prevState:any) {
    if (prevState.meetingId !== this.state.meetingId) {
    }
  }

  getMeetingAndToken = async (id?: string) => {
    const meetingId =
      id == null ? await createMeeting({ token: authToken }) : id;
    this.setState({meetingId:meetingId})
  }

  onMeetingLeave = () => {
    this.setState({meetingId:""});
    if (this.state.isCustomer){
      this.redirectToReviewScreen();
      useEndCall();

    }
    else{
      this.redirectTCallEndPageForWealthPlanner()
      useEndCall();

    }
  }

  GOBack=()=>{
    this.setState({meetingId:""});
    
    this.props.navigation.history.goBack();
  }
  
  redirectToReviewScreen=()=>{
        const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "RatingButtonComponent");
    message.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
    );
    const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {
        currentPlannerName:this.state.seconduser,
        currentPlannerImage: this.state.wealthPlannerImage,
        currentPlannerId:this.state.currentPlannerId,
    })
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message)  
   
}
  redirectTCallEndPageForWealthPlanner=()=>{
        const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "CallEndForWealthPlanner");
    message.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
    );
    const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {
        currentPlannerName:this.state.seconduser,
        currentPlannerImage: this.state.wealthPlannerImage,
        currentPlannerId:this.state.currentPlannerId,
    })
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message)  
   
}

  // Customizable Area End
}
// Customizable Area Start
import React from "react";
import {
  MeetingProvider,
 
} from "@videosdk.live/react-sdk";
import { authToken, } from "./API";
import JoinScreen from '../../../components/src/JoinScreen.web'
import MeetingView from "../../../components/src/MeetingView.web";
import VideoCallControllers, {
  Props,
} from "./VideoCallController.web";
// Customizable Area End

export default class VideoCall extends VideoCallControllers {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
// Customizable Area Start
  render() {
    
    return (
      <div style={{ overflowX: 'hidden', overflowY: 'hidden' ,width:'100%',height:'100%'}}>
        {this.state.meetingId&&this.state.token&&
        <MeetingProvider
          config={{
            meetingId:this.state.meetingId,
            micEnabled: true,
            webcamEnabled: true,
            name:this.state.userName,
            debugMode: false,
          }}
          token={this.state.token}
          joinWithoutUserInteraction={true}
        >
<MeetingView 
  onMeetingLeave={this.onMeetingLeave} 
  onbackButtonPress={this.onMeetingLeave} 
  meetingHeader={this.state.seconduser} 
  BookingTime={this.state.BookingTime} 
  bookingDuration={this.state.bookingDuration}
/>  
        </MeetingProvider>
        }
      </div>
    ) 
  }
// Customizable Area End
}




import React from "react";
// Customizable Area Start
import "./style.css";
import {
  bankicon,
  bankimage,
  bankimage2,
  bankimage3,
  bankimage4,
  bankimage5,
  bankimage6,
  bankimage7,
  bankimage8,
  bankimage9,
  buttonimage,
  refreshicon,
  settingicon,
  subarrowicon,
  sidearrow,
  cash,
  pension,
  shares,
  logoNew,
  commodity,
  commodity1,
  commodity2,
  bankIcon,
  liablityImg
} from "./assets";
import { LineChart, Line, Tooltip, ResponsiveContainer, YAxis} from 'recharts';
import { NavLink } from "react-router-dom";
import { Box, CircularProgress, styled } from "@material-ui/core";

// Customizable Area End
import DashboardController, { Props } from "./DashboardController";
import { addCommas,addCommas2 } from "../../../components/src/common";
import { formatDate, formatDateWithTime, formatNumberWithCommasAndDecimals, formatString } from "../../../components/src/commonFn";
export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  CustomTooltip = ({ active, payload, label }:any) => {
    if (active && payload && payload.length) {
      const dataPoint = payload[0].payload;
      return (
        <div className="custom-tooltip">
          <p className="custom-tooltip-text">{formatDate(dataPoint.updation_date)}</p>
          <p className="custom-tooltip-text">{dataPoint.symbol}{parseFloat(dataPoint.networth).toLocaleString()}</p>
        </div>
      );
    }
    return null;
  };
  renderCurrencyList:any=()=>{
    return(
    this.state.currencyList && this.state.currencyList.list.map((value:any, index:number)=>{
      return <li key={index} data-test-id={"currency-"+index} onClick={()=>this.selectCurrency(value)}>{value.symbol+value.currency_type}</li>
    }))
}
  renderBalance=()=>{
      if (this.state.StocksOverview.balance) {
        return (
          <p>
            {this.state.StocksOverview.symbol + addCommas(this.state.StocksOverview.balance)}
          </p>
        );
      }
      return null; 
    };
  
    renderCryptoBalance = () => {
      if (this.state.CryptoCurrencyOverview.balance) {
        return (
          <p>
            {this.state.CryptoCurrencyOverview.symbol +
              addCommas(this.state.CryptoCurrencyOverview.balance)}
          </p>
        );
      }
      return null;
    };
    
  renderImage=()=>{
if(this.state.activeAsset == "cashOverview"){
  return subarrowicon
}else {
  return sidearrow
}
  }

  renderPropertyImage=()=>{
    if(this.state.activeAsset == "PropertyOverview"){
      return subarrowicon
    }else {
      return sidearrow
    }
  }
  renderCashData=()=>{
    return (
      <div className="faq-content">
        {this.state.cashData
          ? this.state.cashData.map((value:any,key:any) => (
            <div className="faq-content-wrapper" key={key}>
              <img
                src={cash}
                alt="bank-account"
                width="45"
                height="45"
              />
              <div
                   className="faq-inner-content"
                   data-test-id="faqcontent"
                 >
                   <p>{formatString(value.attributes.name)} </p>
                   <span className="cash">
                     {value.attributes
                       .converted_currency_symbol +
                       formatNumberWithCommasAndDecimals(value.attributes.converted_amount.toString())}
                   </span>
                 </div>
            </div>
          ))
          : ""} </div>)}
 



  stockData = ()=>{
       return(
    <div className="faq-content">
    {this.state.stockData
      && this.state.stockData.slice(0,5).map((value: any,key:any) => {
        return (
            <div className="faq-content-wrapper" key={key}>
              <img
                src={value.icon_url? value.icon_url: logoNew}
                alt="bank-account"
                width="45"
                height="45"
              />
              <div
                className="faq-inner-content"
                data-test-id="faqcontent"
              >
                <p>{formatString(value.full_name)}</p>
                <span className="cash">
                  {value
                    .symbol +
                    addCommas2(value.price ?? "0") }
                </span><br/>
                  <span className={value.operand == '+' ? "plusStock" : "minusStock"}>
                  {value
                    .operand +
                    parseFloat(addCommas2(value.difference)!).toFixed(2) + ` (${value.operand}${ parseFloat(value.percentage).toFixed(2)}%)`}
                </span>

              </div>

            </div>
        );
      })
     }
    <div data-test-id="viewDetail1" className="view-detail-btn">
      {" "}
      <NavLink className="view-detail-btn" to={`/stockDetails`}>{this.getStringDashboard("ViewDetails")}</NavLink>
    </div>
  </div>
   )
  }

  cryptoData = () => {
    return (
      <div className="faq-content">
        {this.state.cryptoData && this.state.cryptoData.slice(0, 5).map((value: any) => (
          <div className="faq-content-wrapper" key={value.id}>
            <img
              src={value.icon_url ? value.icon_url : logoNew}
              alt="crypto-account"
              width="45"
              height="45"
            />
            <div className="faq-inner-content">
              <p>{formatString(value.full_name)} </p>
              <span className="cash">{value.symbol + addCommas(value.price)}</span><br />
              <span className={value.operand === '+' ? "plusStock" : "minusStock"}>
                {value.operand + parseFloat(value.difference).toFixed(2) + ` (${value.operand}${parseFloat(value.percentage).toFixed(2)}%)`}
              </span>
            </div>
          </div>
        ))}
        <div data-test-id="viewDetail1" className="view-detail-btn">
          <NavLink className="view-detail-btn" to={`/CryptoDetails`}>{this.getStringDashboard("ViewDetails")}</NavLink>
        </div>
      </div>
    )
  }

  getCategoryImage = (data:any) => {
    const { attributes } = data;
    if (attributes) {
      const subcatImg = attributes.sub_category && attributes.sub_category.image && attributes.sub_category.image.url;
      const imgUploadedUrl = attributes.images && attributes.images.length > 0 && attributes.images[0].url;
      return !attributes.images ? subcatImg : imgUploadedUrl;
    }
    return '';
  }

  renderCommodity = () => {
    return (
      <div
        className={this.state.expandCommodity}
      >
        <div className="faq-inner-heading fih-8">
                <NavLink to="/AddCommodity">
                    <img width="54" height="54" src={commodity} alt="commodityimg" />
                </NavLink>
                <div className="faq-heading faq-heading-9" style={{ width: '100%' }} data-test-id="commodityTab"
        onClick={() => this.getCommodityData()}>
                    <div className="faq-inner-heading-content faq-inner-heading-content2">
                        <p>{this.state.commodityOverview.name}</p>
                        <span>{this.state.commodityOverview.count} {this.getStringDashboard("Assets")}</span>
                    </div>
                    <div className="faq-heading-content">
                        <div className="inner-info">
                            <span>{this.getStringDashboard("Balance")}</span>
                            <p>
                                {this.state.commodityOverview.symbol +
                                    addCommas(this.state.commodityOverview.balance)}
                            </p>
                        </div>
                        <div className="down-arrow">
                            <img
                                src={
                                    this.state.activeAsset === "CommodityOverview"
                                        ? subarrowicon
                                        : sidearrow
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        <div className="faq-content">
          {this.state.commodityData
            ? this.state.commodityData.map((value:any) => (
              <div className="faq-content-wrapper" key={value.id}>
                <img
                  src={value.attributes.commodity_type === "other" ? commodity1 : commodity2}
                  alt="commodity"
                  style={{ width: '100%', height: 'auto', maxWidth: '45px', maxHeight: '45px' }}
                />
                <div className="faq-inner-content">
                  <p>{formatString(value.attributes.name)} </p>
                  <p>{value.attributes.quantity}</p>
                  <span className="pension">
                    {value.attributes.converted_currency_symbol +
                      formatNumberWithCommasAndDecimals(value.attributes.converted_amount.toString())}
                  </span>
                </div>
              </div>
            ))
            : ""}
          <div data-test-id="viewDetail5" className="view-detail-btn">
            <NavLink className="view-detail-btn" to={`/CommodityOverview`}>{this.getStringDashboard("ViewDetails")}</NavLink>
          </div>
        </div>
      </div>
    )
  }

  shareData = () => {
    return (
      <div className="faq-content">
        {this.state.sharesData
          ? this.state.sharesData.map((value) => (
            <div className="faq-content-wrapper" key={value.id}>
              <img
                src={shares}
                alt="shares"
                style={{ width: '100%', height: 'auto', maxWidth: '45px', maxHeight: '45px' }}
              />
              <div className="faq-inner-content" id="faq">
                <p>{formatString(value.attributes.name)} </p>
                <span className="shares">
                  {value.attributes.converted_currency_symbol +
                    formatNumberWithCommasAndDecimals((Number(value.attributes.converted_new_price) * value.attributes.number_of_shares).toString())}
                </span>
                {parseFloat(value.attributes.converted_new_price) && value.attributes.profit_percent ? (
                  <div style={{ lineHeight: 1.2, paddingBottom: "8px" }} className={value.attributes.operand === '+' ? "plusStock" : "minusStock"}>
                    {value.attributes.operand}{value.attributes.converted_currency_symbol}{parseFloat(value.attributes.profit).toFixed(2) + `(${value.attributes.profit_percent.toFixed(2)}%)`}
                  </div>
                ) : (
                  <></>
                )}
                <div style={{ color: "#308BEE", fontSize: "12px", lineHeight: 1 }}>{value.attributes.number_of_shares} Shares</div>
              </div>
            </div>
          ))
          : ""}
        <div data-test-id="viewDetail2" className="view-detail-btn">
          <NavLink className="view-detail-btn" to={`/ShareAssetsDetail`}>{this.getStringDashboard("ViewDetails")}</NavLink>
        </div>
      </div>
    )
  }

  propertyData = () => {
    return (
      <div className="faq-content">
        {this.state.propertyData
          ? this.state.propertyData.map((value) => (
            <div className="faq-content-wrapper" key={value.id}>
              <img
                src={this.getCategoryImage(value)}
                alt="property"
                width="45"
                height="45"
              />
              <div className="faq-inner-content">
                <p>{formatString(value.attributes.name)} </p>
                <p className="cash">
                  {value.attributes.converted_currency_symbol +
                    formatNumberWithCommasAndDecimals(value.attributes.converted_market_value.toString())}
                </p>
                <p className={value.attributes.operand === '+' ? "plusStock" : "minusStock"}>
                  <span className={value.attributes.operand === '+' ? "plusStock" : "minusStock"}>{value.attributes.operand + value.attributes.converted_currency_symbol + addCommas(parseFloat(value.attributes.profit.toString()).toFixed(2))}</span>
                  {` (${value.attributes.operand}${parseFloat(value.attributes.profit_percentage).toFixed(2)}%)`}
                </p>
              </div>
            </div>
          ))
          : ""}
        <div data-test-id="viewDetail3" className="view-detail-btn">
          <NavLink className="view-detail-btn" to={`/PropertyAssetsDetail`}>{this.getStringDashboard("ViewDetails")}</NavLink>
        </div>
      </div>
    )
  }

  renderLiability = () => {
    return (
      <div
        className={this.state.expandLiability}
      >
        <div className="faq-inner-heading fih-8">
                <NavLink to="/AddLiability">
                    <img width="54" height="54" src={liablityImg} alt="liablityImg" />
                </NavLink>
                <div className="faq-heading faq-heading-9" style={{ width: '100%' }} data-test-id="liabilityTab"
        onClick={() => this.getLiabilityData()}>
                    <div className="faq-inner-heading-content faq-inner-heading-content2">
                        <p>{this.state.liabilityOverview.name}</p>
                        <span>{this.state.liabilityOverview.count} {this.getStringDashboard("Assets")}</span>
                    </div>
                    <div className="faq-heading-content">
                        <div className="inner-info">
                            <span>{this.getStringDashboard("Balance")}</span>
                            <p style={
                                this.state.liabilityOverview.balance !== "0" ? {
                                    color: "rgb(226,81,83)"
                                } : {}}>
                                {(this.state.liabilityOverview.balance !== "0" ? "-" : "") + this.state.liabilityOverview.symbol +
                                    this.state.liabilityOverview.balance.toLocaleString()}
                            </p>
                        </div>
                        <div className="down-arrow">
                            <img
                                src={
                                    this.state.activeAsset === "LiabilityOverview"
                                        ? subarrowicon
                                        : sidearrow
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        <div className="faq-content">
          {this.state.liabilityData
            ? this.state.liabilityData.map((value) => (
              <div className="faq-content-wrapper" key={value.id}>
                  <div style={{
                  height: "45px",
                  width: "45px",
                  borderRadius: "8px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  background: "#fff"
                }}>
                  <img
                    src={this.getLiabilityImg(value.form_type)}
                    alt="pension"
                    style={{ width: '100%', height: 'auto', maxWidth: '28px', maxHeight: '28px' }}
                  />
                </div>
                <div className="faq-inner-content">
                  <p>{formatString(value.name)} </p>
                  <span className="pension" style={{ color: "rgb(226,81,83)" }} >
                    - {value.converted_currency_symbol +
                      formatNumberWithCommasAndDecimals(Math.round(value.remaining_payment).toString())}
                  </span>
                </div>
              </div>
            ))
            : ""}
          <div data-test-id="viewDetail5" className="view-detail-btn">
            <NavLink className="view-detail-btn" to={`/LiabilityDetail`}>{this.getStringDashboard("ViewDetails")}</NavLink>
          </div>
        </div>
      </div>
    )
  }

  renderBank = () => {
    return (
        <div
            className={this.state.expandBank}
        >
            <div className="faq-inner-heading">
                <NavLink to="Cradditionalformsformschangerequests">
                    <img src={bankimage} alt="bankimage" />
                </NavLink>
                <div className="faq-heading" style={{ width: '100%' }} data-test-id="bankaccounttab" onClick={() => this.getBankAccountData()}>
                    <div className="faq-inner-heading-content">
                        <p>{this.state.bankAccountOverview.name}</p>
                        <span>
                            {this.state.bankAccountOverview.count} {this.getStringDashboard("Assets")}
                        </span>
                    </div>
                    <div className="faq-heading-content">
                        <div className="inner-info">
                            <span>{this.getStringDashboard("Balance")}</span>
                            <p>
                                {this.state.bankAccountOverview.symbol +
                                    this.state.bankAccountOverview.balance}
                            </p>
                        </div>
                        <div className="down-arrow">
                            <img
                                src={
                                    this.state.bankAccountOverview === "bankAccountOverview"
                                        ? subarrowicon
                                        : sidearrow
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="faq-content">
                {this.state.BankAccountNewData && this.state.BankAccountNewData.map((value) => (
                    <div className="faq-content-wrapper" key={value.id}>
                        <div style={value.connection_type === "manual" ? {
                            height: "56px",
                            width: "56px",
                            borderRadius: "9px",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                            background: "#fff"
                        } : {}}>
                            <img
                                src={value.connection_type === "manual" ? bankIcon : value.image}
                                alt="bank-account"
                                style={value.connection_type !== "manual" ? {
                                    width: "38px",
                                    height: "30px"
                                } : {}}
                            />
                        </div>
                        <div className="faq-inner-content">
                            <p>
                                {formatString(value.name)}{" "}  {value.account_number ? value.account_number : ""} 
                            </p>
                            <span>
                                {value.converted_symbol +
                                    formatNumberWithCommasAndDecimals(value.converted_balance.toString())}
                            </span>
                        </div>
                    </div>
                ))}
                <div data-test-id="viewDetail1" className="view-detail-btn">
                    <NavLink className="view-detail-btn" to={`/BankOverview`}>{this.getStringDashboard("ViewDetails")}</NavLink>
                </div>
            </div>
        </div>
    )
}
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <StyleWrapper className="pagedashboard-wrapper" onClick={this.handleGlobalDropdown}>
        <div className="header-wrapper">
          <div className="page-title">
            <h4>{this.getStringDashboard("Networth")}</h4>
          </div>
          <div className="page-info">
            <ul className="drop-menu">
              <li>
                <div
                  data-test-id="currencyDropdoen"
                  onClick={() => this.handleDropdown()}
                  className={this.state.dropdownVisiblity}
                >
                  <p className="currency2">
                    {this.state.selectedCurrency} <img src={subarrowicon} alt="subarrow" />
                  </p>
                  <ul className="currency2">
                  {this.renderCurrencyList()}
                  </ul>
                </div>
              </li>
              <li>
                <NavLink to="/Account-Detail">
                  <img src={settingicon} alt="setting" />
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="content-wrapper">
          <div className="dashboard-graph">
            {
              this.state.isNetWorthGraphLoade ? <div style={{position:'relative', height:'400px',width:'100%'}}>
              <NetworthLoaderContainer>
              <CircularProgress style={{ color: "#fff" }} size={40} color="inherit"/>
              </NetworthLoaderContainer>
            </div>
            : <>
            <div className="networth-text">
              <span className="networth-heading-text">{this.getStringDashboard("TotalNetworth")}</span><br />
              <span className="networth-count-text">{this.state.graphNetworth?.Networth}</span>
            </div>
            <ResponsiveContainer width="100%" height={400}>
              <LineChart data={this.state.graphNetworth?.networth_graph} margin={{ top: 50, right: 30, left: 20, bottom: 5 }}>
                <YAxis type="number" domain={["auto", "auto" ]} hide />
                <Tooltip content={this.CustomTooltip} />
                <Line type="monotone" dataKey="networth" stroke="#ffffff" strokeWidth={4} dot={false} />
              </LineChart>
            </ResponsiveContainer>
            </>
            }
          </div>
          <div className="month-btns">
            <span
              data-test-id="duration1"
              onClick={() => this.manageDuration("1")}
              className={this.state.duration ==''? "duration" :this.state.duration1}
            >
              1M
            </span>
            <span
              data-test-id="duration2"
              onClick={() => this.manageDuration("3")}
              className={this.state.duration2}
            >
              3M
            </span>
            <span
              data-test-id="duration3"
              onClick={() => this.manageDuration("6")}
              className={this.state.duration3}
            >
              6M
            </span>
            <span
              data-test-id="duration4"
              onClick={() => this.manageDuration("12")}
              className={this.state.duration4}
            >
              1Y
            </span>
          </div>
          <div className="assets-block">
            <div className="assets-block-heading">
              <div className="assets-content">
                <h4>{this.getStringDashboard("AssetsOverview")}</h4>
                {
                  !this.state.isNetWorthGraphLoade && <span>
                  <img
                    src={refreshicon}
                    data-test-id="refreshIconBtn"
                    onClick={()=>this.handleRefresh()}
                    alt="refresh"
                    className="refresh-icon-btn"
                  />{" "}
                  { formatDateWithTime(this.state.lastSyncTime)}
                </span>
                }
              </div>
              <div className="assets-button">
                <NavLink to="/CategoriessubcategoriesWeb">
                  <img src={buttonimage} alt="button" />
                </NavLink>
              </div>
            </div>
            {this.state.isLoader ? (
              <LoaderContainer>
              <CircularProgress style={{ color: "#fff" }} size={40} color="inherit"/>
              </LoaderContainer>
            ) : (
                 <div className="assets-block-content">
              <div className="faq-wrapper">
                {this.renderBank()}
                <div
                  className={this.state.expandCash}
                >
                  <div className="faq-inner-heading">
                <NavLink to="/AddCashWeb">
                    <img src={bankimage2} alt="bankimage" />
                </NavLink>
                <div className="faq-heading" style={{ width: '100%' }} data-test-id="cashtab" onClick={() => this.getCashData()}>
                    <div className="faq-inner-heading-content">
                        <p>{this.state.cashOverview.name}</p>
                        <span>{this.state.cashOverview.count} {this.getStringDashboard("Assets")}</span>
                    </div>
                    <div className="faq-heading-content faq-heading-content2">
                        <div className="inner-info">
                            <span>{this.getStringDashboard("Balance")}</span>
                            <p>
                                {this.state.cashOverview.symbol +
                                    this.state.cashOverview.balance}
                            </p>
                        </div>
                        <div className="down-arrow">
                            <img
                                src={this.renderImage()}
                            />
                        </div>
                    </div>
                </div>
            </div>
                  <div className="faq-content">
                   {this.renderCashData()}
                    <div data-test-id="viewDetail1" className="view-detail-btn">
                      <NavLink className="view-detail-btn" to={`/CashAssetsDetail`}>{this.getStringDashboard("ViewDetails")}</NavLink>
                    </div>
                  </div>
                </div>
                <div
                  className={this.state.expandStocks}
                >
                  <div className="faq-inner-heading">
                <NavLink to="/StockSelection">
                    <img src={bankimage3} alt="bankimage" />
                </NavLink>
                <div className="faq-heading" style={{ width: '100%' }} data-test-id="stockstab" onClick={() => this.getStockData()}>
                    <div className="faq-inner-heading-content">
                        <p>{this.state.StocksOverview.name}</p>
                        <span>{this.state.StocksOverview.count} {this.getStringDashboard("Assets")}</span>
                    </div>
                    <div className="faq-heading-content faq-heading-content3">
                        <div className="inner-info">
                            <span>{this.getStringDashboard("Balance")}</span>
                            {
                                this.renderBalance()
                            }
                        </div>
                        <div className="down-arrow">
                            <img src={sidearrow} />
                        </div>
                    </div>
                </div>
            </div>
                  {this.stockData()}
                </div>
                <div
                  className={this.state.expandShares}
                >
                  <div className="faq-inner-heading">
                <NavLink to="/AddSharesWeb">
                    <img src={bankimage4} alt="bankimage" />
                </NavLink>
                <div className="faq-heading" style={{ width: '100%' }} data-test-id="sharestab" onClick={() => this.getSharesData()}>
                    <div className=" faq-inner-heading-content6">
                        <p>Shares & Funds</p>
                        <span>{this.state.SharesOverview.count} {this.getStringDashboard("Assets")}</span>
                    </div>
                    <div className="faq-heading-content faq-heading-content4">
                        <div className="inner-info">
                            <span>{this.getStringDashboard("Balance")}</span>
                            <p>
                                {this.state.SharesOverview.symbol +
                                    this.state.SharesOverview.balance}
                            </p>
                        </div>
                        <div className="down-arrow">
                            <img
                                src={
                                    this.state.activeAsset === "SharesOverview"
                                        ? subarrowicon
                                        : sidearrow
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
                  {this.shareData()}
                </div>
                <div
                  className={this.state.expandCrypto}
                >
                  <div className="faq-inner-heading">
                <NavLink to="/CryptoSelection">
                    <img src={bankimage5} alt="bankimage" />
                </NavLink>
                <div className="faq-heading" style={{ width: '100%' }} data-test-id="cryptotab" onClick={() =>
                    this.getCryptoData()
                  }>
                    <div className="faq-inner-heading-content">
                        <p>{this.state.CryptoCurrencyOverview.name}</p>
                        <span>
                            {this.state.CryptoCurrencyOverview.count} {this.getStringDashboard("Assets")}
                        </span>
                    </div>
                    <div className="faq-heading-content faq-heading-content3">
                        <div className="inner-info">
                            <span>{this.getStringDashboard("Balance")}</span>
                            {
                                this.renderCryptoBalance()
                            }
                        </div>
                        <div className="down-arrow">
                            <img src={sidearrow} />
                        </div>
                    </div>
                </div>
            </div>
                  {this.cryptoData()}
                  <div className="faq-content" />
                </div>
                <div
                  className={this.state.expandProperty}
                >
                  <div className="faq-inner-heading fih-6">
                <NavLink to="/AddPropertyweb">
                    <img src={bankimage7} alt="propertyimg" />
                </NavLink>
                <div className="faq-heading faq-heading-7" style={{ width: '100%' }} data-test-id="propertytab"
                  onClick={() => this.getPropertyData()}>
                    <div className="faq-inner-heading-content faq-inner-heading-content3">
                        <p>{this.state.PropertyOverview.name}</p>
                        <span>{this.state.PropertyOverview.count} {this.getStringDashboard("Assets")}</span>
                    </div>
                    <div className="faq-heading-content faq-heading-content7">
                        <div className="inner-info">
                            <span>{this.getStringDashboard("Balance")}</span>
                            <p>
                                {this.state.PropertyOverview.symbol +
                                    this.state.PropertyOverview.balance}
                            </p>
                        </div>
                        <div className="down-arrow">
                            <img
                                src={this.renderPropertyImage()
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
                  {this.propertyData()}
                </div>
                <div
                  className={this.state.expandPortable}
                >
                  <div className="faq-inner-heading">
                <NavLink to="/PortableAssetweb">
                    <img src={bankimage8} alt="bankimage" />
                </NavLink>
                <div className="faq-heading" style={{ width: '100%' }} data-test-id="portabletab"
                  onClick={() => this.getPortableAssetData()}>
                    <div className="faq-inner-heading-content faq-inner-heading-content2">
                        <p>Luxury</p>
                        <span>{this.state.PortableOverview.count} {this.getStringDashboard("Assets")}</span>
                    </div>
                    <div className=" faq-heading-content">
                        <div className="inner-info">
                            <span>{this.getStringDashboard("Balance")}</span>
                            <p>
                                {this.state.PortableOverview.symbol +
                                    this.state.PortableOverview.balance}
                            </p>
                        </div>
                        <div className="down-arrow">
                            <img
                                src={
                                    this.state.activeAsset === "PortableOverview"
                                        ? subarrowicon
                                        : sidearrow
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
                  <div className="faq-content">
                    {this.state.portableData
                      ? this.state.portableData.map((value:any) => (
                        <div className="faq-content-wrapper" key={value.id}>
                          <img
                            src={this.getCategoryImage(value)}
                            alt="portable"
                            style={{ width: '100%', height: 'auto', maxWidth: '45px', maxHeight: '45px' }}
                          />
                          <div className="faq-inner-content">
                            <p id="a">{formatString(value.attributes.name)} </p>
                            <span className="portable">
                              {value.attributes.converted_currency_symbol +
                                formatNumberWithCommasAndDecimals(value.attributes.converted_market_value?.toString())}
                            </span>
                            <div style={{ lineHeight: 1.2, paddingBottom: "8px" }} className={value.attributes.operand === '+' ? "plusStock" : "minusStock"}>
                              {value.attributes.operand + value.attributes.converted_currency_symbol + Math.abs(parseFloat(value.attributes.converted_market_value) - (parseFloat(value.attributes.converted_price))).toFixed(2) + ` (${value.attributes.operand}${parseFloat(value.attributes.profit_percentage).toFixed(2)}%)`}
                            </div>
                          </div>
                        </div>
                      ))
                      : ""}
                    <div data-test-id="viewDetail4" className="view-detail-btn">
                      {" "}
                      <NavLink className="view-detail-btn" to={`/PortableAssetsDetail`}>{this.getStringDashboard("ViewDetails")}</NavLink>
                    </div>
                  </div>
                </div>
                <div
                  className={this.state.expandPension}
                >
                  <div className="faq-inner-heading fih-8">
                <NavLink to="/AddPensionweb">
                    <img src={bankimage9} alt="bankimage" />
                </NavLink>
                <div className="faq-heading faq-heading-9" style={{ width: '100%' }}  data-test-id="pensiontab"
                  onClick={() => this.getPensionData()}>
                    <div className="faq-inner-heading-content faq-inner-heading-content2">
                        <p>{this.state.PensionOverview.name}</p>
                        <span>{this.state.PensionOverview.count} {this.getStringDashboard("Assets")}</span>
                    </div>
                    <div className="faq-heading-content">
                        <div className="inner-info">
                            <span className="spanBalance">{this.getStringDashboard("Balance")}</span>
                            <p>
                                {this.state.PensionOverview.symbol +
                                    this.state.PensionOverview.balance}
                            </p>
                        </div>
                        <div className="down-arrow">
                            <img
                                src={
                                    this.state.activeAsset === "PensionOverview"
                                        ? subarrowicon
                                        : sidearrow
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
                  <div className="faq-content">
                    {this.state.pensionData
                      ? this.state.pensionData.map((value:any) => (
                        <div className="faq-content-wrapper" key={value.id}>
                          <img
                            src={pension}
                            alt="pension"
                            style={{ width: '100%', height: 'auto', maxWidth: '45px', maxHeight: '45px' }}
                          />
                          <div className="faq-inner-content">
                            <p>{formatString(value.attributes.name)} </p>
                            <span className="pension">
                              {value.attributes.converted_currency_symbol +
                                formatNumberWithCommasAndDecimals(value.attributes.converted_amount.toString())}
                            </span>
                          </div>
                        </div>
                      ))
                      : ""}
                    <div data-test-id="viewDetail5" className="view-detail-btn">
                      {" "}
                      <NavLink className="view-detail-btn" to={`/PensionAssetsDetail`}>{this.getStringDashboard("ViewDetails")}</NavLink>
                    </div>
                  </div>
                </div>
                {this.renderLiability()}
                {this.renderCommodity()}
              </div>
            </div>
             )}
          </div>
        </div>
      </StyleWrapper>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyleWrapper = styled(Box)({
  "& .faq-heading": {
    flexWrap: "wrap",
  },
  "& .assets-block-heading": {
    flexWrap: "wrap",
    gap: "20px",
  },
  "& .assets-content": {
    flexWrap: "wrap",
  }
})
const LoaderContainer = styled(Box)({
  position: "absolute",
 top: "100%",
 left: "50%",
 transform: "translate(-50%, -50%)"
})
const NetworthLoaderContainer = styled(Box)({
  position: "absolute",
 top: "50%",
 left: "50%",
 transform: "translate(-50%, -50%)"
})
// Customizable Area End

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import storage from "framework/src/StorageProvider";
import moment from "moment";
import { convertISOTo12HourFormat } from "../../../components/src/commonFn";


interface ClientDataObject {
  id: string;
  type: string;
  attributes: {
    email: string;
    full_name: string;
    location: string;
    consultation_call_plan: string | null;
    sheduled_for: string | null;
    call_status: boolean;
    services: string[];
    payment: string;
    payment_date: string | null;
    booking_date: string,
    start_time: string, 
    end_time: string,
  };
}

interface ClientDataResponseObject {
  clients: {
    data: ClientDataObject;
  };
}

interface VideoAppointment {
  booking: {
    data: {
      id: string;
      type: string;
      attributes: {
        booking_date: string;
        booking_time: string;
        plan_name: string;
        plan_duration: string;
        plan_benefits: string[];
        video_meeting_id: string;
      };
    };
  };
  videosdk_token: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  plannerInfo: {
    name:string,
    email:string,
    image:string
  },
  clientDetails: ClientDataObject | null,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start

  // Customizable Area End
}

export default class ClientDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserProfileAPICallId: string = "";
  getClientDataAPICallId: string = "";
  getVideoCallMeetingAPICallId:string = "";
 


  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];
    
    // Customizable Area End


    this.state = {
      // Customizable Area Start
        plannerInfo:{
            name:"",
            image:"",
            email:""
          },
        clientDetails: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.getUserProfile();
    this.getClientDetails();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.handleUserProfileResponse(apiRequestCallId,responseJson);
    this.handleClientDataResponse(apiRequestCallId,responseJson);
    this.handleVideoCallDataResponse(apiRequestCallId,responseJson);
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.getUserProfile();
  }
  
  getToken=()=>{
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  }


  apiCall = async (data: {
    contentType: string, method: string, endPoint: string, body: string | null 
  }) => {
    const { contentType, method, endPoint, body } = data;
    const clientDetailsRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));

    clientDetailsRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": contentType,
        token: localStorage.getItem("auhtToken")
      })
    );

    clientDetailsRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    clientDetailsRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    body && clientDetailsRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(clientDetailsRequestMessage.id, clientDetailsRequestMessage);
    return clientDetailsRequestMessage.messageId;
  };

  handleUserProfileResponse(apiCallId:string,responseJson: {
    data: {
      attributes:{
        name: string,
        email: string,
        image: string
      }
    }
  }){
    if(apiCallId === this.getUserProfileAPICallId){
      this.setState({
        plannerInfo: responseJson.data.attributes
      })
    }
  }

  getUserProfile = async () => {
    this.getUserProfileAPICallId = await this.apiCall({
      method: "GET" + '',
      endPoint: "bx_block_formapprovalworkflow/wealth_planners/show_profile",
      body: null,
      contentType:"application/json"
    });
  }

  getClientDetails = async () => {
    const accountId = await storage.get("clientId")
    this.getClientDataAPICallId = await this.apiCall({
      method: "GET" + '',
      endPoint: `bx_block_proposal_generation/client_proposals_generations/get_client_details?id=${accountId}`,
      body: null,
      contentType:"application/json"
    });
  }

  handleClientDataResponse(apiCallId:string,responseJson: ClientDataResponseObject){
    if(apiCallId === this.getClientDataAPICallId){
      this.setState({
        clientDetails: responseJson.clients.data
      })
    }
  }

  getScheduleCallString = (newDate: string, startDateValue: string, endDateValue: string) => {
    const dataString = "Scheduled for ";
    const scheduledDate = moment(newDate, "DD/MM/YYYY").format("Do MMMM")
    const startDate = convertISOTo12HourFormat(startDateValue)
    const endDate = convertISOTo12HourFormat(endDateValue)
    return dataString.concat(scheduledDate, ", ", startDate, " - ", endDate)
  }

  handleJoinCall = async () => {
    const accountId = await storage.get("clientId")
    this.getVideoCallMeetingAPICallId = await this.apiCall({
      method: "GET" + '',
      endPoint: `/bx_block_appointment_management/appointments/get_video_call_details?for=planner&account_id=${accountId}`,
      body: null,
      contentType:"application/json"
    });
  }

  handleVideoCallDataResponse(apiCallId:string,responseJson: VideoAppointment){
    if(apiCallId === this.getVideoCallMeetingAPICallId){
      const payload = {
        meetingId:responseJson.booking.data.attributes.video_meeting_id,
        authToken:responseJson.videosdk_token,
        userName:this.state.plannerInfo?.name,
        wealthPlanneName:this.state.clientDetails?.attributes.full_name,
      }
  
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "VideoCall");
      message.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), payload)
      message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
  
      this.send(message)
    }
  }

  navigateRoute = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
    );
    this.send(message)
  }

  // Customizable Area End 
}
import React from "react";
// Customizable Area Start
import {
    Box, Typography,
    Grid,
    OutlinedInput,
    FormControl,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
} from "@material-ui/core";

import { createTheme } from '@material-ui/core/styles';
import AddCommodityController, { Props } from "./AddCommodityController.web"
import Select from 'react-select';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { SuccessCard, calendarImg, commodity1, commodity2, image_Vuesax, imagenav_BackIcon } from "./assets";
import { Formik, Form, ErrorMessage } from "formik";
import { processInputValue, sanitizeAndRemoveCommas } from "../../../components/src/commonFn";



let theme = createTheme({
    palette: {
        primary: {
            main: "#2CE2D5",
            contrastText: "#fff",
        },
    },
});

theme = createTheme(theme, {
    overrides: {
        MuiOutlinedInput: {
            root: {
                "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
                    borderColor: "#fff",
                },
                "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
                    borderColor: "#fff",
                },
                "& .MuiOutlinedInput-input": {
                    borderBottom: '0px',
                    color: "white",
                },
                "& .MuiAutocomplete-endAdornment": {
                    position: 'unset'
                },
                "& $notchedOutline": {
                    borderColor: "#94989c",
                },

            },
        },
        MuiSvgIcon: {
            root: {
                color: '#94989c',
            },
        },
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: "#3C3C51",
            },
        },
        MuiDialog: {
            paper: {
                backgroundColor: '#395D6B',
                boxShadow: '0px 0px 10px 0px rgba(44, 226, 213, 0.16)',
                width: 'max-content',
                borderRadius: '8px',
            },
        },
        MuiPickersBasePicker: {
            pickerView: {
                color: "rgb(181, 176, 176)",
                backgroundColor: "rgb(57, 93, 107)",

            },
        },
        MuiPickersCalendarHeader: {
            switchHeader: {
                color: "rgb(181, 176, 176)",
                backgroundColor: "rgb(57, 93, 107)",
                "& button": {
                    color: "rgb(181, 176, 176)",
                    backgroundColor: "rgb(57, 93, 107)",
                }
            },
            dayLabel: {
                color: "rgb(181, 176, 176)"
            },
            dayheader: {
                color: "rgb(181, 176, 176)",
                backgroundColor: "rgb(57, 93, 107)",
            },

        },
        MuiPickersDay: {
            dayLabel: {
                color: "rgb(181, 176, 176)"
            },
            daySelected: {
                backgroundColor: "#2CE2D5",
            },
            day: {
                color: "rgb(181, 176, 176)",
            },
            current: {
                color: "rgb(181, 176, 176)"
            },
            dayDisabled: {
                color: "#ccc",
            },
        },
        MuiPickersModal: {
            dialogAction: {
                backgroundColor: "#3C3C51",
                color: "#33abb6",
            },
        },
    },
});

// Customizable Area End


export default class AddCommodity extends AddCommodityController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start


    // Customizable Area End

    render() {

        // Customizable Area Start

        return (
            <Box
                style={{
                    width: '100%',
                    padding: '24px',
                    height: '100vh',
                    overflowY: 'auto'
                }}
            >
                {/* header part */}
                <Box style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: 'space-between'
                }}>
                    <Box style={{ display: "flex", gap: '10px', alignItems: "center", }}>
                        <img
                            style={{
                                cursor: "pointer",
                            }}
                            src={imagenav_BackIcon}
                            onClick={this.handleBack}
                            data-test-id="handle-back"
                        />
                        <div
                            style={{
                                fontSize: "24px",
                                fontFamily: "Poppins",
                                fontWeight: 600,
                                color: "#fff",
                            }}
                        >
                            Commodity
                        </div>
                    </Box>
                    <Box className="addCashrightHeaderDropSetting">
                        <Box
                            style={{
                                alignItems: "center",
                                display: "flex",
                            }}
                        >
                            <FormControl>
                                <Select
                                    value={this.state.selectedCurrency}
                                    classNamePrefix="an-simple-selectUSD"
                                    className="selectUSD"
                                    options={this.state.currencyListDropDown}
                                    onChange={(value) => {
                                        value?.value && this.setCurrency(value);
                                    }}
                                    data-test-id="selectMenu"
                                />
                            </FormControl>
                            <Box
                                onClick={() => this.goToAccountDetail()}
                                data-test-id="accountDetail"                    >
                                <img
                                    src={image_Vuesax}
                                    style={{
                                        height: "41px",
                                        cursor: "pointer",
                                        width: "41px",
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box style={{
                    display: 'flex',
                    gap: '24px',
                    flexDirection: 'column',
                }}>
                    <Typography className="title">Commodity Type</Typography>

                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>
                            <Box onClick={() => {
                                this.handleChangeCommodityType("metal")
                            }} style={this.state.commodityType === "metal" ? webStyle.activeStyle : webStyle.inActiveStyle} data-test-id="metal-tab">
                                <img height="96px" width='96px' src={commodity2} />
                                <Typography style={this.state.commodityType === "metal" ? webStyle.activeFont : webStyle.inActiveFont}>Precious Metals</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Box data-test-id="other-tab" style={this.state.commodityType === "other" ? { ...webStyle.activeStyle } : { ...webStyle.inActiveStyle }} onClick={() => {
                                this.handleChangeCommodityType("other")
                            }}>
                                <img width='96px' height="96px" src={commodity1} />
                                <Typography style={this.state.commodityType === "other" ? webStyle.activeFont : webStyle.inActiveFont}>Others</Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box>
                        <Formik
                            enableReinitialize
                            initialValues={this.state.formValues}
                            onSubmit={(values: any) => {
                                const submitValues = {
                                    ...values,
                                    purchasePrise: parseFloat(sanitizeAndRemoveCommas(values.purchasePrise)),
                                    quantity: parseFloat(sanitizeAndRemoveCommas(values.quantity)),
                                };
                                this.handleSubmit(submitValues);
                            }}
                            data-test-id="commodityForm"
                            validationSchema={this.validationSchema}
                        >
                            {({ handleBlur, values, handleSubmit, handleChange, setFieldValue }) => (
                                <Form noValidate onSubmit={handleSubmit} data-test-id='submitForm' translate={"en"}>
                                    <Grid container spacing={0}>
                                        <Grid item md={8} xs={12}>
                                            <Typography className="title">{this.state.commodityType === "metal" ? "Name Of metal" : "Name Of a commodity"}</Typography>
                                        </Grid>
                                        <Grid item md={8} xs={12} >
                                            <OutlinedInput
                                                style={webStyle.inputRoot}
                                                data-test-id="metalName"
                                                value={values.name}
                                                onChange={handleChange}
                                                placeholder={"Enter metal name"}
                                                fullWidth={true}
                                                name="name"
                                                type="text"
                                            />
                                            <Box sx={webStyle.fieldError}>
                                                <ErrorMessage name="name" />
                                            </Box>
                                        </Grid>
                                        <Grid item md={8} xs={12} style={webStyle.fieldStyle}>
                                            <Typography className="title">Quantity</Typography>
                                        </Grid>
                                        <Grid item md={8} xs={12} >
                                            <OutlinedInput
                                                style={webStyle.inputRoot}
                                                data-test-id="quantity"
                                                placeholder={"Enter commodity quantity"}
                                                fullWidth={true}
                                                onChange={(e) => {
                                                    let inputValue = e.target.value;
                                                        const result = processInputValue(inputValue, 7);
                                                        if (result) {
                                                            const { actualValue, formattedValue } = result;
                                                            setFieldValue('quantity', actualValue);
                                                            handleChange('quantity')(formattedValue);
                                                        }
                                                }}
                                                onBlur={handleBlur}
                                                value={values.quantity}
                                                name="quantity"
                                                type="text"
                                            />
                                            <Box sx={webStyle.fieldError}>
                                                <ErrorMessage name="quantity" />
                                            </Box>

                                        </Grid>
                                        <Grid item md={8} xs={12} style={webStyle.fieldStyle}>
                                            <Typography className="title">Purchase Price</Typography>
                                        </Grid>
                                        <Grid item md={8} xs={12} >
                                            <OutlinedInput
                                                style={webStyle.inputRoot}
                                                data-test-id="metalName"
                                                placeholder={"Enter purchase price"}
                                                fullWidth={true}
                                                onBlur={handleBlur}
                                                type="text"
                                                onChange={(e) => {
                                                    let inputValue = e.target.value;
                                                        const result = processInputValue(inputValue, 11);
                                                        if (result) {
                                                            const { actualValue, formattedValue } = result;
                                                            setFieldValue('purchasePrise', actualValue);
                                                            handleChange('purchasePrise')(formattedValue);
                                                        }
                                                }}
                                                value={values.purchasePrise}
                                                name="purchasePrise"
                                            />
                                            <Box sx={webStyle.fieldError}>
                                                <ErrorMessage name="purchasePrise" />
                                            </Box>
                                        </Grid>
                                        <Grid item md={8} xs={12} style={webStyle.fieldStyle}>
                                            <Typography className="title">Currency</Typography>
                                        </Grid>
                                        <Grid item md={8} xs={12} >
                                            <Select
                                                styles={{
                                                    control: (defaultStyles) => ({
                                                        ...defaultStyles,
                                                        backgroundColor: "#2a2a38",
                                                        borderRadius: '8px',
                                                        padding: "8px",
                                                        color: 'rgba(255, 255, 255, 0.60)',
                                                        fontSize: '16px',
                                                        fontFamily: 'Poppins',
                                                        textAlign: "left",
                                                        fontWeight: 500,
                                                    }),
                                                }}
                                                classNamePrefix="an-simple-select"
                                                options={this.state.currencyListDropDownForm}
                                                onChange={(event) => event && setFieldValue("currency", event)}
                                                className="selectMenu selectedMenuDropdown"
                                                data-test-id="selectCurrencyMenu"
                                                value={values.currency}
                                                placeholder="Select Currency"
                                            />
                                            <Box sx={webStyle.fieldError}>
                                                <ErrorMessage name="currency.value" />
                                            </Box>
                                        </Grid>
                                        <Grid item md={8} xs={12} style={webStyle.fieldStyle}>
                                            <Typography className="title">Transaction Date</Typography>
                                        </Grid>
                                        <Grid item md={8} xs={12}>
                                            <Box data-test-id="datePicker">
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        error={false}
                                                        placeholder="DD/MM/YYYY"
                                                        variant="inline"
                                                        fullWidth
                                                        inputVariant="outlined"
                                                        format="dd/MM/yyyy"
                                                        name="transactionDate"
                                                        value={values.transactionDate}
                                                        onChange={(values) => setFieldValue("transactionDate", values, true)}
                                                        autoOk
                                                        InputProps={{
                                                            style: {
                                                                padding: '10px 0px',
                                                                borderRadius: '8px',
                                                                color: 'white'
                                                            }
                                                        }}
                                                        KeyboardButtonProps={{
                                                            style: {
                                                                color: 'white'
                                                            }
                                                        }}
                                                        data-test-id="transactionDate"
                                                        helperText=""
                                                        className="uploadBox newBoxContant12Pension"
                                                        keyboardIcon={<img src={calendarImg} style={{
                                                            borderRadius: "0",
                                                            height: "18px",
                                                            width: "18px",
                                                            marginTop: "-4px"
                                                        }} />}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Box>
                                            <Box sx={webStyle.fieldError}>
                                                <ErrorMessage name="transactionDate" />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Box style={{ textAlign: "end" }} data-test-id="formSubmit">
                                        <Button
                                            style={webStyle.nextButton}
                                            data-test-id="submitButton"
                                            variant="contained"
                                            type="submit"
                                            className="nextButton">
                                            Save
                                        </Button>
                                    </Box>
                                </Form>
                            )}
                        </Formik>

                    </Box>
                </Box>
                <Dialog
                    data-test-id="dialogModel"
                    open={this.state.openSuccessModal}
                    keepMounted
                    aria-describedby="alert-dialog-slide-description"
                    aria-labelledby="alert-dialog-slide-title"
                    onClose={() => this.goToBackPage()}
                    PaperProps={{ style: this.dialogSuccessPaperStyle }}
                >
                    <DialogTitle id="alert-dialog-slide-title" style={{ textAlign: "center" }}><img src={SuccessCard} /></DialogTitle>
                    <DialogContent style={{ padding: '8px 20px', textAlign: "center" }}>
                        <DialogContentText id="alert-dialog-slide-description" className='dialogContent' style={{
                            textAlign: "center"
                        }}>
                            Your asset is added successfully
                        </DialogContentText>

                    </DialogContent>
                </Dialog>
            </Box>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
type IStyle = { [key: string]: React.CSSProperties }

const webStyle: IStyle = {
    inputRoot: {
        borderRadius: '8px',
        height: '56px',
        outline: 'none',
        fontFamily: 'Poppins',
    },
    activeStyle: {
        width: "200px",
        height: "200px",
        backgroundColor: '#2CE2D5',
        flexDirection: "column",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        borderRadius: "12px",
        display: "flex",
        cursor: "pointer"
    },
    inActiveStyle: {
        width: "200px",
        height: "200px",
        backgroundColor: '#36364D',
        flexDirection: "column",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        borderRadius: "12px",
        display: "flex",
        cursor: "pointer"
    },
    activeFont: {
        fontSize: '16px',
        textAlign: 'center',
        paddingTop: '9px',
        color: '#3C3C50',
        fontFamily: 'Poppins',
        fontWeight: 500,
    },
    inActiveFont: {
        fontSize: '16px',
        textAlign: 'center',
        paddingTop: '9px',
        color: '#FFF',
        fontFamily: 'Poppins',
        fontWeight: 500,
    },
    fieldError: {
        color: "#EE5353",
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
    },
    nextButton: {
        color: '#3C3C50',
        textAlign: 'center',
        fontSize: '17px',
        fontFamily: 'Poppins',
        fontWeight: 700,
        lineHeight: '24px',
        textTransform: 'none',
        width: "auto",
        marginTop: '30px'
    },
    fieldStyle: {
        marginTop: "15px"
    }
}
// Customizable Area End
import React from "react";

// Customizable Area Start
import '../assets/portableAsset.css';
import '../assets/galleryBtnModal.css';
import '../assets/galleryModal.css';
import '../assets/docOpenerModal.css';
import '../assets/confirmationModal.css';
import { Link } from 'react-router-dom';
import { Select, MenuItem, FormControl, Grid, Menu, Box, styled } from '@material-ui/core'
import { addCommas } from "../../../components/src/common";
// Customizable Area End

import AssetOverviewController, {
  Props,
} from "./AssetOverviewController";
import { headereBackIcon, settingicon, vertical3Dots } from "./assets";

export default class PortableAssetsOverview extends AssetOverviewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getCurrencyList() {
    return this.state.currencyList && this.state.currencyList.list && this.state.currencyList.list.length > 0 ? this.state.currencyList.list : [];
  }

  getSelectedCurrencyItem(currency_list: any[]) {
    return currency_list.length > 0 && currency_list.find((currency: any) => currency.id === this.state.currencyList.selection) || null;
  }

  getDocFileExtension(docUrl: string) {
    return docUrl && this.getFileExtension(docUrl) || null;
  }

  checkDocTypeImage(fileExtension: any) {
    return fileExtension && ['jpg', 'jpeg', 'png'].includes(fileExtension);
  }

  fetchPortablesAsssetKeys(data: any) {
    let obj: any = {
      receipt: '',
      warrants: '',
      name: '',
      price: '',
      currencySymbol: '',
      galleryImages: '',
      purchaseDate: '',
      model: '',
      shouldShowDefaultImage: false,
      defaultImageUrl: null,
      market_value: '',
      profit: 0,
      profit_prcentage: ''
    }
    const attributes = data && data.attributes;
    if (attributes) {
      obj.receipt = attributes.receipts;
      obj.warrants = attributes.warrants;
      obj.name = attributes.name;
      obj.price = attributes.converted_price;
      obj.currencySymbol = attributes.converted_currency_symbol;
      obj.galleryImages = attributes.images || [];
      obj.shouldShowDefaultImage = !attributes.images;
      obj.purchaseDate = attributes.purchase_date;
      obj.model = attributes.model;
      obj.defaultImageUrl = attributes.sub_category && attributes.sub_category.image && attributes.sub_category.image.url;
      obj.market_value = attributes.converted_market_value;
      obj.profit = attributes.profit;
      obj.profit_percentage = attributes.profit_prcentage;
      return obj;
    }

    return obj
  }

  renderDocOpenerModal() {
    const fileExtension = this.getDocFileExtension(this.state.docUrl);
    const isImage = this.checkDocTypeImage(fileExtension);
    return (
      <>
        {
          this.state.isDocOpen && <div className="main-doc-opener-wrapper">
            <div className="doc-modal-popup-container" ref={this.docModalRef}>
              {
                isImage ? <img src={this.state.docUrl} width="100%" height="100%" />
                  : <embed
                    type="application/pdf"
                    src={this.state.docUrl}
                    width="100%"
                    height="100%"
                  />
              }
              <button data-test-id="portable-share-button" className="portable-share-button"
                onClick={() => this.handleDownloadDoc(this.state.docUrl)}>Share</button>
            </div>
          </div>
        }
      </>
    )
  }

  renderUploadDocumentModal() {
    return <>
      {
        this.state.isUploadDocumentOpen && <div className="portable-asset-gallery-btn-main-container">
          <div className="portable-modal-popup-container portable-model-size" ref={this.uploadDocModalRef}>
            <div className="portable-single-btn">
              <div className="portable-btn-upload btn-title-doc portable-white-btn-title" data-test-id={`upload-receipt`}
                onClick={() => this.receiptBtnRef.current && this.receiptBtnRef.current.click()}>
                {`Upload Receipt`}
                <input
                  type="file"
                  accept=".jpg, .jpeg, .png, .pdf"
                  ref={this.receiptBtnRef}
                  style={{ display: 'none' }}
                  data-test-id="input-for-receipt"
                  onChange={(e: any) => {
                    if (e.target.files) {
                      this.setState({ receipt: e.target.files[0], isUploadDocumentOpen: false });
                      this.updatePortableAssetData(this.state.currentCardId, 'data[attributes][receipts][]', e.target.files[0]);
                    }
                  }}
                />
              </div>
            </div>

            <div className="portable-single-btn">
              <div className="portable-btn-upload btn-title-doc black-btn-title" data-test-id="upload-certificate" onClick={() => this.certificateBtnRef.current.click()}>
                {`Upload Certificate`}
                <input
                  type="file"
                  data-test-id="input-for-certificate"
                  accept=".jpg, .jpeg, .png, .pdf"
                  ref={this.certificateBtnRef}
                  style={{ display: 'none' }}
                  onChange={(e: any) => {
                    if (e.target.files) {
                      this.setState({ certificate: e.target.files[0], isUploadDocumentOpen: false });
                      this.updatePortableAssetData(this.state.currentCardId, 'data[attributes][warrants][]', e.target.files[0]);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      }
    </>
  }

  renderGalleryModal() {
    return <>
      {
        this.state.isGalleryModalOpen && <div className="portable-asset-gallery-main-container">
          <div className="portable-gallery-modal-popup-container" ref={this.galleryModalRef}>
            <div className="gallery-image-container">
              {
                this.state.selectedGallery && this.state.selectedGallery.length > 0 && this.state.selectedGallery.map((imgData: any, index: number) => {
                  return (
                    <div className="gallery-card" key={index}>
                      <img src={imgData.url} alt='gallery image' />
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      }
    </>
  }

  renderMainImage(galleryImages: any[]) {
    return (
      <div className={`portable-gallery-image-container-main ${galleryImages.length === 1 && 'single-gallery-image-width border-radius-single' || ''} ${galleryImages.length > 1 && 'multiple-gallery-image-width'}`}>
        <img src={galleryImages[0].url} alt='gallery image 1' />
      </div>
    )
  }

  renderDefaultImage(imgURl: string, shouldShow: boolean = false) {
    if (shouldShow) {
      return (
        <div className={`portable-gallery-image-container-main cursor-pointer-none`}>
          <img src={imgURl} alt='default gallery image 1' />
        </div>
      )
    }
  }

  renderSubImages(galleryImages: any[]) {
    if (galleryImages.length > 1) {
      return (
        <div className="portable-gallery-image-container-sub ">
          <div className="portable-small-image-container portable-border-top-radius">
            <img src={galleryImages[1].url} alt='portable gallery image 1' />
          </div>

          {
            galleryImages.length > 2 && <div className="portable-small-image-container">
              <img src={galleryImages[2].url} alt='portable gallery image 2' />
            </div>
          }
          {
            galleryImages.length > 3 && <div className="portable-small-image-container relative portable-border-bottom-radius">
              <img src={galleryImages[3].url} alt='portable gallery image 3' />
              {
                galleryImages.length > 4 &&
                <div className="more-image-counter">{`${galleryImages.length - 4}+`}</div>
              }
            </div>
          }
        </div>
      )
    }
  }

  renderConfirmationModal() {
    if (this.state.isDeleteConfimationModalOpen && this.state.currentCardId) {
      return (
        <div className="portable-modal-background">
          <div className="portable-modal-popup-container">
            <div className="portable-modal-title">Are you sure you want to delete ?</div>
            <div className="portable-modal-button-container">
              <div className="portable-modal-button modal-save" data-test-id='popup-cancel-click' onClick={() => this.setState({ isDeleteConfimationModalOpen: false })}>
                Cancel
              </div>

              <div className="portable-modal-button modal-discard" data-test-id='popup-delete-click' onClick={() => {
                this.setState({ isDeleteConfimationModalOpen: false });
                this.deletePortableAssetData(this.state.currentCardId)
              }}>
                Delete
              </div>
            </div>

          </div>
        </div>
      )
    }
  }

  conditionalRender = (condition: boolean, truthyValue: string, falsyValue: string) => {
    return condition ? truthyValue : falsyValue
  }


  addToggleBoxes(){
    return this.state.portableFiltersData.length > 0 && this.state.portableFiltersData.map((filterData, index) => (
      <Grid item key={index}>
        <Box
          className={`portable-btn-upload portable-single-btn-upload btn-title-doc black-btn-title filter-btn ${this.conditionalRender(this.state.selectedPortableFilterId === filterData.attributes.id, "active", "")}`}
          data-test-id={`property-filter-${filterData.attributes.id}`}
          onClick={() => this.handlePortableFilterSelect(filterData.attributes.id)}
        >
          {filterData.attributes.name}
        </Box>
      </Grid>
    ))
  }
  marketValue(market_value: string, currencySymbol:string){
    return market_value && `${currencySymbol}${market_value}`
  }
  // Customizable Area End

  render() {
    const currency_list = this.getCurrencyList();
    const selectedCurrency = this.getSelectedCurrencyItem(currency_list);
    return (
      // Customizable Area Start
      <div className="portable-asset-overview-main-container">
        <div className="portable-asset-overview-container">
          <div className="header-wrapper">
            <div className="page-title">
              <h4>
                <Link to={'/Dashboard'} className="navigation-back-icon">
                  <img src={headereBackIcon}></img>
                </Link>
                Luxury Assets</h4>
            </div>
            <div className="page-info">
              <ul className="drop-menu">
                <li>
                  <FormControl className="an-custom-select">
                    <Select
                      displayEmpty
                      value={selectedCurrency ? selectedCurrency.id : ''}
                      data-test-id="select-change"
                      onChange={async (e: any) => {
                        if (e.target.value) {
                          await this.onAddCurrencyToAccount(e.target.value);
                          setTimeout(async () => {
                            await this.loadPortableAssetData();
                          }, 500);
                        }
                      }}
                      className="an-select-dropdown"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {currency_list.length > 0 &&
                        currency_list.map((currency: any) => (
                          <MenuItem key={currency.id} value={currency.id} className="an-custom-menu-item">
                            {`${currency.symbol} ${currency.currency_type}`}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </li>
                <li>
                  <Link to="/Account-Detail">
                    <img src={settingicon} alt="setting" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="property-filters">
            <Grid container spacing={4} className="property-filters-container">
              
              {this.addToggleBoxes()}

              <Grid item>
                <Box 
                  className={`portable-btn-upload portable-single-btn-upload btn-title-doc black-btn-title filter-btn ${this.conditionalRender(this.state.selectedPortableFilterId === 0, "active", "")}`}
                  onClick={() => this.handlePortableFilterSelect(0)}
                  data-test-id={`property-filter-0`}
                >
                  All
                </Box>
              </Grid>
            </Grid>
          </div>
          <Grid container spacing={2} style={{paddingLeft: "10px"}} className="portable-card-container">
            {
              this.state.portableAssetData.length > 0 && this.state.portableAssetData.map((data, index: number) => {
                const { receipt, warrants, name, price, currencySymbol, galleryImages, purchaseDate, model,
                  shouldShowDefaultImage, defaultImageUrl, market_value, profit } = this.fetchPortablesAsssetKeys(data);
                return (
                  <Grid item xs={12} sm={6} xl={4}>
                  <PortableCardWrapper className="portable-card" key={data.id}>
                    <div className="portable-card-left">
                      <div className={`portable-card-left-upload`}>
                        {
                          galleryImages.length > 0 &&
                          <div data-test-id={`on-gallery-click${index}`}
                            className="portable-gallery-image-container portable-cursor-pointer"
                            onClick={() => this.setState({ selectedGallery: galleryImages, isGalleryModalOpen: true, currentCardId: data.id })}>
                            {this.renderMainImage(galleryImages)}
                            {this.renderSubImages(galleryImages)}
                          </div>
                        }
                        {
                          this.renderDefaultImage(defaultImageUrl, shouldShowDefaultImage)
                        }

                        <div className="portable-btn-container-main">
                          {receipt && warrants &&
                            <div className="portable-multiple-btn btn-title-doc portable-white-btn-title">
                              <div data-test-id={`receipt-click${index}`} className={`portable-btn-upload`}
                                onClick={() => this.setState({ isDocOpen: true, docUrl: receipt[0].url })}>
                                Receipt
                              </div>
                              <div
                                data-test-id={`certificate-click${index}`}
                                className={`portable-btn-upload`} onClick={() => this.setState({ isDocOpen: true, docUrl: warrants[0].url })}>
                                Certificate
                              </div>
                            </div> ||
                            receipt && !warrants &&
                            <div className="portable-single-btn">
                              <div data-test-id={`receipt-click${index}`} className="portable-btn-upload portable-single-btn-upload btn-title-doc portable-white-btn-title" onClick={() => this.setState({ isDocOpen: true, docUrl: receipt[0].url })}>
                                Receipt
                              </div>
                            </div>
                            ||
                            !receipt && warrants &&
                            <div className="portable-single-btn">
                              <div data-test-id={`certificate-click${index}`} className="portable-btn-upload portable-single-btn-upload btn-title-doc portable-white-btn-title" onClick={() => this.setState({ isDocOpen: true, docUrl: warrants[0].url })}>
                                Certificate
                              </div>
                            </div> ||
                            <div data-test-id={`on-upload-doc-click${index}`} className="portable-single-btn" onClick={() => this.setState({ isUploadDocumentOpen: true, currentCardId: data.id })}>
                              <div className="portable-btn-upload portable-single-btn-upload btn-title-doc portable-white-btn-title">
                                Upload Document
                              </div>
                            </div>}

                          <div className="portable-single-btn">
                          <a
                            href="https://www.google.com/"
                            target="_blank" 
                            className="portable-btn-upload portable-single-btn-upload btn-title-doc black-btn-title"
                          >
                          Insure
                         </a>
                          </div>
                        </div>
                      </div>
                      <div className="portable-card-left-detail">
                      <span className="nameOverLape">{name}</span>
                      <Grid container>
                        <Grid item xs={4} className="portable-card-data-item">
                            <div className="sub-tile-bold-section">Purchase Price</div>
                            <div className="sub-tile-normal-section">{price && `${currencySymbol}${price}`}</div>
                        </Grid>
                        <Grid item xs={4} className="portable-card-data-item">
                            <div className="sub-tile-bold-section">Date</div>
                            <div className="sub-tile-normal-section">{purchaseDate}</div>
                        </Grid>
                        <Grid item xs={4} className="portable-card-data-item">
                            <div className="sub-tile-bold-section">Model</div>
                            <div className="sub-tile-normal-section" style={{ width: '70px' }}><span style={{
                              width: '100%',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: 'block'}} >{model}</span></div>
                        </Grid>
                      </Grid>
                      <Grid container className="portable-card-data-item-margin-top">
                        <Grid item xs={4} className="portable-card-data-item">
                            <div className="sub-tile-bold-section">Market Value</div>
                            <div className="sub-tile-normal-section l-blue">{this.marketValue(market_value,currencySymbol)}</div>
                        </Grid>
                        <Grid item xs={4} className="portable-card-data-item">
                            <div className="sub-tile-bold-section">Profit</div>
                            <div className="sub-tile-normal-section l-blue" >{`${data.attributes?.operand}${currencySymbol}${addCommas(profit?.toString())}`}</div>
                        </Grid>
                        <Grid item xs={4} className="portable-card-data-item">
                            <div className="sub-tile-bold-section">Profit%</div>
                            <div className="sub-tile-normal-section l-blue" style={{ width: '70px' }}><span style={{
                              width: '100%',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: 'block'}} >{`${data.attributes?.operand}${addCommas(data.attributes?.profit_percentage?.toString())}`}%</span></div>
                        </Grid>
                      </Grid>
                      </div>
                    </div>

                    <div className="portable-card-right">
                      <img
                        onClick={(event: React.MouseEvent<HTMLElement>) => this.handleMenuModal(event, data.id)}
                        data-test-id={`portable-vertical-icon-click-${index}`}
                        
                        alt='vertival-dots' src={vertical3Dots} />
                      {this.state.isVerticalPopoverOpen && data.id === this.state.currentCardId && (
                        <Menu
                        onClose={this.handleDocumentMenuModalClose}
                        data-test-id="cash-popup-menu"
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        anchorEl={this.state.anchorEl}
                        id="simple-menu"
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        PaperProps={{
                          style: {width:'55px',background:"#395D6B", padding:"0 5px", borderRadius:'8px'}
                        }}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                          
                        >
                          <Link to={{
                            pathname: '/PortableAssetweb',
                            state: { portableEditData: data }
                          }} className="portable-edit-option" data-test-id={`portable-edit-${index}`} onClick={() => {
                            this.setState({ isVerticalPopoverOpen: false });
                          }}>
                            Edit
                          </Link>
                          <div className="portable-delete-option" data-test-id={`portable-delete-${index}`} onClick={() => {
                            this.setState({ isVerticalPopoverOpen: false, isDeleteConfimationModalOpen: true, currentCardId: data.id });
                          }}>
                            Delete
                          </div>
                        </Menu>
                      )}
                    </div>
                  </PortableCardWrapper>
                  </Grid>
                )
              }
              )
            }
            {
              this.state.portableAssetData.length == 0 && 
              <Box className="no-asset-box">
                <span>
                  No assets available in this category!
                </span>
              </Box>
            }
          </Grid>
        </div>
        {
          this.renderUploadDocumentModal()
        }

        {
          this.renderGalleryModal()
        }

        {
          this.renderDocOpenerModal()
        }
        {
          this.renderConfirmationModal()
        }
      </div >
      // Customizable Area End
    );
  }
}

const PortableCardWrapper = styled(Box)({
  minWidth: "max-content"
})
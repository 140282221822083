import React from "react";

import {

  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import "../../email-account-login/assets/border.css"
// Customizable Area End

import WeathPlannerCallEndController, {
  Props,
} from "./WealthPlannerCallEndController.web";

export default class CallEndForWealthPlanner extends WeathPlannerCallEndController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start

      <>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%', flexDirection: 'column' }}>
          <text style={{ color: 'white', fontSize: '24', marginBottom: 20 }}>Appointment Ended</text>
          <div style={webStyle.imageContainerStyle}>
            <div
              data-test-id="avatar-placeholder"
              style={{
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                backgroundColor: '#ccc', 
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '2em',
                color: '#fff',
                objectFit: 'cover'
              }}
            >
              {this.state.wealthplannerImagee ? (
                <img
                  data-test-id="wealthplanner-image"
                  src={this.state.wealthplannerImagee}
                  alt="Wealth Planner"
                  style={{ width: '100%', height: '100%', borderRadius: '50%', objectFit: 'cover' }}
                />
              ) : (
                <span data-test-id="wealthplanner-initials">
                  {this.getInitials(this.state.wealthplannerNamee)}
                </span>
              )}
            </div>
          </div>
          <div style={webStyle.textStyle1}>
            <text>{this.state.wealthplannerNamee}</text>
          </div>
          <div style={webStyle.textStyle2}>
            <text>Wealth Planner</text>
          </div>
          <div style={webStyle.gapStyle}></div>
          <div style={{ marginBottom: '20px' }}>
            <button
              data-test-id="backButtonBtn"
              style={webStyle.buttonStyle1}
              onClick={() => this.navigateToWealthPlannerHomepage()}
            >
              Go back to Home Page
            </button>
          </div>
        </div>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  textStyle1: {
    color: 'white', fontSize: '30', fontWeight: 600, marginBottom: 10
  },
  textStyle2: {
    color: 'white', fontSize: '24',
  },
  imageContainerStyle: {
    width: '10vw',
    height: '10vw',
    marginBottom: '20px'
  },
  gapStyle: {
    height: '70px',
  },
  buttonStyle1: { color: '#C9F9F6', border: '1px solid #C9F9F6', background: '#3C3C51', padding: '9px 48px', fontWeight: 600, borderRadius: '5px', cursor: 'pointer', width: '400px' },


};
// Customizable Area End

Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start

exports.btnExampleTitle = "CLICK ME";
exports.test = "test";

exports.languageListLogin = {
  English: {
    Mortgage: {
      title: "Add Mortgage",
      name: "Mortgage name",
      placeholderName: "Enter your mortgage name",
      amount: "Mortgage amount",
      placeholderAmount: "Enter the amount",
      term: "Mortgage term",
      months: "Months",
      placeholderTerm: "Enter years",
      placeholderMonths: "Enter months",
      annualInterestRate: "Annual interest rate (%)",
      placeholderAnnualInterestRate: "Enter annual interest rate (%)",
      monthlyPayments: "Mortgage monthly payments",
      placeholderMonthlyPayments: "Enter mortgage monthly payments",
      startDate: "Mortgage start date",
      firstPayment: "First mortgage payment date",
      placeholderFirstPayment: "Enter First mortgage payment",
      associatedInvestment: "Associated investment",
      amountError: "Mortgage amount is required",
      nameError: "Mortgage name is required",
      termError: "Mortgage term is required",
      termDecimalError: "Mortgage term must be an integer",
      monthsMinError: "Mortgage months must be greater than or equal to 0",
      monthsDecimalError: "Mortgage months must be an integer",
      monthsMaxError: "Mortgage months must be less than or equal to 12",
      monthsError: "Months is required",
      annualInterestRateError: "Annual interest rate is required",
      monthlyPaymentsError: "Monthly Payments is required",
      startDateError: "Start date is required",
      firstPaymentError: "First payment date is required",
      associatedInvestmentError: "Associated Investment is required",
      moreAmountError:"First loan payment can't be more than actual amount",
      save: "Save",
      currencyTypeError: "Currency is required",
      currency_type: "Currency",
    }, 
    PersonalLoan: {
      title: "Add Personal Loans",
      name: "Loan name",
      placeholderName: "Enter your loan name",
      amount: "Loan Amount",
      placeholderAmount: "Enter the amount",
      term: "Loan term",
      placeholderTerm: "Enter years",
      months: "Months",
      placeholderMonths: "Enter months",
      annualInterestRate: "Annual interest rate (%)",
      placeholderAnnualInterestRate: "Enter annual interest rate (%)",
      monthlyPayments: "Loan monthly payments",
      placeholderMonthlyPayments: "Enter loan monthly payments",
      firstPayment: "First loan payment date",
      startDate: "Loan start date",
      placeholderFirstPayment: "Enter First loan payment",
      associatedInvestment: "Associated investment",
      nameError: "Loan name is required",
      amountError: "Loan amount is required",
      termError: "Loan term is required",
      termDecimalError: "Loan term must be an integer",
      monthsDecimalError: "Loan months must be an integer",
      monthsMinError: "Loan months must be greater than or equal to 0",
      monthsMaxError: "Loan months must be less than or equal to 12",
      monthsError: "Months is required",
      monthlyPaymentsError: "Monthly Payments is required",
      annualInterestRateError: "Annual interest rate is required",
      startDateError: "Start date is required",
      firstPaymentError: "First payment date is required",
      associatedInvestmentError: "Associated Investment is required",
      moreAmountError:"First loan payment can't be more than actual amount",
      save: "Save",
      currencyTypeError: "Currency is required",
      currency_type: "Currency",
    },
    Other: {
      name: "Loan name",
      title: "Add Other Loans",
      amount: "Loan Amount",
      placeholderName: "Enter your loan name",
      term: "Loan term",
      placeholderAmount: "Enter the amount",
      months: "Months",
      placeholderTerm: "Enter years",
      annualInterestRate: "Annual interest rate (%)",
      placeholderMonths: "Enter months",
      monthlyPayments: "Loan monthly payments",
      placeholderAnnualInterestRate: "Enter annual interest rate (%)",
      startDate: "Loan start date",
      placeholderMonthlyPayments: "Enter loan monthly payments",
      placeholderFirstPayment: "Enter First loan payment",
      firstPayment: "First loan payment date",
      nameError: "Loan name is required",
      associatedInvestment: "Associated investment",
      termError: "Loan term is required",
      amountError: "Loan amount is required",
      monthsDecimalError: "Loan months must be an integer",
      termDecimalError: "Loan term must be an integer",
      monthsMaxError: "Loan months must be less than or equal to 12",
      monthsMinError: "Loan months must be greater than or equal to 0",
      annualInterestRateError: "Annual interest rate is required",
      monthsError: "Months is required",
      startDateError: "Start date is required",
      monthlyPaymentsError: "Monthly Payments is required",
      associatedInvestmentError: "Associated Investment is required",
      firstPaymentError: "First payment date is required",
      moreAmountError:"First loan payment can't be more than actual amount",
      currencyTypeError: "Currency is required",
      currency_type: "Currency",
      save: "Save",
    },
  },
  Arabic: {
    Mortgage: {
      title: "إضافة الرهن العقاري",
      name: "اسم الرهن العقاري",
      placeholderName: "أدخل اسم الرهن العقاري الخاص بك",
      amount: "مبلغ الرهن العقاري",
      term: "مدة الرهن العقاري",
      placeholderAmount: "أدخل المبلغ",
      placeholderTerm: "أدخل عدد السنوات",
      months: "الشهور",
      placeholderMonths: "أدخل عدد الشهور",
      annualInterestRate: "معدل الفائدة السنوي (%)",
      placeholderAnnualInterestRate: "أدخل معدل الفائدة السنوي (%)",
      placeholderMonthlyPayments: "أدخل الأقساط الشهرية للرهن العقاري",
      monthlyPayments: "الأقساط الشهرية للرهن العقاري",
      startDate: "تاريخ بدء الرهن العقاري",
      firstPayment: "تاريخ سداد الرهن العقاري الأول",
      placeholderFirstPayment: "أدخل الدفعة الأولى للرهن العقاري",
      associatedInvestment: "الاستثمار المرتبط",
      amountError: "مبلغ الرهن العقاري مطلوب",
      nameError: "اسم الرهن العقاري مطلوب",
      termError: "مدة الرهن العقاري مطلوبة",
      termDecimalError: "يجب أن يكون مصطلح الرهن العقاري عددا صحيحا",
      monthsDecimalError: "يجب أن تكون أشهر الرهن العقاري عددا صحيحا",
      monthsMinError: "يجب أن تكون الأشهر أكبر من أو تساوي 0",
      monthsError: "الشهور مطلوبة",
      monthsMaxError: "يجب أن تكون الأشهر أقل من أو تساوي 12",
      annualInterestRateError: "معدل الفائدة السنوي مطلوب",
      monthlyPaymentsError: "الأقساط الشهرية مطلوبة",
      startDateError: "تاريخ البدء مطلوب",
      firstPaymentError: "مطلوب تاريخ الدفع الأول",
      currencyTypeError: "العملة مطلوبة",
      associatedInvestmentError: "الاستثمار المرتبط مطلوب",
      moreAmountError: "لا يمكن أن تكون دفعة القرض الأولى أكثر من المبلغ الفعلي",
      save: "حفظ",
      currency_type: "عملة",
    }, 
    PersonalLoan: {
      title: "إضافة القروض الشخصية",
      name: "اسم القرض",
      placeholderName: "أدخل اسم القرض الخاص بك",
      amount: "مبلغ القرض",
      placeholderAmount: "أدخل المبلغ",
      term: "مدة القرض",
      placeholderTerm: "أدخل عدد السنوات",
      months: "الشهور",
      placeholderMonths: "أدخل عدد الشهور",
      placeholderAnnualInterestRate: "أدخل معدل الفائدة السنوي (%)",
      annualInterestRate: "معدل الفائدة السنوي (%)",
      monthlyPayments: "الأقساط الشهرية للقرض",
      placeholderMonthlyPayments: "أدخل الأقساط الشهرية للقرض",
      firstPayment: "تاريخ سداد القرض الأول",
      startDate: "تاريخ بدء القرض",
      placeholderFirstPayment: "أدخل الدفعة الأولى للقرض",
      associatedInvestment: "الاستثمار المرتبط",
      nameError: "اسم القرض مطلوب",
      amountError: "مبلغ القرض مطلوب",
      termError: "مدة القرض مطلوبة",
      monthsDecimalError: "يجب أن تكون أشهر القرض عددًا صحيحًا",
      termDecimalError: "يجب أن تكون مدة القرض رقمًا",
      monthsMinError: "يجب أن تكون أشهر القرض أكبر من أو تساوي 0",
      monthsMaxError: "يجب أن تكون أشهر القرض أقل من أو تساوي 12",
      monthsError: "الشهور مطلوبة",
      monthlyPaymentsError: "الأقساط الشهرية مطلوبة",
      annualInterestRateError: "معدل الفائدة السنوي مطلوب",
      startDateError: "تاريخ البدء مطلوب",
      firstPaymentError: "مطلوب تاريخ الدفع الأول",
      associatedInvestmentError: "الاستثمار المرتبط مطلوب",
      moreAmountError: "لا يمكن أن تكون دفعة القرض الأولى أكثر من المبلغ الفعلي",
      save: "حفظ",
      currencyTypeError: "العملة مطلوبة",
      currency_type: "عملة",
    },
    Other: {
      title: "إضافة القروض الأخرى",
      name: "اسم القرض",
      placeholderName: "أدخل اسم القرض الخاص بك",
      placeholderAmount: "أدخل المبلغ",
      amount: "مبلغ القرض",
      placeholderMonths: "أدخل عدد الشهور",
      placeholderTerm: "أدخل عدد السنوات",
      term: "مدة القرض",
      annualInterestRate: "معدل الفائدة السنوي (%)",
      months: "الشهور",
      placeholderAnnualInterestRate: "أدخل معدل الفائدة السنوي (%)",
      firstPayment: "تاريخ سداد القرض الأول",
      placeholderMonthlyPayments: "أدخل الأقساط الشهرية للقرض",
      monthlyPayments: "الأقساط الشهرية للقرض",
      placeholderFirstPayment: "أدخل الدفعة الأولى للقرض",
      startDate: "تاريخ بدء القرض",
      associatedInvestment: "الاستثمار المرتبط",
      nameError: "اسم القرض مطلوب",
      amountError: "مبلغ القرض مطلوب",
      termError: "مدة القرض مطلوبة",
      monthsMaxError: "يجب أن تكون أشهر القرض أقل من أو تساوي 12",
      monthsDecimalError: "يجب أن تكون أشهر القرض عددًا صحيحًا",
      termDecimalError: "يجب أن تكون مدة القرض رقمًا",
      annualInterestRateError: "معدل الفائدة السنوي مطلوب",
      monthsMinError: "يجب أن تكون أشهر القرض أكبر من أو تساوي 0",
      monthsError: "الشهور مطلوبة",
      firstPaymentError: "مطلوب تاريخ الدفع الأول",
      monthlyPaymentsError: "الأقساط الشهرية مطلوبة",
      startDateError: "تاريخ البدء مطلوب",
      save: "حفظ",
      associatedInvestmentError: "الاستثمار المرتبط مطلوب",
      currencyTypeError: "العملة مطلوبة",
      moreAmountError: "لا يمكن أن تكون دفعة القرض الأولى أكثر من المبلغ الفعلي",
      currency_type: "عملة",
    }
  }
}

exports.validationApiContentType = "application/json";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.validationApiMethodType = "GET";
exports.exampleApiContentType = "application/json";
exports.exampleAPiMethod = "POST";
exports.labelTitleText = "cradditionalformsformschangerequests";
exports.textInputPlaceHolder = "Enter Text";
exports.labelDataText = "cradditionalformsformschangerequests Data";
exports.exampleAPiPOSTMethod = "POST";
exports.exampleAPiDELETEMethod = "DELETE";
exports.labelBodyText = "cradditionalformsformschangerequests Body";
exports.exampleAPiPUTMethod = "PUT";
// Customizable Area End
import React, { useEffect, useState, useRef } from "react";
import { useMeeting } from "@videosdk.live/react-sdk";
import { Box, Typography } from '@material-ui/core';
import Controls from './Controls.web';
import ParticipantView from "./ParticipantView.web";
import { backButton } from '../../blocks/groupvideocall/src/assets';

interface MeetingViewProps {
  onMeetingLeave: () => void;
  onbackButtonPress: () => void;
  meetingHeader: string;
  BookingTime: string;
  bookingDuration: string;
}

const MeetingView: React.FC<MeetingViewProps> = ({
  onMeetingLeave,
  onbackButtonPress,
  meetingHeader,
  BookingTime,
  bookingDuration
}) => {
  const [joined, setJoined] = useState<string>("");
  const [mainParticipantId, setMainParticipantId] = useState<string | null>(null);
  const [elapsedTime, setElapsedTime] = useState<number>(0);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const { join, leave, participants } = useMeeting({
    onMeetingJoined: () => {
      setJoined("JOINED");
      startTimer();
      startDifferenceCheck();
    },
    onMeetingLeft: () => {
      stopTimer();
      stopDifferenceCheck();
      onMeetingLeave();
    },
  });

  useEffect(() => {
    return () => {
      stopTimer();
      stopDifferenceCheck();
    };
  }, []);

  const startTimer = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    const startTime = new Date();
    intervalRef.current = setInterval(() => {
      setElapsedTime(Math.floor((new Date().getTime() - startTime.getTime()) / 1000));
    }, 1000);
  };

  const stopTimer = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    setElapsedTime(0);
  };

  const startDifferenceCheck = () => {
    intervalRef.current = setInterval(() => {
      calculateDifference();
    }, 6000);
  };

  const stopDifferenceCheck = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };
  const handleBackButtonClick = () => {
    leave();
    stopTimer();
    stopDifferenceCheck();
    onbackButtonPress();
  };
  const calculateDifference = () => {
    if (!BookingTime) {
      return;
    }
    const currentTime = new Date();
    const [hours, minutes] = BookingTime.split(':');
    const bookingDateTime = new Date(Date.UTC(
      currentTime.getUTCFullYear(),
      currentTime.getUTCMonth(),
      currentTime.getUTCDate(),
      parseInt(hours, 10),
      parseInt(minutes, 10)
    ));
    const currentUTCTime = new Date(Date.UTC(
      currentTime.getUTCFullYear(),
      currentTime.getUTCMonth(),
      currentTime.getUTCDate(),
      currentTime.getUTCHours(),
      currentTime.getUTCMinutes(),
      currentTime.getUTCSeconds()
    ));

    const differenceInMillis = bookingDateTime.getTime() - currentUTCTime.getTime();
    const differenceInMinutes = Math.floor(differenceInMillis / (1000 * 60));

    if (differenceInMinutes <-parseInt(bookingDuration, 10)) {
      handleBackButtonClick()
    }
  };

  const joinMeeting = () => {
    setJoined("JOINING");
    join();
  };

  

  useEffect(() => {
    if (participants.size >= 1 && !mainParticipantId) {
      const [firstParticipant] = Array.from(participants.keys());
      setMainParticipantId(firstParticipant);
    }
  }, [participants, mainParticipantId]);

  const toggleMainParticipant = (participantId: string) => {
    setMainParticipantId(participantId);
  };

  const formatElapsedTime = (seconds: number): string => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = seconds % 60;
    return `${h > 0 ? h + ":" : ""}${m < 10 ? "0" + m : m}:${s < 10 ? "0" + s : s}`;
  };

  return (
    <div style={styles.container}>
      {joined === "JOINED" ? (
        <div style={styles.joinedContainer}>
          <div style={styles.header}>
            <Box display="flex" alignItems="center" style={styles.headerContent}>
              <img src={backButton} alt="arrow" onClick={handleBackButtonClick} />
              <Typography variant="body1" style={styles.headerText}>
                {meetingHeader}
              </Typography>
            </Box>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%', backgroundColor: '#32273b' }}>
            <Typography style={styles.headerText}>
              {formatElapsedTime(elapsedTime)}
            </Typography>
          </div>
          <div style={styles.mainContent}>
            {Array.from(participants.keys()).map((participantId) => (
              <ParticipantView
                participantId={participantId}
                key={participantId}
                isMain={participantId === mainParticipantId}
                onMeetingleave={onbackButtonPress}
                onToggleMain={() => toggleMainParticipant(participantId)}
              />
            ))}
          </div>
          <div style={styles.controls}>
            <Controls />
          </div>
        </div>
      ) : joined === "JOINING" ? (
        <p>Joining the meeting...</p>
      ) : (
        <></>
      )}
    </div>
  );
};

const styles = {
  container: {
    overflowX: 'hidden' as 'hidden',
    overflowY: 'hidden' as 'hidden',
    height: '100vh',
    width: '100%',
  },
  joinedContainer: {
    overflow: 'hidden' as 'hidden',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column' as 'column',
    position: 'relative' as 'relative',
  },
  header: {
    height: '10%',
    width: '100%',
    backgroundColor: '#32273b',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: '60px',
  },
  headerContent: {
    marginTop: '20px',
  },
  headerText: {
    marginLeft: '20px',
    fontSize: '26px',
    fontStyle: 'Roboto',
    fontWeight: 'bold' as 'bold',
    color: '#D9D9D9',
  },
  mainContent: {
    width: '100%',
    flexGrow: 1,
    backgroundColor: '#32273b',
    position: 'relative' as 'relative',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '20px'
  },
  controls: {
    height: '10%',
    width: '100%',
    backgroundColor: '#32273b',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
};

export default MeetingView;

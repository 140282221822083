import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start

import { CreditCardBlack, CreditCardWhite, MortgageImgBlack, MortgageImgWhite, OtherBlack, OtherWhite, PersonalLoanBlack, PersonalLoanWhite, imgArrow, imgSetting, } from "./assets";
import { NavLink } from "react-router-dom";
import MuiButton from '@material-ui/core/Button';
import { Box, CssBaseline, FormControl, Grid, ThemeProvider, createTheme, styled } from "@material-ui/core";
import Select from 'react-select';

const theme = createTheme({
  palette: {
    primary: {
      main: "#2CE2D5",
      contrastText: "#fff",
    },
  },
});

// Customizable Area End

import AddLiabilityController, {
  Props,
  configJSON
} from "./AddLiabilityController";

export default class AddLiabilityWeb extends AddLiabilityController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MainWrapper dir={this.state.direction}>
        <Box className="wrapper">
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box className="innerWrapper">
              <Box className="headerMainAddLiability">
                <Box className="headerTitle">
                  <Box><img className={`backImage ${this.state.direction === 'rtl' ? 'rtlRotate' : ''}`} src={imgArrow} data-test-id="backButton" onClick={() => this.navigateRoute("CategoriessubcategoriesWeb")} /></Box>
                  <a style={{ fontSize: '20px', color: 'white', padding: '0px 20px', fontFamily: 'Poppins', fontWeight: 600 }}>Add liability</a>
                </Box>
                <Box>
                  <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <FormControl>
                      <Select
                        options={this.state.currencyList.map(currency => (
                          {
                            label: currency.currency_type,
                            value: currency.currency_type
                          }))
                        }
                        value={this.state.selectedCurrency}
                        onChange={(val) => this.setCurrency({ label: val!.label, value: val!.value })}
                        classNamePrefix="an-simple-selectUSDNew"
                        className="selectUSDNew"
                        data-test-id="selectCurrencyMenu"
                      />
                    </FormControl>
                    <Box >
                      <NavLink to="/Account-Detail">
                        <img style={{ width: '41px', height: '40px', cursor: "pointer" }} src={imgSetting} />
                      </NavLink>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box style={{ paddingTop: '15px', display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={2} md={4} lg={2}>
                    <Box onClick={() => this.handleLiabilityTypeChange(this.LiabilityType.Mortgages)} className={`FormsContainer formBox ${this.state.selectedLiabilityType === this.LiabilityType.Mortgages ? 'selected' : ''}`} data-test-id="mortgage-card">
                      <img className="cardImage" src={this.state.selectedLiabilityType === this.LiabilityType.Mortgages ? MortgageImgBlack : MortgageImgWhite} alt="" />
                      <Box className="label" style={{ fontSize: "15px" }}>
                        Mortgages
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={2} md={4} lg={2}>
                    <Box onClick={() => this.handleLiabilityTypeChange(this.LiabilityType.CreditCard)} className={`FormsContainer formBox ${this.state.selectedLiabilityType === this.LiabilityType.CreditCard ? 'selected' : ''}`} data-test-id="credit-card">
                      <img className="cardImage" src={this.state.selectedLiabilityType === this.LiabilityType.CreditCard ? CreditCardBlack : CreditCardWhite} alt="" />
                      <Box className="label" style={{ fontSize: "15px" }}>
                        Credit Cards
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={2} md={4} lg={2}>
                    <Box onClick={() => this.handleLiabilityTypeChange(this.LiabilityType.PersonalLoan)} className={`FormsContainer formBox ${this.state.selectedLiabilityType === this.LiabilityType.PersonalLoan ? 'selected' : ''}`} data-test-id="personal-loan-card">
                      <img className="cardImage" src={this.state.selectedLiabilityType === this.LiabilityType.PersonalLoan ? PersonalLoanBlack : PersonalLoanWhite} alt="" />
                      <Box className="label" style={{ fontSize: "15px" }}>
                        Personal Loans
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={2} md={4} lg={2} >
                    <Box onClick={() => this.handleLiabilityTypeChange(this.LiabilityType.Other)} className={`FormsContainer formBox ${this.state.selectedLiabilityType === this.LiabilityType.Other ? 'selected' : ''}`} data-test-id="other-loan-card">
                      <img className="cardImage" src={this.state.selectedLiabilityType === this.LiabilityType.Other ? OtherBlack : OtherWhite} alt="" />
                      <Box className="label" style={{ fontSize: "15px" }}>
                        Other
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Box style={{ textAlign: "end", paddingTop: '80px' }}>
                  <MuiButton
                    data-test-id="submitButton"
                    variant="contained"
                    className="nextButton"
                    style={{ position: 'absolute', bottom: '20px', right: '30px' }}
                    onClick={this.handleSelectLiabilityNext}
                  >
                    Next
                  </MuiButton>
                </Box>
              </Box>
            </Box>
          </ThemeProvider>
        </Box>
      </MainWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const MainWrapper = styled(Box)({
  width: "100%",
  background: "#2A2A39",
  fontFamily: 'Poppins',
  "& .innerWrapper": {
    padding: "0px 30px"
  },
  "& .wrapper": {
    height: "100vh",
    overflowY: "auto"
  },
  "& .headerMainAddLiability": {
    display: 'flex',
    justifyContent: 'space-between',
    alignItem:'center',
    padding: '20px 0'
  },
  "& .headerTitle": {
    display: 'flex',
    alignItems: 'center',
    justifyContent: "center"
  },
  "& .backImage": {
    width: '24px',
    height: '13px',
    cursor: "pointer",
    marginTop: "3px",
  },
  "& .backImage.rtlRotate": {
    transform: "rotate(180deg)"
  },
  "& .formBox": {
    height: "200px",
    background: "#2A2A39",
    boxShadow: "0px 0px 10px 2px rgba(6, 141, 132, 0.44982)",
    borderRadius: "12px",
    cursor: "pointer",
    transition: "all .3s ease",
    "& .label": {
      color: "#FFFFFF"
    },
    "& img": {
      color: "black"
    }
  },
  "& .formBox.selected": {
    background: "#2CE2D5",
    "& .label": {
      color: "#3C3C50"
    }
  },
  "& .FormsContainer": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .cardImage": {
    width: '50px',
    height: '50px',
    marginBottom: "15px"
  },
  "& .nextButton": {
    textAlign: 'center',
    lineHeight: '24px',
    fontWeight: 700,
    fontFamily: 'Poppins',
    textTransform: 'none',
    color: '#3C3C50',
    fontSize: '17px',
    width: "auto",
  }
})


// Customizable Area End
